import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { useQuery } from "@apollo/client";
import { GET_PARSERLOGS } from "./emailparserlogs.query";

const EmailParserLogs = () => {
  const [tableData, setTableData] = useState([]);

  const columns = [
    {
      dataField: "Id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "MessageArrived",
      text: "Message Arrived",
      sort: false,
    },
    {
      dataField: "ParserType",
      text: "Parser",
      sort: false,
    },
    {
      dataField: "OrgName",
      text: "Org Name",
      sort: true,
    },
    {
      dataField: "TemplateMessageSent",
      text: "Message Sent Date",
      sort: false,
    },
    {
      dataField: "CustomerReplied",
      text: "Customer Replied Date",
      sort: false,
    },
    {
      dataField: "EmailForwardedAt",
      text: "Email Forwarded",
      sort: false,
    },
    {
      dataField: "ErrorDetails",
      text: "Error Details",
      sort: false,
    },
    {
      dataField: "S3Identifier",
      text: "Message Identifier",
      sort: true,
    },
  ];
  const defaultSorted: any = [
    {
      dataField: "Id",
      order: "desc",
    },
  ];
  const selectRow: any = {
    mode: "checkbox",
  };
  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
  };
  const { SearchBar } = Search;

  const { data, refetch } = useQuery(GET_PARSERLOGS, {fetchPolicy: "no-cache"});

  useEffect(() => {
    if (data && data.msgbox_EmailParserLog) {
      var tmpData = data.msgbox_EmailParserLog.filter((item: any) => item.Organisation).map(function (item: {
        EmailParserLogId: number;
        MessageArrived: string;
        Organisation: { Name: string };
        S3Identifier: string;
        ErrorDetails: string;
        CustomerReplied: string | null;
        TemplateMessageSent: string | null;
        EmailForwardedAt: string | null;
        ParserType: string | null;
      }) {
        return {
          Id: item.EmailParserLogId,
          MessageArrived: item.MessageArrived.replace("T", " "),
          OrgName: item.Organisation.Name,
          S3Identifier: item.S3Identifier,
          CustomerReplied: item.CustomerReplied
            ? item.CustomerReplied.replace("T", " ")
            : "N/A",
          TemplateMessageSent: item.TemplateMessageSent
            ? item.TemplateMessageSent.replace("T", " ")
            : "N/A",
          EmailForwardedAt: item.EmailForwardedAt
            ? item.EmailForwardedAt.replace("T", " ")
            : "N/A",
          ErrorDetails: item.ErrorDetails,
          ParserType: item.ParserType,
        };
      });
      console.log("tmpData:", tmpData);
      setTableData(tmpData);
    }
  }, [data]);

  const refetchData = () => {
    refetch();
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <h4 className="card-title">Email Parser Logs</h4>
              </div>
            </Col>
            <Col md={6}>
              <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                <div>
                  <Link to="#" className="btn btn-light" onClick={refetchData}>
                    <i className="uil uil-refresh me-1"></i> Refresh
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            // columns={columns}
            // data={productData}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="Id"
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            // responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            selectRow={selectRow}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default EmailParserLogs;
