import { gql } from '@apollo/client';

export const DELETE_USER_INBOX = gql`
mutation delete_inbox ($InboxId: Int! $userId:Int $time: timestamptz) {
    update_msgbox_UserInbox(
      where: {InboxId: {_eq: $InboxId}} 
    	_set:{
        IsDeleted: true
        DeletedBy:$userId
        DeletedOn:$time
      }) {
      affected_rows
  }
}
  `
  