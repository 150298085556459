import React, { Dispatch, SetStateAction, useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation } from "@apollo/client";
import SweetAlert from "react-bootstrap-sweetalert";
import { UPDATE_ORG_NAME } from "./editOrgName.query";
import { ThemeContext } from "src/helpers/themeContext";




interface IProps {
   
  editOrgNameModel: boolean;
  setEditOrgNameModel: Dispatch<SetStateAction<boolean>>;
  PassedUser : any;  
  onRefresh: any; 
  }

const EditOrgName = (props: IProps)=>{

  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);
  const Theme = useContext(ThemeContext)

  const [OrgName, setOrgName] = useState('');
  const [Id, setId] = useState('');

  useEffect(() => {
    if (props.PassedUser) {
      setOrgName(props.PassedUser.Name);
      setId(props.PassedUser.Id);
    }
  }, [props]);


      const handleShow = () => {
        props.setEditOrgNameModel(false);
      };
      const toggle = () => {
        props.setEditOrgNameModel(!props.editOrgNameModel);
      };

  const handleModalOpen = () => { };
  
  const [addRenameOrg] = useMutation(UPDATE_ORG_NAME);

  const handleSubmit =  (values: any) => {
       addRenameOrg({
          variables :{
            OrganisationId:Id,
            Name:values.Rename        
          }
        })
        .then((response)=>{
          console.log("Data Updated successfully");
          setdynamic_description("Organization edited successfully");
          setsuccess_dlg(true);
          toggle();        
        })
        .catch((error)=>{
          console.log(error.message);
          setdynamic_description("Failed to Update the data");
          setfailure_dlg(true);
          toggle();
        })     
     }

    return(
    <React.Fragment>
        {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
            props.onRefresh();
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}

      {failure_dlg ? (
        <SweetAlert
          error
          title={Theme.name}
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      
     <Modal
             isOpen={props.editOrgNameModel}
             toggle={toggle}
             size="lg"
             onOpened={() => handleModalOpen()}
        >

         <ModalHeader toggle={toggle} tag="h4">
         Rename Organization
        </ModalHeader>

        <ModalBody className="modelStyle">
            <AvForm  
                onValidSubmit={(e: any, values: any) => {
                handleSubmit(values);
              }}> 
                <Row form className="d-flex flex-row mb-3">
                    <Col>
                    <div className="mb-3">
                    <AvField
                        className="avInput"
                        name="Name"
                        label="Organization Name"
                        type="Text"
                        placeholder="Organization Name"
                        readOnly = {true}
                        value={OrgName}
                    />
                    </div>
                    
                    <div className="mb-3">
                    <AvField
                        className="avInput"
                        name="Rename"
                        label="Rename Organization"
                        placeholder="Enter Organization Name"
                        errorMessage="Invalid Name"
                        validate={{
                        required: { value: true },
                        }}
                        value={""}
                    />
                    </div>
                    </Col>
                </Row>
                <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={handleShow}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
            </AvForm>
        </ModalBody>
        </Modal>

    </React.Fragment>
    );
};

export default EditOrgName;