import React from "react";
import { Card, CardBody } from "reactstrap";
import OrganizationAnalysisWidget from "src/components/AnalysisData/OrganizationAnalytics/OrganizationAnalysisWidget";
import UserAnalysisWidget from "src/components/AnalysisData/UserAnalytics/UserAnalysisWidget";
import MessageAnalysisWidget from "src/components/AnalysisData/MessageAnalytics/MessageAnalysisWidget";
import SuccessfulMessagesAnalysisWidget from "src/components/AnalysisData/SuccessfulMessagesAnalytics/SuccessfulMessagesAnalysisWidget";

const Widget = () => {
    return (
        <React.Fragment>
                
            <Card>
            <CardBody>

                <div className="d-flex align-items-start">
                <div className="flex-grow-1 overflow-hidden">
                    <h5 className="card-title text-truncate mb-4">This Month To Last Month</h5>
                </div>
            </div>
               <OrganizationAnalysisWidget/>
               <UserAnalysisWidget/>
               <MessageAnalysisWidget/>
               <SuccessfulMessagesAnalysisWidget/>  
                         </CardBody>

            </Card>
        </React.Fragment>
    );
};

export default Widget;