
import client from "../apollo";
import { gql } from "@apollo/client";

export interface ICreateOrgProps {
    orgId: number;
    companyName: string;
}

export const CreateOrganisation = async (props: ICreateOrgProps) => {
    const orgResponse = await client.mutate({
        mutation: CREATE_ORGANISATION,
        variables: {
            name: props.companyName,
        },
    });
    props.orgId =
        orgResponse.data.insert_msgbox_Organisation.returning[0].OrganisationId;
    console.log("org createed:", props.orgId);
    const orgConfigResponse = await client.mutate({
        mutation: CREATE_ORGANISATION_CONFIG,
        variables: {
            organisationId: props.orgId,
        },
    });
    const orgPermissionsResponse = await client.mutate({
        mutation: CREATE_ORGANISATION_PERMISSSIONS,
        variables: {
            organisationId: props.orgId,
        },
    });
    return props;
};

export interface IAddOpeningHoursProps {
    orgId: number;
    dayOfWeek: number;
    open: string;
    close: string;
}

export const AddOpeningHours = async (props: IAddOpeningHoursProps) => {
    const addResponse = await client.mutate({
        mutation: ADD_OPENING_HOURS,
        variables: {
            organisationId: props.orgId,
            dayOfWeek: props.dayOfWeek,
            open: props.open,
            close: props.close,
        },
    });

    return true;
};

const CREATE_ORGANISATION = gql`
  mutation MyMutation($name: String!) {
    insert_msgbox_Organisation(objects: { Name: $name }) {
      returning {
        OrganisationId
      }
    }
  }
`;

const CREATE_ORGANISATION_CONFIG = gql`
  mutation MyMutation($organisationId: Int!) {
    insert_msgbox_Configuration(objects: { OrganisationId: $organisationId }) {
      affected_rows
    }
  }
`;

const CREATE_ORGANISATION_PERMISSSIONS = gql`
  mutation MyMutation($organisationId: Int!) {
    insert_msgbox_Permission(
      objects: {
        OrganisationId: $organisationId

        InboxSeeUnassignedConversations: 1
        InboxSeeOthersAssignedConversations: 1
        InboxAssignConversation: 1

        ContactsDeleteContacts: 2
        ContactsDownloadContacts: 2

        TemplatesDeleteTemplates: 2
        TemplatesAddEditTemplates: 3

        AutomationsSeeAutomations: 2

        AnalyticsSeeAnalytics: 3
      }
    ) {
      affected_rows
    }
  }
`;

const ADD_OPENING_HOURS = gql`
  mutation MyMutation(
    $organisationId: Int!
    $dayOfWeek: Int!
    $open: timetz!
    $close: timetz!
  ) {
    insert_msgbox_OpeningHours(
      objects: {
        OrganisationId: $organisationId
        DayOfWeek: $dayOfWeek
        Open: $open
        Close: $close
      }
    ) {
      affected_rows
    }
  }
`;
