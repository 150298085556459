import { gql } from "@apollo/client";

export const GET_API_PROVIDER = gql`
  query MyQuery {
    msgbox_APIProvider(where: { Name: { _nlike: "gupshup" } }) {
      APIProviderId
    }
  }
`;

export const GET_USER_ORGADMIN_ROLE = gql`
  query MyQuery {
    msgbox_Role(where: { Name: { _eq: "OrgAdmin" } }) {
      RoleId
    }
  }
`;

export const GET_ORGANISATIONS = gql`
  query MyQuery {
    msgbox_Organisation(order_by: { Name: asc }) {
      OrganisationId
      Name
    }
  }
`;

export const GET_ROLES = gql`
  query getRoles {
    msgbox_Role {
      Name
      Description
      RoleId
    }
  }
`;

export const GET_INBOXES = gql`
  query get_inboxes($org: Int) {
    msgbox_Inbox(where: { OrganisationId: { _eq: $org } }) {
      Name
      InboxId
    }
  }
`;

export const EDIT_USER = gql`
  mutation update_user($firstName: String!, $lastName: String!, $id: Int) {
    update_msgbox_User(
      where: { UserId: { _eq: $id } }
      _set: { FirstName: $firstName, LastName: $lastName }
    ) {
      affected_rows
    }
  }
`;

export const EDIT_ROLE = gql`
  mutation edit_role($roleId: Int, $id: Int) {
    update_msgbox_UserRole(
      where: { UserId: { _eq: $id } }
      _set: { RoleId: $roleId }
    ) {
      affected_rows
    }
  }
`;

export const ADD_INBOX = gql`
  mutation add_inbox($inboxId: Int, $id: Int, $OrganisationId: Int) {
    insert_msgbox_UserInbox(
      objects: {
        InboxId: $inboxId
        UserId: $id
        OrganisationId: $OrganisationId
      }
    ) {
      affected_rows
    }
  }
`;

export const REMOVE_INBOX = gql`
  mutation remove_inbox($id: Int) {
    delete_msgbox_UserInbox(where: { UserId: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const GET_TEAMS = gql`
  query MyQuery($orgId: Int) {
    msgbox_Department(
      where: { OrganisationId: { _eq: $orgId }, IsDeleted: { _eq: false } }
    ) {
      DepartmentId
      Name
    }
  }
`;

export const GET_TEAMS_FOR_USER = gql`
  query MyQuery($userId: Int) {
    msgbox_UserDepartment(where: { UserId: { _eq: $userId } }) {
      DepartmentId
      Department {
        Name
        DepartmentId
      }
    }
  }
`;


export const REMOVE_USER_DEPARTMENT = gql`
  mutation remove_user_department($id: Int) {
    delete_msgbox_UserDepartment(where: { UserId: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const ADD_USER_TO_TEAM = gql`
mutation MyMutation(
$OrganisationId: Int
$id: Int
$departmentId: Int
)
  {
    insert_msgbox_UserDepartment(
    objects:
    {
     DepartmentId: $departmentId,
     OrganisationId: $OrganisationId,
     UserId: $id
    }
    )
    {
    affected_rows
    }

  }
`
