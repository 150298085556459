import React, { Dispatch, SetStateAction, useState, useEffect,useContext } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation } from "@apollo/client";
import { ADD_LOCATION } from "./addLocation.query";
import { ThemeContext } from "src/helpers/themeContext";
import SweetAlert from "react-bootstrap-sweetalert";



interface IProps {
    WidgetId : number;
    locationModel: boolean;
    setLocationModel: Dispatch<SetStateAction<boolean>>;
    onRefetch : any;
   
  }

const AddexpandRowWidget = (props: IProps)=>{
  const Theme = useContext(ThemeContext)
  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);

      const handleShow = () => {
        props.setLocationModel(false);
      };
      const toggle = () => {
        props.setLocationModel(!props.locationModel);
      };

      const handleModalOpen = () => {};

      const [addLocation,{loading, error}] = useMutation(ADD_LOCATION);

      const handleWidgetSubmit =  (values: any) => {
        console.log("WidgetID : ",props.WidgetId);
        console.log("Values : ", values);
        
        addLocation({
          variables :{
            Text:values.Text,
            Url:values.URL,
            WidgetId:props.WidgetId
          }
        })
        .then((response)=>{
          console.log("Data added successfully");
          setdynamic_description("Web Widget created successfully");
          setsuccess_dlg(true);
          toggle();
         
        })
        .catch((error)=>{
          console.log(error.message);
          setdynamic_description("Failed to create Web Widget");
          setfailure_dlg(true);
          toggle();
        })
        
      }

    return(
    <React.Fragment>
      {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
            props.onRefetch();
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      
       {failure_dlg ? (
        <SweetAlert
          error
          title={Theme.name}
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}


        <Modal
             isOpen={props.locationModel}
             toggle={toggle}
             size="lg"
             onOpened={() => handleModalOpen()}
        >

         <ModalHeader toggle={toggle} tag="h4">
          Add New Location
        </ModalHeader>

        <ModalBody className="modelStyle">
            <AvForm
             onValidSubmit={(e: any, values: any) => {
                handleWidgetSubmit(values);
              }}
            > 
                <Row form className="d-flex flex-row mb-3">
                    <Col>
                    <div className="mb-3">
                    <AvField
                        className="avInput"
                        name="Text"
                        label="Text"
                        type="Text"
                        placeholder="Enter Text"
                        validate={{
                        required: { value: true },
                        }}
                        value={""}
                    />
                    </div>
                    
                    <div className="mb-3">
                    <AvField
                        className="avInput"
                        name="URL"
                        label="URL"
                        placeholder="Enter URL"
                        errorMessage="Invalid URL"
                        validate={{
                        required: { value: true },
                        }}
                        value={""}
                    />
                    </div>
                    </Col>
                </Row>
                <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={handleShow}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
            </AvForm>
        </ModalBody>
      </Modal>

    </React.Fragment>
    );
};

export default AddexpandRowWidget;