import React, { Dispatch, SetStateAction, useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { ThemeContext } from "src/helpers/themeContext";
import {MUTATE_EmailParserOutgoingEmail, GET_DEFAULTS} from "./query";

import client from "../../apollo";
import SweetAlert from "react-bootstrap-sweetalert";

interface IProps {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  refresh: any;
  data: any;
}

const EditEmailForwarder = (props: IProps) => {

  const handleShow = () => {
    props.setModal(false);
  };
  const toggle = () => {
    props.setModal(!props.modal);
  };
  const Theme = useContext(ThemeContext)
  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);
  const [defaultEmail, setDefaultEmail] = useState('false')
  const [options, setOptions] = useState<string[]>([])

  useEffect(()=>{
    if(!props.data) return
    getOptions()
  }, [props])

 async function getOptions () {
    let res: any = await client.query({
      query: GET_DEFAULTS,
      variables: {apiAccountId: props.data.APIAccountId},
      fetchPolicy: "no-cache"
    }).catch(err => {
      console.log("error:", err)
    });
    if(res?.data?.msgbox_EmailParserOutgoingEmail.length === 0 || props.data.IsDefault === true){
      setOptions(['false', 'true'])
    } else {
      setOptions(['false'])
    }
    let tmp = props.data.IsDefault.toString() || 'false'
    setDefaultEmail(tmp)
  }

  const handleEmailParserSubmit = async (values: any) => {

    var obj = {
      _eq: props.data.Id,
      emailAddress: values.emailAddress,
      matchExpression: values.matchExpression,
      defaultEmail: JSON.parse(values.defaultEmail),
    };

    let failed = false

    await client.mutate({
      mutation: MUTATE_EmailParserOutgoingEmail,
      variables: obj
    }).catch(err => {
      console.log("error:",err)
      failed = true
    });

    if (failed) {
      setdynamic_description("Failed to edit Email Forwarder");
      setfailure_dlg(true);
    } else {
      setdynamic_description("Email Parser edited successfully");
      setsuccess_dlg(true);
      props.refresh()
    }
    toggle();
  };

  return (
    <React.Fragment>
      {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      {failure_dlg ? (
        <SweetAlert
          error
          title={Theme.name}
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}      
      <Modal
        isOpen={props.modal}
        toggle={toggle}
        size="lg"
      >
        <ModalHeader toggle={toggle} tag="h4">
          Edit Email Forwarder
        </ModalHeader>
        <ModalBody className="modelStyle">
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleEmailParserSubmit(values);
            }}
          >
            <Row form className="d-flex flex-row mb-3">
              <Col>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="emailAddress"
                    label="Email Address"
                    type="email"
                    placeholder="Enter destination email address"
                    errorMessage="Invalid email address"
                    validate={{
                      required: { value: true }
                    }}
                    value={props.data.EmailAddress}
                  />
                </div>

                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="matchExpression"
                    label="Match Expression"
                    type="regex"
                    placeholder="Enter match expression"
                    errorMessage="Invalid match expression"
                    validate={{
                      required: { value: true }
                    }}
                    value={props.data.RegEx}
                  />
                </div>
                <div className="mb-3">
                  <AvField className="avInput" type="select" id="defaultEmail" value={defaultEmail} name="defaultEmail" label="Default Email Address">
                    {options.map((item: string, index: number) => {
                      return <option key={index} value={item}>{item}</option>
                    })}
                  </AvField>
                </div>
                
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={handleShow}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default EditEmailForwarder;
