import client from "../../apollo";
import { ADD_BOT_RESPONSE } from "./query";

async function AddEstateAgentCTAAutomation(org, api) {
  let orgId = org.OrganisationId;
  let apiId = api.APIAccountId;
  let orgName = org.Name;
  if (!orgName || !apiId || !orgId) return false;

  let botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: "0",
      reply: `Thank you so much for contacting ${orgName}.\n\nIn order to assist you with your enquiry please select Menu options, and select the department that you'd like to be connected to 👇\n\nShould you wish to unsubscribe at anytime, simply message the word *STOP*.`,
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      listMetadata:
        '[{"title":"Select An Option","options":[{"postbackText":"Sales postback payload","type":"text","title":"Sales"},{"postbackText":"Lettings postback payload","type":"text","title":"Lettings"},{"postbackText":"Mortgages postback payload","type":"text","title":"Mortgages"},{"postbackText":"Report a fault postback payload","type":"text","title":"Report a fault"},{"postbackText":"Other postback payload","type":"text","title":"Other"}]}]',
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,

      apiAccountId: apiId,
      keyword: "SALES",
      reply:
        "Please respond with one of the following options 👇 *Sell my home* - if you want us to sell your property *View/Register* - if you're looking to buy or register as an applicant",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      replyMetadata:
        '[{"postbackText":"dev-defined-postback1","type":"text","title":"SELL MY HOME"},{"postbackText":"dev-defined-postback2","type":"text","title":"VIEW/REGISTER"}]',
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  let parentBotResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: "SELL MY HOME",
      reply:
        "We'd love to help you sell your home! We're going to ask you 5 simple questions and its important your that you only reply with *a single response message to each question.*",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "Phrase",
    },
  });

  if (
    parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
      .BotResponseId === 0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,

      apiAccountId: apiId,
      keyword: null,
      reply: "Please confirm your full name?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 1,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "and your current address?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 2,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "Please could you confirm your email address?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 3,
      requiresResponse: true,
      captureEmailAddress: true,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "Can I please ask if you need Mortgage advice?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 4,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "When are you looking to take your property to market?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 5,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }
  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "Thank you. We'll assign this chat to the sales team. *Please note, we aim to respond to this chat within 1 working hour.*",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 6,
      requiresResponse: false,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  parentBotResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: "BOOK A VIEWING",
      reply:
        "Please can you answer the next set of questions? *It's important you reply with a single message to each question* Please confirm your full name?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "Phrase",
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
      .BotResponseId === 0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "and your email address?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 1,
      requiresResponse: true,
      captureEmailAddress: true,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "Please can you confirm the property you'd like to view?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 2,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "And where did you see it advertised?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 3,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "And how many adults over the age of 18 years will be moving in to the property?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 4,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "Do you have a UK/EU passport or Visa for all applicants?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 5,
      requiresResponse: true,
      captureEmailAddress: false,
      replyMetadata:
        '[{"postbackText":"dev-defined-postback1","type":"text","title":"YES"},{"postbackText":"dev-defined-postback2","type":"text","title":"NO"}]',
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "What time of day works best for you to view?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 6,
      requiresResponse: true,
      captureEmailAddress: false,
      replyMetadata:
        '[{"postbackText":"dev-defined-postback1","type":"text","title":"Morning"},{"postbackText":"dev-defined-postback2","type":"text","title":"Afternoon"},{"postbackText":"dev-defined-postback3","type":"text","title":"Evening"}]',
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "That's great. Thank you. Your details have been passed to the lettings team who will be in contact. *We will aim to be in touch within 4 working hours.*",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 7,
      requiresResponse: false,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: "OTHER",
      reply:
        "Please explain the nature of your enquiry, and a member of the team will pick up and aim to *reply within 4 working hours.*",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      requiresResponse: false,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  parentBotResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: "VIEW A PROPERTY",
      reply:
        "We'd love to arrange a viewing for you. But first, we just need to take some details from you.",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "Phrase",
    },
  });

  if (
    parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
      .BotResponseId === 0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "We're going to ask you some questions, and its important that you only reply with a *single response message to each question.*",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 1,
      requiresResponse: false,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "Please confirm your full name?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 2,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "What property would you like to view?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 3,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "and your current address?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 4,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "Please could you confirm your email address?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 5,
      requiresResponse: true,
      captureEmailAddress: true,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "And what is your current position?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 6,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "Can I please ask if you need Mortgage advice?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 7,
      requiresResponse: true,
      captureEmailAddress: false,
      replyMetadata:
        '[{"postbackText":"dev-defined-postback1","type":"text","title":"Yes"},{"postbackText":"dev-defined-postback2","type":"text","title":"No"},{"postbackText":"dev-defined-postback3","type":"text","title":"Unsure"}]',
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "What time of day works best for you to view?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 8,
      requiresResponse: true,
      captureEmailAddress: false,
      replyMetadata:
        '[{"postbackText":"dev-defined-postback1","type":"text","title":"Morning"},{"postbackText":"dev-defined-postback2","type":"text","title":"Afternoon"},{"postbackText":"dev-defined-postback3","type":"text","title":"Evening"}]',
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "Perfect, thank you. Your details have been passed over to the sales team. *We'll aim to respond within 1 working hour.*",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 9,
      requiresResponse: false,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: "LETTINGS",
      reply: "Thank you. Please reply using one of the buttons below 👇",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      requiresResponse: false,
      captureEmailAddress: false,
      replyMetadata:
        '[{"postbackText":"dev-defined-postback1","type":"text","title":"LET MY PROPERTY"},{"postbackText":"dev-defined-postback2","type":"text","title":"REGISTER/VIEW"}]',
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: "SOMETHING ELSE",
      reply:
        "Please explain the nature of your enquiry, and a member of the team will pick up and aim to *reply within 4 working hours.*",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "Phrase",
      requiresResponse: false,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  parentBotResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: "REPORT A FAULT",
      reply:
        "Sorry to hear you are having issues. We just need to take some details from you.",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "Phrase",
    },
  });

  if (
    parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
      .BotResponseId === 0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "We're going to ask you some questions, and its important that you only reply with a *single response message to each question.*",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 1,
      requiresResponse: false,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "What is the address of the property that you are reporting the issue on?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 2,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "And what is the issue relating to (click menu 👇 and select the appropriate option):",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 3,
      requiresResponse: true,
      captureEmailAddress: false,
      listMetadata:
        '[{"title":"Select An Option","options":[{"postbackText":"Heating/Boiler/Gas postback payload","type":"text","title":"Heating/Boiler/Gas"},{"postbackText":"Water/Leaks postback payload","type":"text","title":"Water/Leaks"},{"postbackText":"Electricity/Lighting postback payload","type":"text","title":"Electricity/Lighting"},{"postbackText":"Doors/Locks/Windows postback payload","type":"text","title":"Doors/Locks/Windows"},{"postbackText":"Communal Facilities postback payload","type":"text","title":"Communal Facilities"},{"postbackText":"Pests/Vermin postback payload","type":"text","title":"Pests/Vermin"},{"postbackText":"Roof/Exterior postback payload","type":"text","title":"Roof/Exterior"},{"postbackText":"Kitchen/Bathroom postback payload","type":"text","title":"Kitchen/Bathroom"},{"postbackText":"Fire/Smoke Detector postback payload","type":"text","title":"Fire/Smoke Detector"},{"postbackText":"Other postback payload","type":"text","title":"Other"}]}]',
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "And when did you first notice the issue?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 4,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "Thank you. Please can you now describe in detail the issue, and include any images/video's to give us as much detail as possible in order to be able to assist you. To set expectations *we aim to respond to issues within 2 working hours.* Thank you.",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 5,
      requiresResponse: false,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  parentBotResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: "LET MY PROPERTY",
      reply:
        "We'd love to help you let your property! We're going to ask you 4 simple questions, and its important your that you *only reply with a single response message to each question.*",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "Phrase",
    },
  });

  if (
    parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
      .BotResponseId === 0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,

      apiAccountId: apiId,
      keyword: null,
      reply: "Please confirm your full name?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 1,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "and the address of the property you wish to let out? If you have more than one property, simply respond with *multiple*.",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 2,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "Please could you confirm your email address?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 3,
      requiresResponse: true,
      captureEmailAddress: true,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "When are you looking to take your property to market?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 4,
      requiresResponse: true,
      captureEmailAddress: false,
      replyMetadata:
        '[{"postbackText":"dev-defined-postback1","type":"text","title":"WITHIN NEXT 3 MONTHS"},{"postbackText":"dev-defined-postback2","type":"text","title":"3 TO 6 MONTHS"},{"postbackText":"dev-defined-postback3","type":"text","title":"6 MONTHS +"}]',
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "Thank you. We'll assign this chat to the lettings team. Please note, we aim to *respond to this chat within 1 working hour.*",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 5,
      requiresResponse: false,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: "VIEW/REGISTER",
      reply: `Can you choose from one of the following: *View a Property* - if you have seen a specific property we're currently selling and you'd like to arrange a viewing *Register* - if you're wanting us to alert you when suitable properties come onto the market *Other* - for all other enquiries`,
      isDeletable: true,
      isEnabled: true,
      botResponseType: "Phrase",
      requiresResponse: false,
      captureEmailAddress: false,
      replyMetadata:
        '[{"postbackText":"dev-defined-postback1","type":"text","title":"VIEW A PROPERTY"},{"postbackText":"dev-defined-postback2","type":"text","title":"REGISTER "},{"postbackText":"dev-defined-postback3","type":"text","title":"OTHER"}]',
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  parentBotResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: "REGISTER",
      reply:
        "OK, in order to register you're details, we're going to need to take some details from you. *It's important that you reply with one message to each question.* Firstly, what is your full name?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      requiresResponse: true,
    },
  });

  if (
    parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
      .BotResponseId === 0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,

      apiAccountId: apiId,
      keyword: null,
      reply: "And what is your email address?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 1,
      requiresResponse: true,
      captureEmailAddress: true,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "In a few words, please describe your ideal property and location including any specific streets, post codes and/or areas you would like to live in? Remember, the response needs to be in one message reply.",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 2,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "Please advise your current position (click on menu options 👇)",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 3,
      requiresResponse: true,
      captureEmailAddress: false,
      listMetadata:
        '[{"title":"Select An Option","options":[{"postbackText":"First time buyer postback payload","type":"text","title":"First time buyer"},{"postbackText":"Looking to sell my home postback payload","type":"text","title":"Looking to sell my home"},{"postbackText":"Already on the market postback payload","type":"text","title":"Already on the market"},{"postbackText":"Sold STC postback payload","type":"text","title":"Sold STC"},{"postbackText":"Landlord looking to let postback payload","type":"text","title":"Landlord looking to let"}]}]',
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "Do you need mortgage advice?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 4,
      requiresResponse: true,
      captureEmailAddress: false,
      replyMetadata:
        '[{"postbackText":"dev-defined-postback1","type":"text","title":"Yes"},{"postbackText":"dev-defined-postback2","type":"text","title":"No"},{"postbackText":"dev-defined-postback3","type":"text","title":"Unsure"}]',
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "That's great. This information has been passed onto the team, *who will aim to respond within 4 working hours.* If you have any questions or further information do you require about the property, please feel free to add them here.",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 5,
      requiresResponse: false,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: "REGISTER/VIEW",
      reply: `Can you choose from one of the following: *Book a viewing* - if you have seen a specific property we're currently selling and you'd like to arrange a viewing *Register Applicant* - if you're wanting us to alert you when suitable properties come onto the market to rent *Something else* - for all other letting enquiries`,
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      requiresResponse: false,
      captureEmailAddress: false,
      replyMetadata:
        '[{"postbackText":"dev-defined-postback1","type":"text","title":"BOOK A VIEWING"},{"postbackText":"dev-defined-postback2","type":"text","title":"REGISTER APPLICANT"},{"postbackText":"dev-defined-postback3","type":"text","title":"SOMETHING ELSE"}]',
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  parentBotResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: "MORTGAGES",
      reply:
        "We’d love to arrange a mortgage appointment for you. But first we just need to take some details.",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
    },
  });

  if (
    parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
      .BotResponseId === 0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "We're going to ask you 4 simple questions, and its important your that you only reply with a *single response message to each question.*",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 1,
      requiresResponse: false,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "Please confirm your full name?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 2,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "Please could you confirm your email address?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 3,
      requiresResponse: true,
      captureEmailAddress: true,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "Please can you confirm your reason for enquiring? Click on menu options 👇",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 4,
      requiresResponse: true,
      captureEmailAddress: false,
      listMetadata:
        '[{"title":"Select An Option","options":[{"postbackText":"First time buyer postback payload","type":"text","title":"First time buyer"},{"postbackText":"Homemover postback payload","type":"text","title":"Homemover"},{"postbackText":"Buy to let postback payload","type":"text","title":"Buy to let"},{"postbackText":"Re-mortgage to save £ postback payload","type":"text","title":"Re-mortgage to save £"},{"postbackText":"Other postback payload","type":"text","title":"Other"}]}]',
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "Thank you, that's the end of the questions for now, please can you let us have 2 days/times that work best for you, so we can arrange a suitable appointment? 📆 👉 Monday AM or PM 👉 Tuesday AM or PM 👉Wednesday AM or PM 👉Thursday AM or PM 👉Friday AM or PM 👉Saturday AM or PM",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 5,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "One of our team will call you to confirm the next steps in regards to your viewing. Please let us know when it would be best to contact you? ☎",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 6,
      requiresResponse: true,
      captureEmailAddress: false,
      replyMetadata:
        '[{"postbackText":"dev-defined-postback1","type":"text","title":"Morning"},{"postbackText":"dev-defined-postback2","type":"text","title":"Afternoon"},{"postbackText":"dev-defined-postback3","type":"text","title":"Evening"}]',
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "Perfect, thank you. *Please note, we aim to respond within 4 working hours to your mortgage enquiry.*",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 7,
      requiresResponse: false,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: "LETTINGS OTHER",
      reply: `Please can you let us know the nature of your enquiry. Once submitted, one of the team will pick up the chat. *Please note we aim to respond to messages within 4 working hours.*`,
      isDeletable: true,
      isEnabled: true,
      botResponseType: "Phrase",
      requiresResponse: false,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  parentBotResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: "REGISTER APPLICANT",
      reply:
        "OK, in order to register you're details, we're going to need to take some details from you. *It's important that you reply with one message to each question.* Firstly, what is your full name?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "Phrase",
      requiresResponse: true,
    },
  });

  if (
    parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
      .BotResponseId === 0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "And what is your email address?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 1,
      requiresResponse: true,
      captureEmailAddress: true,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "In a few words, please describe your ideal property and location including any specific streets, post codes and/or areas you would like to live in? *Remember, the response needs to be in one message reply.*",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 2,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "What are your current living arrangements, Renting, Home Owner, Family and Friends?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 3,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply: "What is the earliest date that you can move into the property?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 4,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "Do you need to give notice if you are in rental now. If so how many weeks / months do you need to give your Landlord or Letting Agency?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 5,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "What is your maximum rental per month that you are looking to pay per property?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 6,
      requiresResponse: true,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  botResponseId = await client.mutate({
    mutation: ADD_BOT_RESPONSE,
    variables: {
      organisationId: orgId,
      apiAccountId: apiId,
      keyword: null,
      reply:
        "Thank you. This information will be passed over to the lettings team, who will aim to be in touch *within 4 working hours.*",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId:
        parentBotResponseId.data.insert_msgbox_BotResponse.returning[0]
          .BotResponseId,
      order: 7,
      requiresResponse: false,
      captureEmailAddress: false,
    },
  });

  if (
    botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId ===
    0
  ) {
    return false;
  }

  return true;
}

export default AddEstateAgentCTAAutomation;
