import { gql } from '@apollo/client';

export const UPDATE_ORG_NAME = gql`
mutation MyMutation($Name: String!, $OrganisationId: Int!) {
    update_msgbox_Organisation(where: {OrganisationId: {_eq: $OrganisationId}}, _set: {Name: $Name}) {
        affected_rows
    }
}
`;


