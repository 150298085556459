import { createHttpLink, split, ApolloLink, fromPromise } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import jwt_decode from "jwt-decode";
import * as url from "../helpers/url_helper";

const uri = process.env.REACT_APP_SERVER_URL;

const getAccessToken = () => {
  try {
    const access_token = JSON.parse(localStorage.getItem("access_token"));
    return access_token;
  } catch (exception) {
    console.log("no access token");
    return null;
  }
};

console.log("access_token:", getAccessToken());
const httpLink = createHttpLink({
  uri: "https://" + uri,
});

const wsLink = new SubscriptionClient("wss://" + uri, {
  reconnect: true,
  connectionParams: () => {
    return {
      headers: {
        "hasura-client-name": "msgboxx-reseller",
        authorization: "Bearer " + getAccessToken(),
      },
    };
  },
  reconnectionAttempts: 5,
});

const authLink = setContext((_, { headers }) => {
  const token = getAccessToken();
  return {
    headers: {
      ...headers,
      "hasura-client-name": "msgboxx-reseller",
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const setAccessToken = accessToken => {
  localStorage.setItem("access_token", accessToken);
};

export const removeAccessToken = () => {
  localStorage.removeItem("access_token");
};

export const setResellerId = resellerId => {
  localStorage.setItem("reseller_id", resellerId);
};

const getNewToken = async () => {
  try {
    const response = await fetch(url.POST_FAKE_JWT_REFRESHTOKEN, {
      method: "GET",
      credentials: "include",
    });
    const data = await response.json();
    const decoded = jwt_decode(data.access_token);
    if (
      decoded["https://hasura.io/jwt/claims"]["x-hasura-reseller-id"] &&
      decoded["https://hasura.io/jwt/claims"]["x-hasura-reseller-id"].length > 0
    ) {
      setAccessToken(data.access_token);
      setResellerId(
        decoded["https://hasura.io/jwt/claims"]["x-hasura-reseller-id"]
      );
      localStorage.setItem("user_id", decoded["https://hasura.io/jwt/claims"]["x-hasura-msgbox-id"]);
    } else {
      console.log("JWT token is not valid for reseller");
      removeAccessToken();
      document.location.href = `/login?notreseller`;
    }
    return data.access_token;
  } catch (error) {
    removeAccessToken();
    document.location.href = `/login`;
    console.log("error", error);
  }
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (networkError) {
      console.log("network error");
      return fromPromise(getNewToken())
        .filter(async value => Boolean(value))
        .flatMap(token => {
          const oldHeaders = operation.getContext().headers;
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `Bearer ${token}`,
            },
          });
          setAccessToken(token);
          wsLink.close(true);
          return forward(operation);
        });
    }
    if (graphQLErrors) {
      console.log("graphQLErrors", graphQLErrors);
      console.log("operation", operation);
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case "invalid-jwt":
          case "invalid-headers":
            return fromPromise(getNewToken())
              .filter(async value => Boolean(value))
              .flatMap(token => {
                const oldHeaders = operation.getContext().headers;
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${token}`,
                  },
                });
                setAccessToken(token);
                wsLink.close(true);
                return forward(operation);
              });
          default:
            break;
        }
      }
    }
  }
);

const _link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const authMiddleware = new ApolloLink((operation, forward) => {
  console.log("operation", operation.getContext());
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  }));

  return forward(operation);
});

const link = new ApolloLink.from([errorLink, authLink, _link]);

export default link;
