import React, { Dispatch, SetStateAction, useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import TemplateEntry from "./TemplateEntry";
import { templateConfigs } from "./config";
import { ThemeContext } from "src/helpers/themeContext";
import {
  GET_ORGANISATIONS,
  GET_API_ACCOUNTS,
  GET_BASE_DATA,
} from "./query";

import client from "../../apollo";
import SweetAlert from "react-bootstrap-sweetalert";
import { toNumber } from "lodash";
import { createTemplate, ICreateTemplate } from "src/common/templateUtility";

interface IProps {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  onRefetch: any;
  inboxId?: number;
  orgName?: string;
  orgId?: number;
  apiAccountId?: number;
}

const AddPrebuiltTemplate = (props: IProps) => {
  const handleShow = () => {
    props.setModal(false);
  };
  const toggle = () => {
    props.setModal(!props.modal);
  };
  const Theme = useContext(ThemeContext)
  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);

  const [organisations, setOrganisations] = useState([]);
  const [apiAccounts, setApiAccounts] = useState([]);

  const [showApi, setShowApi] = useState<boolean>(false);
  const [showList, setShowList] = useState<boolean>(false);
  const [showExamples, setShowExamples] = useState<boolean>(false);

  const [selectedOrg, setSelectedOrg] = useState<number>(-1);
  const [selectedOrgname, setSelectedOrgname] = useState<string>("");
  const [selectedInboxName, setSelectedInboxName] = useState<string>("");
  const [selectedApi, setSelectedApi] = useState<number>(-1);

  const [templateTypes, setTemplateTypes] = useState<{ [key: string]: number }>(
    {}
  );
  const [templateCategories, setTemplateCategories] = useState<{
    [key: string]: number;
  }>({});

  let selectedTemplates: { [key: number]: any } = {};

  useEffect(() => {
    if (organisations.length === 0) {
      loadBaseData();
    }
  });

  useEffect(() => {
    //console.log("apiAccouns changed");
    if (apiAccounts && apiAccounts[0]) {
      handleApiSelect(apiAccounts[0]["APIAccountId"]);
    }
  }, [apiAccounts]);

  const loadBaseData = async () => {

    const data = await client.query({
      query: GET_BASE_DATA,
    });

    const orgData = data?.data?.msgbox_Organisation || [];
    let orgId
    let orgName
    if(orgData.length > 0){
         orgId = orgData[0]["OrganisationId"];
         orgName = orgData[0]["Name"];
         setSelectedOrg(orgId);
         setSelectedOrgname(orgName);
    }
    setOrganisations(orgData);

    const catData = data?.data?.msgbox_TemplateCategory || [];
    catData.forEach((element: any) => {
      const name = element["Name"];
      templateCategories[name] = element["TemplateCategoryId"];
      setTemplateCategories({ ...templateCategories });
    });

    const typeData = data?.data?.msgbox_TemplateType || [];
    typeData.forEach((element: any) => {
      const desc = element["Description"];
      templateTypes[desc] = element["TemplateTypeId"];
      setTemplateTypes({ ...templateTypes });
    });

    if (orgData.length > 0) {
      loadApis(orgId);
    }
  };


  const loadApis = async (orgId: number) => {
    const apis = await client.query({
      query: GET_API_ACCOUNTS,
      variables: {
        orgId: orgId,
      },
    });
    const apiData = apis?.data?.msgbox_APIAccount || [];
    setApiAccounts(apiData);

    if (apiData.length > 0) {
      const apiId = apiData[0]["APIAccountId"];
      setSelectedApi(apiId);
      setShowList(true);
    } 
    setShowApi(true);
  };

  const handleOrgSelect = async (target: number) => {
    //console.log("handleOrgSelect");
    setShowApi(false);
    setSelectedOrg(target);
    await loadApis(target);
    const selectedOrg = organisations.filter(
      (order: any, key: number) => order["OrganisationId"] == target
    );
    setSelectedOrgname(selectedOrg[0]["Name"]);
    //console.log("Org Name", selectedOrg[0]["Name"]);
  };

  const handleApiSelect = (target: number) => {
    //console.log("handleApiSelect");
    setSelectedApi(target);
    //console.log("target:", target);
    //console.log("apiAccounts:", apiAccounts);
    const selectedApi = apiAccounts.filter(
      (order: any, key: number) => order["APIAccountId"] == target
    );
    //console.log("selectedApi:", selectedApi);
    if (selectedApi && selectedApi[0]) {
      setSelectedInboxName(selectedApi[0]["Name"]);
      //console.log("selected inbox name:", selectedApi[0]["Name"]);
    }
  };

  const handleTemplateClick = (
    templateId: number,
    templateName: string,
    templateText: string,
    footer: string,
    templateExample: string,
    templateType: string,
    templateCategory: string,
    attachmentType: string,
    attachmentFileType: string,
    attachmentExample: string
  ) => {
    console.log("footer:", footer)
    console.log("templateCategory:", templateCategory)
    if (templateId in selectedTemplates) {
      delete selectedTemplates[templateId];
    } else {
      let newTemplate: ICreateTemplate = {
        organisationId: selectedOrg,
        name: templateName,
        created: new Date().toISOString(),
        user: selectedApi,
        tempTypeId: templateTypes[templateType],
        tempCatId: templateCategories[templateCategory],
        apiId: selectedApi,
        tempText: templateText
          .replaceAll("{{orgName}}", selectedOrgname)
          .replaceAll("{{inboxName}}", selectedInboxName),
        tempExample: templateExample
          .replaceAll("{{orgName}}", selectedOrgname)
          .replaceAll("{{inboxName}}", selectedInboxName),
        footer: footer,
        attachmentType: attachmentType,
        attachmentFileType: attachmentFileType,
        attachmentExample: attachmentExample,
      };
      selectedTemplates[templateId] = newTemplate;
    }
    console.log("Template Change", selectedTemplates);
  };

  const handleTemplateSubmit = () => {
    console.log("Template Submitted");

    for (let key in selectedTemplates) {
      if (selectedTemplates.hasOwnProperty(key)) {
        const val = selectedTemplates[key];
        const newTemplateId = createTemplate(val);
        console.log(newTemplateId);
      }
    }

    setdynamic_description("Template(s) created successfully");
    setsuccess_dlg(true);
    toggle();
  };

  const handleModalOpen = () => {
    if (props.orgId) {
      handleOrgSelect(props.orgId);
    }
    if (props.apiAccountId) {
      console.log("setting apiAccountId:", props.apiAccountId);
      handleApiSelect(props.apiAccountId);
    }
  };

  return (
    <React.Fragment>
      {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
            props.onRefetch();
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      <Modal
        isOpen={props.modal}
        toggle={toggle}
        size="lg"
        onOpened={() => handleModalOpen()}
      >
        <ModalHeader toggle={toggle} tag="h4">
          Add Prebuilt Templates
        </ModalHeader>
        <ModalBody className="modelStyle">
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleTemplateSubmit();
            }}
          >
            <Row form className="d-flex flex-row mb-3">
              <Col>
                <FormGroup className="mb-3">
                  <Label>Select an Organisation</Label>
                  <Input
                    className="avInput"
                    id="selectOrg"
                    name="selectOrg"
                    type="select"
                    value={selectedOrg}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      handleOrgSelect(toNumber(e.currentTarget.value));
                    }}
                  >
                    {Array.isArray(organisations)
                      ? organisations.map((order: any, key: number) => (
                          <option key={key} value={order["OrganisationId"]}>
                            {order["Name"]}
                          </option>
                        ))
                      : null}
                  </Input>
                </FormGroup>

                {showApi && (
                  <FormGroup className="mb-3">
                    <Label>Select an API Account</Label>
                    <Input
                      className="avInput"
                      id="selectApi"
                      name="selectApi"
                      type="select"
                      value={selectedApi}
                      onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        handleApiSelect(toNumber(e.currentTarget.value))
                      }
                    >
                      {Array.isArray(apiAccounts) && apiAccounts.length > 0 ? (
                        apiAccounts.map((order: any, key: number) => (
                          <option key={key} value={order["APIAccountId"]}>
                            {order["Name"]}
                          </option>
                        ))
                      ) : (
                        <p>No API Accounts Found</p>
                      )}
                    </Input>
                  </FormGroup>
                )}

                {showList && (
                  <FormGroup className="mb-3">
                    <Label>Select templates to add</Label>

                    <FormGroup check className="mb-2">
                      <Input
                       
                        checked={showExamples}
                        type="checkbox"
                        onChange={() => setShowExamples(!showExamples)}
                      />{" "}
                      <Label check>Show Examples</Label>
                    </FormGroup>

                    {templateConfigs.templateConfig.map(
                      (order: any, key: number) => {
                        return (
                          <div key={key}>
                            <h4>{order["groupName"]}</h4>

                            <Table bordered responsive>
                              <thead>
                                <tr>
                                  <th>✓</th>
                                  <th>Name</th>
                                  <th>Sample</th>
                                </tr>
                              </thead>
                              <tbody>
                                {templateConfigs.templateConfig[
                                  key
                                ].templates.map((val: any, idx: number) => {
                                  return (
                                    <TemplateEntry
                                      key={idx}
                                      template_id={val["templateId"]}
                                      name={val["templateName"]}
                                      text={val["templateText"]}
                                      footer={val["footer"]}
                                      example={val["templateExample"]}
                                      template_type={val["templateType"]}
                                      template_category={
                                        val["templateCategory"]
                                      }
                                      attachmentType={val["attachmentType"]}
                                      attachmentFileType={
                                        val["attachmentFileType"]
                                      }
                                      attachmentExample={
                                        val["attachmentExample"]
                                      }
                                      onClick={handleTemplateClick}
                                      showExample={showExamples}
                                    />
                                  );
                                })}
                              </tbody>
                            </Table>
                          </div>
                        );
                      }
                    )}
                  </FormGroup>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={handleShow}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddPrebuiltTemplate;
