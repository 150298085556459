import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query MyQuery {
    msgbox_APIAccount(order_by: { Name: asc }) {
      APIAccountId
      AppName
      Key
      Name
      PartnerId
      PartnerToken
      PhoneNumber
      CreatedOn
      Inboxes {
        InboxId
        Name
      }
      Organisation {
        Name
        OrganisationId
      }
    }
  }
`;
