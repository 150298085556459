import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { Link } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { useQuery } from "@apollo/client";
import { GET_RECENTSUCCESSFUL_MESSAGES } from './messages.query';

const Messages = () => {
    const [tableData, setTableData] = useState([]);
  
    const columns = [
        {
          dataField: "Id",
          text: "Id",
          sort: true,
        },
        {
          dataField: "IsQueued",
          text: "Is Queued",
          sort: true,
        },
        {
          dataField: "IsSent",
          text: "Is Sent",
          sort: true,
        },
        {
          dataField: "IsDelivered",
          text: "Is Delivered",
          sort: true,
        },
        {
          dataField: "IsRead",
          text: "Is Read",
          sort: true,
        },
        {
          dataField: "IsDeleted",
          text: "Is Deleted",
          sort: true,
        },
        {
          dataField: "IsFailed",
          text: "Is Failed",
          sort: true,
        },
        {
          dataField: "IsTemplate",
          text: "Is Template",
          sort: true,
        },
        {
          dataField: "OrgName",
          text: "Org Name",
          sort: true,
        },
        {
          dataField: "CreatedOn",
          text: "Created",
          sort: true,
        },
    ];
    const defaultSorted: any = [
        {
          dataField: "id",
          order: "asc",
        },
    ];
    const selectRow: any = {
        mode: "checkbox",
    };
    const pageOptions = {
        sizePerPage: 10,
        totalSize: tableData.length, // replace later with size(customers),
        custom: true,
    };
    const { SearchBar } = Search;

    const {
		data, refetch
	    } = useQuery(GET_RECENTSUCCESSFUL_MESSAGES, {
        fetchPolicy: "no-cache"
	});


    useEffect(() => {
        
        if (data && data.msgbox_Message) {
            var tmpData = data.msgbox_Message.filter((item: any) => item.Organisation).map(function(item: { MessageId: any; CreatedOn: string | number | Date; Organisation: { Name: any; }; IsQueued: boolean; IsDelivered: boolean, IsSent: boolean, IsRead: boolean, IsFailed: boolean, IsTemplate: boolean, IsDeleted:boolean }) {
                return {
                    Id: item.MessageId,
                    CreatedOn: new Date(item.CreatedOn).toLocaleDateString() + " " + new Date(item.CreatedOn).toLocaleTimeString(),
                    OrgName: item.Organisation.Name,
                    IsQueued: item.IsQueued,
                    IsDelivered: item.IsDelivered,
                    IsSent: item.IsSent,
                    IsRead: item.IsRead,
                    IsFailed: item.IsFailed,
                    IsDeleted: item.IsDeleted,
                    IsTemplate: item.IsTemplate,

                }
            })
            setTableData(tmpData)
        }
    }, [data])

    const refetchData = () => {
      refetch();
    };

    return (
        <React.Fragment>
            <Card>
            <CardHeader>
              <Row>
                <Col md={6}>
                      <div className="mb-3">
                        <h4 className="card-title">Recent Successful Messages</h4>
                      </div>
                    </Col>
                    <Col md={6}>
              <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                <div>
                  <Link to="#" className="btn btn-light" onClick={refetchData}>
                    <i className="uil uil-refresh me-1"></i> Refresh
                  </Link>
                </div>
              </div>
            </Col>
                 </Row>
                </CardHeader>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  // columns={columns}
                  // data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="Id"
                        columns={columns}
                        data={tableData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    // responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
          </Card>
        </React.Fragment >
    );
};

export default Messages;
