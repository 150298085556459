import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const MessageChart = (props:any) => {

    const{dataForLast30Day} = props;
    const[groupedMsgPerDay, setGroupedMsgPerDay] = useState<string[]>([]);
 
    useEffect(() => {
        if(dataForLast30Day){
            const messagesCountPerDay =  dataForLast30Day.reduce((acc:any, message:any) => {
                const ukDate = new Date(message.Date).toLocaleDateString('en-GB').slice(0,5);  
                acc[ukDate] = message.SentCount;
                return acc;
              }, {});           
            const last30DaysDates = Array.from({ length: 30 }, (_, index) => {
                const date = new Date();
                date.setDate(date.getDate() - index - 1);
                return date.toLocaleDateString('en-GB').slice(0,5);
            });

            last30DaysDates.forEach((dt) => {
                if (!(dt in messagesCountPerDay)) {
                   messagesCountPerDay[dt] = 0;
                }
            });         
            setGroupedMsgPerDay(messagesCountPerDay);    
        }
    }, [dataForLast30Day]);
    

    const dates = Object.keys(groupedMsgPerDay)
        .sort((a, b) => {
            const dateA:any = new Date(a.split('/').reverse().join('-'));
            const dateB:any = new Date(b.split('/').reverse().join('-'));
            return dateA - dateB;
      });
     
   const series = [
            {
                name: "Messages",
                data: dates.map((date : any) => groupedMsgPerDay[date]) 
            }
        ];

   var options = {
            chart: {
                id: "area-datetime",
                dropShadow: {
                    enabled: true,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 4,
                    opacity: 0.075
                },
                zoom: {
                    autoScaleYaxis: true
                },
                toolbar: {
                    show: false,
                }
            },

            dataLabels: {
                enabled: false
            },
            markers: {
                size: 3,
                strokeWidth: 3,

                hover: {
                    size: 6,
                }
            },
            stroke: {
                show: true,
                // curve: "smooth",
                width: 3,
                dashArray: 0,
            },
            xaxis: {
                categories: dates,
            },
            yaxis: {
                labels: {
                    show: false,
                }
            },
            tooltip: {
                x: {
                    format: "dd MMM yyyy"
                }
            },
            grid: {
                show: true,
                xaxis: {
                    lines: {
                        show: true,
                    }
                },
                yaxis: {
                    lines: {
                        show: false,
                    }
                }
            },
            colors: ["#038edc"],
            fill: {
                opacity: 0.05,
                colors: ["#038edc"],
                type: "solid",
            },
        };
   
  


    return (
        <React.Fragment>
            <ReactApexChart  options={options} series={series} type="area" height={440} className="apex-charts" />
        </React.Fragment>
    );
};

export default MessageChart;