
import client from "../apollo";
import { gql } from "@apollo/client";

export interface ICreateUserProps {
  firstName: string;
  lastName: string;
  isBot: boolean;
  isApi: boolean;
  userId: number;
  orgId: number;
  inboxId: number;
  email?: string | null;
  imageUrl?: string | null;
  hasImage: boolean;
}

export const GetUser = async (email: string) => {
  const userResponse = await client.query({
    query: GET_USER,
    variables: {
      email: email,
    },
  });
  if (userResponse.data && userResponse.data.msgbox_User && userResponse.data.msgbox_User[0]) {
    return { firstName: userResponse.data.msgbox_User[0].FirstName, lastName: userResponse.data.msgbox_User[0].LastName, hasImage: userResponse.data.msgbox_User[0].HasImage, imageURL: userResponse.data.msgbox_User[0].signedurl?.url }
  }
  return null;
};

export const CreateUser = async (props: ICreateUserProps) => {
  const orgBotResponse = await client.mutate({
    mutation: CREATE_USER,
    variables: {
      hasImage: props.hasImage,
      imageURI: props.imageUrl,
      isBot: props.isBot,
      isApi: props.isApi,
      firstName: props.firstName,
      lastName: props.lastName,
      organisationId: props.orgId,
      email: props.email,
    },
  });
  props.userId = orgBotResponse.data.insert_msgbox_User.returning[0].UserId;

  return props;
};

export interface IAddUserToRoleProps {
  orgId: number;
  userId: number;
  roleId: number;
}

export const AddUserToRole = async (props: IAddUserToRoleProps) => {
  const addResponse = await client.mutate({
    mutation: ADD_USER_TO_ROLE,
    variables: {
      userId: props.userId,
      organisationId: props.orgId,
      roleId: props.roleId,
    },
  });

  return true;
};

const GET_USER = gql`
query MyQuery($email: String!) {
  msgbox_User(where: {EMail: {_eq: $email}}) {
    UserId
    FirstName
    LastName
    HasImage
    ImageURI
    signedurl {
      url
    }
  }
}
`

const CREATE_USER = gql`
mutation MyMutation(
  $hasImage: Boolean
  $imageURI: String
  $isBot: Boolean
  $isApi: Boolean
  $lastName: String!
  $organisationId: Int!
  $firstName: String!
  $email: String
) {
  insert_msgbox_User(
    objects: {
      HasImage: $hasImage
      ImageURI: $imageURI
      IsBot: $isBot
      IsAPI: $isApi
      LastName: $lastName
      OrganisationId: $organisationId
      FirstName: $firstName
      EMail: $email
    }
  ) {
    returning {
      UserId
    }
  }
}
`;

const ADD_USER_TO_ROLE = gql`
  mutation MyMutation($organisationId: Int!, $roleId: Int!, $userId: Int!) {
    insert_msgbox_UserRole(
      objects: {
        UserId: $userId
        RoleId: $roleId
        OrganisationId: $organisationId
      }
    ) {
      affected_rows
    }
  }
`;
