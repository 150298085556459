import { gql } from "@apollo/client";

export const SUCCESSFUL_MSGS_DATA_COUNT = gql`
query getMessageCount($start:date $end:date){
  reporting_MessageStatusByReseller(where: {
        Date:{_gte: $start ,_lte:$end }
      }) {
        DeliveredCount
    		Date
    }
}
`;  