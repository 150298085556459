
import client from "../apollo";
import { gql } from "@apollo/client";

export interface ICreateApiProps {
  apiAccountId: number;
  providerId: number;
  orgId: number;
  name: string;
  apiKey: string;
  appName: string;
  partnerId: string;
  partnerToken: string;
  phoneNumber: string;
}

export const CreateApiAccount = async (props: ICreateApiProps) => {
  const apiAccountResponse = await client.mutate({
    mutation: CREATE_API_ACCOUNT,
    variables: {
      apiProviderId: props.providerId,
      appName: props.appName,
      apikey: props.apiKey,
      name: props.name,
      organisationId: props.orgId,
      partnerId: props.partnerId,
      partnerToken: props.partnerToken,
      phoneNumber: props.phoneNumber,
    },
  });
  props.apiAccountId =
    apiAccountResponse.data.insert_msgbox_APIAccount.returning[0].APIAccountId;

  return props;
};

export interface ICreateInboxProps {
  inboxId: number;
  apiAccountId: number;
  orgId: number;
  name: string;
}

export const CreateInbox = async (props: ICreateInboxProps) => {
  const inboxResponse = await client.mutate({
    mutation: CREATE_INBOX,
    variables: {
      inboxId: props.inboxId,
      orgId: props.orgId,
      name: props.name,
      apiAccountId: props.apiAccountId,
    },
  });

  props.inboxId = inboxResponse.data.insert_msgbox_Inbox.returning[0].InboxId;

  return props;
};

export interface ICreateFolderProps {
  folderId: number;
  inboxId: number;
  name: string;
  isInbox: boolean;
  isArchive: boolean;
  isSpam: Boolean;
  order: number;
}

export const CreateFolder = async (props: ICreateFolderProps) => {
  const folderResponse = await client.mutate({
    mutation: CREATE_FOLDER,
    variables: {
      inboxId: props.inboxId,
      isInbox: props.isInbox,
      isArchive: props.isArchive,
      isSpam: props.isSpam,
      name: props.name,
      order: props.order,
    },
  });
  props.folderId =
    folderResponse.data.insert_msgbox_Folder.returning[0].FolderId;

  return props;
};

export interface ICreateAutomationReponse {
  orgId: number;
  apiAccountId: number;
  keyword: string;
  reply: string;
  isDeletable: boolean;
}

export const CreateAutomation = async (props: ICreateAutomationReponse) => {
  const addResponse = await client.mutate({
    mutation: ADD_AUTOMATION_RESPONSE,
    variables: {
      organisationId: props.orgId,
      apiAccountId: props.apiAccountId,
      keyword: props.keyword,
      reply: props.reply,
      botResponseType: "KeywordOnly",
      isDeleted: false,
      isEnabled: true,
      captureEmailAddress: false,
      isDeletable: props.isDeletable,
    },
  });

  return true;
};

export interface IAddUserToInboxProps {
  orgId: number;
  inboxId: number;
  userId: number;
}

export const AddUserToInbox = async (props: IAddUserToInboxProps) => {
  const addResponse = await client.mutate({
    mutation: ADD_USER_TO_INBOX,
    variables: {
      inboxId: props.inboxId,
      organisationId: props.orgId,
      userId: props.userId,
    },
  });

  return true;
};


const CREATE_API_ACCOUNT = gql`
  mutation MyMutation(
    $appName: String!
    $apikey: String!
    $name: String!
    $organisationId: Int!
    $partnerId: String
    $partnerToken: String
    $phoneNumber: String!
    $apiProviderId: Int!
  ) {
    insert_msgbox_APIAccount(
      objects: {
        AppName: $appName
        Key: $apikey
        Name: $name
        OrganisationId: $organisationId
        PartnerId: $partnerId
        PartnerToken: $partnerToken
        PhoneNumber: $phoneNumber
        APIProviderId: $apiProviderId
      }
    ) {
      returning {
        APIAccountId
      }
    }
  }
`;

const CREATE_INBOX = gql`
  mutation MyMutation($name: String!, $orgId: Int!, $apiAccountId: Int!) {
    insert_msgbox_Inbox(
      objects: {
        Name: $name
        OrganisationId: $orgId
        APIAccountId: $apiAccountId
      }
    ) {
      returning {
        InboxId
      }
    }
  }
`;

const CREATE_FOLDER = gql`
  mutation MyMutation(
    $name: String!
    $isInbox: Boolean!
    $order: Int!
    $isArchive: Boolean!
    $isSpam: Boolean!
    $inboxId: Int!
  ) {
    insert_msgbox_Folder(
      objects: {
        Name: $name
        IsInbox: $isInbox
        Order: $order
        IsArchive: $isArchive
        IsSpam: $isSpam
        InboxId: $inboxId
      }
    ) {
      returning {
        FolderId
      }
    }
  }
`;

const ADD_USER_TO_INBOX = gql`
  mutation MyMutation($inboxId: Int!, $organisationId: Int!, $userId: Int!) {
    insert_msgbox_UserInbox(
      objects: {
        InboxId: $inboxId
        OrganisationId: $organisationId
        UserId: $userId
      }
    ) {
      affected_rows
    }
  }
`;


const ADD_AUTOMATION_RESPONSE = gql`
  mutation MyMutation(
    $botResponseType: String!
    $isDeleted: Boolean!
    $isEnabled: Boolean
    $keyword: String!
    $reply: String!
    $apiAccountId: Int!
    $captureEmailAddress: Boolean
    $organisationId: Int!
    $isDeletable: Boolean
  ) {
    insert_msgbox_BotResponse(
      objects: {
        APIAccountId: $apiAccountId
        IsDeleted: $isDeleted
        IsEnabled: $isEnabled
        Keyword: $keyword
        Reply: $reply
        BotResponseType: $botResponseType
        CaptureEmailAddress: $captureEmailAddress
        OrganisationId: $organisationId
        IsDeletable: $isDeletable
      }
    ) {
      affected_rows
    }
  }
`;