import React, { Dispatch, SetStateAction, useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  GET_ORGANISATIONS,
  GET_API_ACCOUNTS,
  GET_TEMPLATE_TYPES,
  GET_TEMPLATE_CATEGORIES,
  GET_DEFAULTS
} from "./query";

import client from "../../apollo";
import SweetAlert from "react-bootstrap-sweetalert";
import { toNumber } from "lodash";
import { ICreateEmailForwarderProps, CreateEmailForwarder } from "src/common/emailParserUtility";
import { ThemeContext } from "src/helpers/themeContext";
interface IProps {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  inboxId?: number;
  orgName?: string;
  orgId?: number;
  apiAccountId?: number;
}

const AddEmailForwarder = (props: IProps) => {
  const handleShow = () => {
    props.setModal(false);
  };
  const toggle = () => {
    props.setModal(!props.modal);
  };
  const Theme = useContext(ThemeContext)
  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);
  const [organisations, setOrganisations] = useState([]);
  const [apiAccounts, setApiAccounts] = useState([]);
  const [showApi, setShowApi] = useState<boolean>(false);
  const [selectedOrg, setSelectedOrg] = useState<number>(-1);
  const [selectedApi, setSelectedApi] = useState<number>(-1);
  const [options, setOptions] = useState<string[]>(['false'])

  const [templateTypes, setTemplateTypes] = useState<{ [key: string]: number }>(
    {}
  );
  const [templateCategories, setTemplateCategories] = useState<{
    [key: string]: number;
  }>({});

  let selectedTemplates: { [key: number]: any } = {};

  useEffect(() => {
    if (organisations.length === 0) {
      loadOrgs();
      loadTemplateTypes();
      loadTemplateCategories();
    }
  });

  useEffect(() => {
    //console.log("apiAccouns changed");
    if (apiAccounts && apiAccounts[0]) {
      handleApiSelect(apiAccounts[0]["APIAccountId"]);
    }
  }, [apiAccounts]);

useEffect(()=>{
  getOptions()
}, [selectedApi])

  async function getOptions () {
    if(!selectedApi) return
    let res: any = await client.query({
      query: GET_DEFAULTS,
      variables: {apiAccountId: selectedApi},
      fetchPolicy: "no-cache"
    }).catch(err => {
      console.log("error:", err)
    });
    if(res?.data?.msgbox_EmailParserOutgoingEmail.length === 0){
      setOptions(['false', 'true'])
    } else {
      setOptions(['false'])
    }
  }

  const loadOrgs = async () => {
    const orgs = await client.query({
      query: GET_ORGANISATIONS,
    });
    const orgData = orgs?.data?.msgbox_Organisation || [];
    const orgId = orgData[0]["OrganisationId"];

    setOrganisations(orgData);
    setSelectedOrg(orgId);

    if (orgData.length > 0) {
      loadApis(orgId);
    }
  };

  const loadApis = async (orgId: number) => {
    const apis = await client.query({
      query: GET_API_ACCOUNTS,
      variables: {
        orgId: orgId,
      },
    });
    const apiData = apis?.data?.msgbox_APIAccount || [];
    setApiAccounts(apiData);

    if (apiData.length > 0) {
      // console.log("select first api account, more than 0");
      const apiId = apiData[0]["APIAccountId"];
      setSelectedApi(apiId);
    } else {
      //console.log("only 0");
    }
    setShowApi(true);
  };

  const loadTemplateTypes = async () => {
    const types = await client.query({
      query: GET_TEMPLATE_TYPES,
    });
    const typeData = types?.data?.msgbox_TemplateType || [];

    typeData.forEach((element: any) => {
      const desc = element["Description"];
      templateTypes[desc] = element["TemplateTypeId"];
      setTemplateTypes({ ...templateTypes });
    });
  };

  const loadTemplateCategories = async () => {
    const categories = await client.query({
      query: GET_TEMPLATE_CATEGORIES,
    });
    const catData = categories?.data?.msgbox_TemplateCategory || [];

    catData.forEach((element: any) => {
      const name = element["Name"];
      templateCategories[name] = element["TemplateCategoryId"];
      setTemplateCategories({ ...templateCategories });
    });
  };

  const handleOrgSelect = async (target: number) => {
    //console.log("handleOrgSelect");
    setShowApi(false);
    setSelectedOrg(target);
    await loadApis(target);
    const selectedOrg = organisations.filter(
      (order: any, key: number) => order["OrganisationId"] == target
    );
  };

  const handleApiSelect = (target: number) => {
    setSelectedApi(target);
    const selectedApi = apiAccounts.filter(
      (order: any, key: number) => order["APIAccountId"] == target
    );
  };

  const handleEmailParserSubmit = async (values: any) => {
    console.log("Email Parser Submitted");
console.log("values:", values)
    var emailForwarder: ICreateEmailForwarderProps | null = {
      emailForwarderId: 0,
      apiAccountId: selectedApi,
      orgId: selectedOrg,
      emailAddress: values["emailAddress"],
      matchExpression: values["matchExpression"],
      defaultEmail: values["defaultEmail"],

    };
    console.log("emailForwarder:", emailForwarder)
    var newEmailForwarder = await CreateEmailForwarder(emailForwarder).catch(err => {
      console.log("error:",err)
      setdynamic_description("Failed to create Email Forwarder");
      setfailure_dlg(true);
      toggle();
      return null;
    });
    if (newEmailForwarder == null) {
      return false;
    }

    setdynamic_description("Email Parser created successfully");
    setsuccess_dlg(true);
    toggle();
  };

  const handleModalOpen = () => {
    if (props.orgId) {
      handleOrgSelect(props.orgId);
    }
    if (props.apiAccountId) {
      console.log("setting apiAccountId:", props.apiAccountId);
      handleApiSelect(props.apiAccountId);
    }
  };

  return (
    <React.Fragment>
      {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      {failure_dlg ? (
        <SweetAlert
          error
          title={Theme.name}
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}      
      <Modal
        isOpen={props.modal}
        toggle={toggle}
        size="lg"
        onOpened={() => handleModalOpen()}
      >
        <ModalHeader toggle={toggle} tag="h4">
          Add New Email Forwarder
        </ModalHeader>
        <ModalBody className="modelStyle">
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleEmailParserSubmit(values);
            }}
          >
            <Row form className="d-flex flex-row mb-3">
              <Col>
                <FormGroup className="mb-3">
                  <Label>Select an Organisation</Label>
                  <Input
                    className="avInput"
                    id="selectOrg"
                    name="selectOrg"
                    type="select"
                    value={selectedOrg}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      handleOrgSelect(toNumber(e.currentTarget.value));
                    }}
                  >
                    {Array.isArray(organisations)
                      ? organisations.map((order: any, key: number) => (
                          <option key={key} value={order["OrganisationId"]}>
                            {order["Name"]}
                          </option>
                        ))
                      : null}
                  </Input>
                </FormGroup>

                {showApi && (
                  <FormGroup className="mb-3">
                    <Label>Select an API Account</Label>
                    <Input
                      className="avInput"
                      id="selectApi"
                      name="selectApi"
                      type="select"
                      value={selectedApi}
                      onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        handleApiSelect(toNumber(e.currentTarget.value))
                      }
                    >
                      {Array.isArray(apiAccounts) && apiAccounts.length > 0 ? (
                        apiAccounts.map((order: any, key: number) => (
                          <option key={key} value={order["APIAccountId"]}>
                            {order["Name"]}
                          </option>
                        ))
                      ) : (
                        <p>No API Accounts Found</p>
                      )}
                    </Input>
                  </FormGroup>
                )}

                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="emailAddress"
                    label="Email Address"
                    type="email"
                    placeholder="Enter destination email address"
                    errorMessage="Invalid email address"
                    validate={{
                      required: { value: true }
                    }}
                    value={""}
                  />
                </div>

                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="matchExpression"
                    label="Match Expression"
                    type="regex"
                    placeholder="Enter match expression"
                    errorMessage="Invalid match expression"
                    validate={{
                      required: { value: true }
                    }}
                    value={""}
                  />
                </div>
                <div className="mb-3">
                  <AvField className="avInput" type="select" id="defaultEmail" value="false" name="defaultEmail" label="Default Email Address">
                  {options.map((item: string, index: number) => {
                      return <option key={index} value={item}>{item}</option>
                    })}
                  </AvField>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={handleShow}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddEmailForwarder;
