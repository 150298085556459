import React, { Dispatch, SetStateAction, useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from "reactstrap";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import { useMutation, useQuery } from "@apollo/client";
import { ThemeContext } from "src/helpers/themeContext";
import { EDIT_MSGBOX_TEMPLATE, GET_INBOXES, GET_ORGS, GET_TEMPLATE_CATEGORIES, GET_TEMPLATE_LANGUAGES, GET_TEMPLATE_TYPES } from "./query";
import {useFilePicker} from 'use-file-picker';
import UploadFile from "src/helpers/uploadFIle";

interface IProps {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  onRefetch: any;
  data: any;
}

const EditTemplate = (props: IProps) => {

  const toggle = () => {
    props.setModal(!props.modal);
  };

  const URL_REGEX = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  const PHONE_REGEX = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{8,14}$/;
  
  const ATTACHMENT_OPTIONS =  [
    {
      label: "No attachment",
      value: "None",
    },
    {
      label: "Image",
      value: "Image",
    },
    {
      label: "Document",
      value: "Document",
    },
  
    {
      label: "Video",
      value: "Video",
    },
  ]

  const Theme = useContext(ThemeContext)

  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);

  const [showingPreview, setShowingPreview] = useState(false)

  const [organisations, setOrganisations] = useState([]);
  const [inboxes, setInboxes] = useState([]);
  const [languages, setLanguages] = useState([]); 
  const [types, setTypes] = useState([]);  
  const [catergories, setCatergories] = useState([]);

  const[Name, setName]= useState("")
  const [selectedOrg, setSelectedOrg] = useState<any>(false);
  const [selectedInbox, setSelectedInbox] = useState<any>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<any>(false);
  const [selectedType, setSelectedType] = useState<any>(false);
  const [selectedCat, setSelectedCat] = useState<any>(false);
  const [selectedAttachment, setSelectedAttachment] = useState<any>(false)
  const [selectedAttachmentOption, setSelectedAttachmentOption] = useState<any>(false)
  const [messageText, setMessageText] = useState<String>("")
  const [previewText, setPreviewText]= useState("")
  const [quickReplyButtons, setQuickReplyButtons] = useState<any>([])
  const [callToActionButtons, setCallToActionButtons] = useState<any>([])

  const [openFileSelector, { plainFiles, loading }] = useFilePicker({
    multiple: false
  });
  


  const {data: orgs} = useQuery(GET_ORGS)
  const {data: inboxData, refetch: RefetchInboxData} = useQuery(GET_INBOXES,  {
    variables: {
      org: selectedOrg?.value || -1
    }
  })
  const {data: langData} = useQuery(GET_TEMPLATE_LANGUAGES)
  const {data: typeData} = useQuery(GET_TEMPLATE_TYPES)
  const {data: catData} = useQuery(GET_TEMPLATE_CATEGORIES)
  const[editTemplate] = useMutation(EDIT_MSGBOX_TEMPLATE)

  useEffect(() => {
    if(!props.data) return
    setName(props.data.Name)
    setMessageText(props.data.TemplateText)
    setPreviewText(props.data.TemplateExample)
    setSelectedInbox({label: props.data.InboxName, value: props.data.APIAccountId})

    let arr: any = organisations.filter((item: any) => {
      return item.value == props.data.OrgId
    })
    if(arr[0]) setSelectedOrg(arr[0])

    arr = languages.filter((item: any) => {
      return item.value == props.data.LanguageCode
    })
    if(arr[0]) setSelectedLanguage(arr[0])


    arr = catergories.filter((item: any) => {
      return item.value == props.data.TemplateCategoryId
    })
    if(arr[0]) setSelectedCat(arr[0])


    arr = types.filter((item: any) => {
      return item.value == props.data.TemplateType.TemplateTypeId
    })
    if(arr[0]) setSelectedType(arr[0])


    arr = ATTACHMENT_OPTIONS.filter((item: any) => {
      return item.value == props.data.Attachment
    })
    if(arr[0]) setSelectedAttachmentOption(arr[0])

    if(props.data.AttachmentId) setSelectedAttachment(props.data.AttachmentId)

    let buttonmeta = JSON.parse(props.data.ButtonMetadata) || []
    let qr: any = []
    let ca: any = []
		buttonmeta.forEach((item: any) => {
			if(item.type == "QUICK_REPLY") {
				qr.push({type: "basic", title: item.text})
			} else if(item.type == "URL") {
        if(item.url.endsWith("/(1)")){
          let newURL = item.url.substring(0, item.url.length - 3); // remove the /(1)
          ca.push({type: "dynamicURL", url: newURL, title: item.text})
        } else {
          ca.push({type: "staticURL", url: item.url, title: item.text})
        }
			} else if(item.type == "PHONE_NUMBER" ){ 
				ca.push({type: "phone", title: item.text, phone: item.phone_number})
			}
		}) 
    setCallToActionButtons(ca)
    setQuickReplyButtons(qr)
  }, [props?.data])

  useEffect(() => {
    if(plainFiles.length > 0) {
      const type = plainFiles[0].type;
			if (type === "application/pdf"){
        setSelectedAttachmentOption(  {
          label: "Document",
          value: "Document",
        })
    } else if (type.includes("image")){
       setSelectedAttachmentOption({
        label: "Image",
        value: "Image",
      })
    }
			else if (type.includes("video")) {
        setSelectedAttachmentOption(  {
        label: "Video",
        value: "Video",
      }) 
     } else {
       setSelectedAttachmentOption(  {
        label: "Document",
        value: "Document",
      });
    }
      setSelectedAttachment(plainFiles[0])
    }
  }, [plainFiles])  

  useEffect(() => {
      if(!langData) return;
      let arr = langData?.msgbox_TemplateLanguage?.map((item: any) => {
          return {label: item.Description, value: item.LanguageCode}
      });
      setLanguages(arr);
  }, [langData]);

  useEffect(() => {
    if(!typeData) return;
    let arr = typeData?.msgbox_TemplateType?.map((item: any) => {
        return {label: item.Description, value: item.TemplateTypeId}
    });
    setTypes(arr); 
  }, [typeData]);

  useEffect(() => {
    if(!catData) return;
    let arr = catData?.msgbox_TemplateCategory?.map((item: any) => {
        return {label: item.Name, value: item.TemplateCategoryId}
    });
    setCatergories(arr); 
  }, [catData]);


  useEffect(() => {
    if(!orgs) return;
    let arr = orgs?.msgbox_Organisation?.map((item: any) => {
        return {label: item.Name, value: item.OrganisationId}
    });
    setOrganisations(arr); 
}, [orgs]);

useEffect(() => {
    if(!inboxData) return;
    let arr = inboxData?.msgbox_Inbox?.map((item: any) => {
        return {label: item.Name, value: item.APIAccountId}
    });
    setInboxes(arr); 
}, [inboxData]);


useEffect(() => {
  RefetchInboxData();
}, [selectedOrg]);

  const handleValidTemplateSubmit = async (values: any) => {
    try {
    if(
      !selectedOrg?.value ||
      !selectedInbox?.value ||
      !selectedLanguage?.value ||
      !selectedCat?.value ||
      !selectedType?.value || 
      !selectedAttachmentOption?.value || 
      !props.data.Id ||
      !(selectedAttachmentOption.value == "None"  || selectedAttachment)
    ) return

    let attachmentId = null
    if(selectedAttachmentOption.value !== "None"){
      if(typeof selectedAttachment !== "number"){
        let atId = await UploadFile(selectedAttachment, selectedOrg?.value)
        if(atId){
          attachmentId = atId
        } else {
          setdynamic_description("Template edit failed");
          setfailure_dlg(true);
          return
        }
      } else {
        attachmentId = selectedAttachment
      }
    }
    let buttonMetaData = null

		let arrQ = quickReplyButtons.map((item: any) => {
      return {"type":"QUICK_REPLY","text":item.title}
    })

    let arrC = callToActionButtons.map((item: any) => {
      if(item.type == "staticURL") {
        return {"type": "URL","text": item.title,"url": item.url }
      } else if (item.type == "dynamicURL") {
        return {"type": "URL","text": item.title, "url": item.url.endsWith("/") ? item.url + "{1}" : item.url + "/{1}" }
      } else {
        let newNum = item.phone.replace("+", "")
				return {"type": "PHONE_NUMBER","text": item.title,"phone_number": newNum }
      }
    })

    if(arrC.length !== 0 || arrQ.length !== 0) buttonMetaData = JSON.stringify([...arrC, ...arrQ])

    let obj = {
      name: values.Name,
      messageText: messageText,
      attachment: selectedAttachmentOption.value,
      category: selectedCat.value,
      templateType: selectedType.value,
      account: selectedInbox.value,
      exampleMessage: previewText,
      attachmentId: attachmentId,
      language: selectedLanguage.value,
      buttonMetaData: buttonMetaData,
      Id: props.data.Id
    }
    await editTemplate({
      variables: obj
    })
    setdynamic_description("Template edited successfully");
    setsuccess_dlg(true);
    toggle();
  } catch {
    setdynamic_description("Template edit failed");
    setfailure_dlg(true);
  }
  };


  const selectStyle = {
    control: (provided: any) => ({
        ...provided,
        backgroundColor: Theme.styles.primary, // primary  
        border: `1px solid ${Theme.styles.primaryBold}`, //primary bold
        color: Theme.styles.textColour1,   //textColor1
        borderColor: Theme.styles.primary
    }), 
    singleValue: (provided: any) => ({
      ...provided,
      color: Theme.styles.textColour1,   //textColor1
     }), 
      menu: (provided: any) => ({
        ...provided,
        backgroundColor: Theme.styles.primary, // primary  
        border: `1px solid ${Theme.styles.primaryBold}`, //primary bold
        color: Theme.styles.textColour1,   //textColor1
    }), 
    menuList: (provided: any) => ({
        ...provided,
        backgroundColor: Theme.styles.primary, // primary  
        border: `1px solid ${Theme.styles.primaryBold}`, //primary bold
        color: Theme.styles.textColour1,   //textColor1
    }), 
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
        return {
          ...styles,
          backgroundColor: isFocused ? Theme.styles.secondary : null,
          color: Theme.styles.textColour1,   //textColor1
        };
      }, 
      multiValue: (styles: any) => {
        return {
          ...styles,
          backgroundColor: Theme.styles.secondary,
        };
      },
      multiValueLabel: (styles: any) => ({
        ...styles,
        color: Theme.styles.textColour1,   //textColor1
      }),
      multiValueRemove: (styles: any) => ({
        ...styles,
        color: Theme.styles.textColour1,   //textColor1
        ':hover': {
          backgroundColor: Theme.styles.primary,
        },
      }),
    }


    function validatePreviewText(t: any){
      const regEx = /{{.*?}}/g;
      const newText = messageText.replaceAll(regEx, "X");
      const exampleRegEx = /\[.*?\]/g;
      const newExample = t.replaceAll(exampleRegEx, "X");

      const numberInBracketsRegEx = /\[\d+\]/g;
      const containsNumbersInBrackets = numberInBracketsRegEx.test(t);
      if(containsNumbersInBrackets) return false

      return newText === newExample;
    }

  return (
    <React.Fragment>
      {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
            props.onRefetch();
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      {failure_dlg ? (
        <SweetAlert
          error
          title={Theme.name}
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      
      <Modal
        isOpen={props.modal}
        toggle={toggle}
        size="xl"
      >
        <ModalHeader toggle={toggle} tag="h4">
          Edit Template
          <p style={{fontSize: 12, marginBottom: 0}}>Use the form below to add a template message. Name muse be atleast 5 characters, be lower case, numbers and _ only. The template must not start or end with the variable field</p>
        </ModalHeader>
        <ModalBody className="modelStyle">
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleValidTemplateSubmit(values);
            }}
          >
            <Row form className="d-flex flex-row mb-3">
              <Col>    
               <div className="mb-2">
                  <AvField
                    className="avInput"
                    name="Name"
                    label="Name"
                    type="text"
                    placeholder="Enter Template Name"
                    errorMessage="Invalid Template Name"
                    validate={{
                      required: { value: true },
                      pattern: {value: /^[0-9a-z_]{5,}$/, errorMessage: "Name muse be atleast 5 characters, be lower case, numbers and _ only"}
                    }}
                    value={Name}
                    onChange={(e: any) => setName(e.target.value)}
                  />
                </div>
                {/* <div className="mb-2">
                  <Label>Organisation</Label>
                  <Select
                    isClearable={true}
                    name="organisation"
                    placeholder="Select"
                    options={organisations}
                    onChange={(e: any) => setSelectedOrg(e)}
                    styles={selectStyle}
                    isDisabled={true}
                    value={selectedOrg}
                  />
                </div> */}
                <div className="mb-2">
                  <Label>Account</Label>
                  <Select
                    isClearable={true}
                    name="account"
                    placeholder="Select"
                    options={inboxes}
                    onChange={(e: any) => setSelectedInbox(e)}
                    styles={selectStyle}
                    value={selectedInbox}

                  />
                </div>
                <div className="mb-2">
                  <Label>Language</Label>
                  <Select
                    isClearable={true}
                    name="language"
                    placeholder="Select"
                    options={languages}
                    onChange={(e: any) =>setSelectedLanguage(e)}
                    styles={selectStyle}
                    value={selectedLanguage}
                  />
                </div>
                <div className="mb-2">
                  <Label>Category</Label>
                  <Select
                    isClearable={true}
                    name="catergory"
                    placeholder="Select"
                    options={catergories}
                    onChange={(e: any) =>setSelectedCat(e)}
                    styles={selectStyle}
                    value={selectedCat}

                  />
                </div>
                <div className="mb-2">
                  <Label>Type</Label>
                  <Select
                    isClearable={true}
                    name="type"
                    placeholder="Select"
                    options={types}
                    onChange={(e: any) =>setSelectedType(e)}
                    styles={selectStyle}
                    value={selectedType}

                  />
                </div>
                <div>
                  <Label>Attachment</Label>
                  <Select
                    isClearable={true}
                    name="attachment"
                    placeholder="Select"
                    options={ATTACHMENT_OPTIONS}
                    onChange={(e: any) => {
                      setSelectedAttachmentOption(e)
                      if(e?.value && e?.value !== "None") {
                        openFileSelector()
                      } else {
                        setSelectedAttachment(false)
                      }
                    }}
                    value={selectedAttachmentOption}
                    styles={selectStyle}
                  />
                </div>
              </Col>
              <Col xs={4} className="px-2">
              <div className="mb-2" style={{ display: !showingPreview ? 'none' : 'block' }}>
                <AvField
                  className="avInput"
                  name="previewText"
                  label="Preview Text"
                  type="textarea"
                  placeholder="Enter Preview Text"
                  errorMessage="Invalid Preview Text"
                  validate={{
                    required: { value: true },
                    function: (t: any) => validatePreviewText(t),
                  }}
                  onChange={(e: any) => setPreviewText(e.target.value)}
                  value={previewText}
                  style={{ height: 300}}
                  />
                  </div>
                  <div className="mb-2" style={{ display: showingPreview ? 'none' : 'block' }}>
                <AvField
                  className="avInput"
                  name="messageText"
                  label="Message Text"
                  type="textarea"
                  placeholder="Enter Message Text"
                  errorMessage="Invalid Message Text"
                  id="messageText"
                  validate={{
                    required: { value: true, errorMessage: "Message text is required." },
                    minLength: { value: 5, errorMessage: "Message text must be at least 5 characters." },
                    maxLength: { value: 1000, errorMessage: "Message text must be less than 1000 characters." },
                    pattern: {
                      value: /^(?!\{\{\d+\}\})(?!.*\{\{\d+\}\}$).*/,
                      errorMessage: "Message text must not start or end with a variable"
                    }
                 }}
                  onChange={(e: any) => {
                    setMessageText(e.target.value);
                    var exampleMsg = e.target.value;
                    exampleMsg = exampleMsg.replaceAll(/\{\{\d+\}\}/g, "[test]");
                    setPreviewText(exampleMsg);
                  }}
                  value={messageText}
                  style={{ height: 300}}

                />
            </div>

                  <div className="mb-2" style={{display: 'flex', justifyContent: 'flex-end', gap: 5}}>
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => {
                      const textarea: any = document.querySelector('#messageText');
                      if (!textarea) return;
                    
                      const start = textarea.selectionStart;
                      const end = textarea.selectionEnd;
                      const text = textarea.value;
                      const before = text.substring(0, start);
                      const after = text.substring(end, text.length);
                    
                      if (start === end) {
                        const newText = before + "*" + after;
                        setMessageText(newText);
                        setPreviewText(newText.replaceAll("{{", "[").replaceAll("}}", "]"));
                        setTimeout(() => {
                          textarea.selectionStart = textarea.selectionEnd = start + 1;
                        }, 0);
                      } else {
                        const selectedText = text.substring(start, end);
                        const newText = before + "*" + selectedText + "*" + after;
                        setMessageText(newText);
                        setPreviewText(newText.replaceAll("{{", "[").replaceAll("}}", "]"));
                        setTimeout(() => {
                          textarea.selectionStart = start;
                          textarea.selectionEnd = end + 2; 
                        }, 0);
                      }
                    }}
                    >
                    B
                  </button>
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => {
                      const textarea: any = document.querySelector('#messageText');
                      if (!textarea) return;
                    
                      const start = textarea.selectionStart;
                      const end = textarea.selectionEnd;
                      const text = textarea.value;
                      const before = text.substring(0, start);
                      const after = text.substring(end, text.length);
                    
                      if (start === end) {
                        const newText = before + "_" + after;
                        setMessageText(newText);
                        setPreviewText(newText.replaceAll("{{", "[").replaceAll("}}", "]"));
                        setTimeout(() => {
                          textarea.selectionStart = textarea.selectionEnd = start + 1;
                        }, 0);
                      } else {
                        const selectedText = text.substring(start, end);
                        const newText = before + "_" + selectedText + "_" + after;
                        setMessageText(newText);
                        setPreviewText(newText.replaceAll("{{", "[").replaceAll("}}", "]"));
                        setTimeout(() => {
                          textarea.selectionStart = start;
                          textarea.selectionEnd = end + 2; 
                        }, 0);
                      }
                    }}
                  >
                    I
                  </button>
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => {
                      setMessageText(messageText + ` {{${(messageText?.match(/{{(\d+)}}/g) || [])?.length + 1}}}`)
                      setPreviewText(previewText + ` [${(messageText?.match(/{{(\d+)}}/g) || [])?.length + 1}]`)
                    }}
                  >
                    {`{{${(messageText?.match(/{{(\d+)}}/g) || [])?.length + 1}}}`}
                  </button>
                  </div>
              </Col>
              <Col xs={6} className="px-2">
                <div className="mb-2">
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Label>Buttons</Label>
                  <UncontrolledDropdown
                    className="me-2"
                    direction="down"
                  >
                    <DropdownToggle
                      caret
                      color="primary"
                      disabled={callToActionButtons.length == 2 && quickReplyButtons.length == 3}
                    >
                      Add Button
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>
                        Quick Reply Buttons
                      </DropdownItem>
                      <DropdownItem
                        disabled={quickReplyButtons.length == 10}
                       onClick={() => {
                        setQuickReplyButtons([...quickReplyButtons, {type: "basic", title: ""}])
                      }}>
                        Quick Reply
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem header>
                        Call To Action Buttons
                      </DropdownItem>
                      <DropdownItem 
                        disabled={callToActionButtons.filter((item: any) => item.type == "phone").length == 1}
                      onClick={() => {
                        setCallToActionButtons([...callToActionButtons, {type: "phone", title: "", phone: ""}])
                      }}>
                        Call To Action - Phone Number
                      </DropdownItem>
                      <DropdownItem
                      disabled={callToActionButtons.filter((item: any) => item.type == "staticURL").length == 2}
                      onClick={() => {
                        setCallToActionButtons([...callToActionButtons, {type: "staticURL", url: "", title: ""}])
                      }}>
                        Call To Action - Static URL
                      </DropdownItem>
                      {/* <DropdownItem 
                    disabled={callToActionButtons.length == 2}
                      onClick={() => {
                        setCallToActionButtons([...callToActionButtons, {type: "dynamicURL", url: "", title: ""}])
                      }}>
                        Call To Action - Dynamic URL
                      </DropdownItem> */}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  </div>
                </div>

                {quickReplyButtons.length > 0 && 
                <div className="mb-4">
                    <Label>Quick Reply</Label>
                    {
                      quickReplyButtons.map((item: any, index: any) => {
                        if(item.type=="basic")return (
                          <div key={"quickReply" + index} className="mb-2" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Label style={{marginRight: 20}}>
                              Title:
                            </Label>
                            <div style={{flex: 1}}>
                            <AvField
                              className="avInput"
                              type="text"
                              name={"quickReply" + index}
                              placeholder="Enter Title"
                              errorMessage="Invalid Title"
                              validate={{
                                required: { value: true },
                                maxLength: { value: 20, errorMessage: "Title must be less than 20 characters" },

                              }}
                              onChange={(e: any) => {
                                const updatedButtons = [...quickReplyButtons];
                                updatedButtons[index].title = e.target.value;
                                setQuickReplyButtons(updatedButtons);
                              }}
                              value={item.title}
                            />
                            </div>
                             <button
                              type="button"
                              style={{flexShrink: 1, marginLeft:10}}
                              className="btn btn-light"
                              onClick={() => {
                                const updatedButtons = [...quickReplyButtons];
                                updatedButtons.splice(index, 1);
                                setQuickReplyButtons(updatedButtons);
                              }}
                            >
                              &#10006;
                            </button>
                          </div>
                          )
                      })
                    }
                </div>
                  }
                  {callToActionButtons.length > 0 && 
                <div className="mb-4">
                    <Label>Call To Action</Label>
                    {
                      callToActionButtons.map((item: any, index:any) => {
                        if(item.type=="phone") return (
                          <div key={"callToActionNumber" + index} className="mb-2" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Label style={{marginRight: 10}}>
                              Phone Number:
                            </Label>
                            <div style={{flex: 1}}>
                            <AvField
                              className="avInput"
                              name={"callToActionNumber" + index}
                              type="text"
                              placeholder="Enter Phone Number"
                              errorMessage="Invalid Phone Number"
                              validate={{
                                required: { value: true },
                                pattern: {value: PHONE_REGEX}
                              }}
                              onChange={(e: any) => {
                                const updatedButtons = [...callToActionButtons];
                                updatedButtons[index].phone = e.target.value;
                                setCallToActionButtons(updatedButtons);
                              }}
                              value={item.phone}
                            />
                            </div>
                            <Label style={{marginRight: 10, marginLeft: 10}}>
                              Title:
                            </Label>
                            <div style={{flex: 1}}>
                            <AvField
                              className="avInput"
                              name={"callToActionTitle" + index}
                              type="text"
                              placeholder="Enter Title"
                              errorMessage="Invalid Title"
                              validate={{
                                required: { value: true },
                                maxLength: { value: 20, errorMessage: "Title must be less than 20 characters" },

                              }}
                              onChange={(e: any) => {
                                const updatedButtons = [...callToActionButtons];
                                updatedButtons[index].title = e.target.value;
                                setCallToActionButtons(updatedButtons);
                              }}
                              value={item.title}
                            />
                            </div>
                             <button
                              type="button"
                              style={{flexShrink: 1, marginLeft:10}}
                              className="btn btn-light"
                              onClick={() => {
                                const updatedButtons = [...callToActionButtons];
                                updatedButtons.splice(index, 1);
                                setCallToActionButtons(updatedButtons);
                              }}
                            >
                              &#10006;
                            </button>
                          </div>
                          )
                          if(item.type=="staticURL") return (
                            <div key={"callToActionNumber" + index} className="mb-2" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                              <Label style={{marginRight: 10}}>
                                Static URL:
                              </Label>
                              <div style={{flex: 1}}>
                              <AvField
                                className="avInput"
                                name={"callToActionURL" + index}
                                type="text"
                                placeholder="Enter URL"
                                errorMessage="Invalid URL"
                                validate={{
                                  required: { value: true },
                                  pattern: {value: URL_REGEX}
                                }}
                                onChange={(e: any) => {
                                  const updatedButtons = [...callToActionButtons];
                                  updatedButtons[index].url = e.target.value;
                                  setCallToActionButtons(updatedButtons);
                                }}
                                value={item.url}
                              />
                              </div>
                              <Label style={{marginRight: 10, marginLeft: 10}}>
                                Title:
                              </Label>
                              <div style={{flex: 1}}>
                              <AvField
                                className="avInput"
                                name={"callToActionTitle" + index}
                                type="text"
                                placeholder="Enter Title"
                                errorMessage="Invalid Title"
                                validate={{
                                  required: { value: true },
                                  maxLength: { value: 20, errorMessage: "Title must be less than 20 characters" },
                                }}
                                onChange={(e: any) => {
                                  const updatedButtons = [...callToActionButtons];
                                  updatedButtons[index].title = e.target.value;
                                  setCallToActionButtons(updatedButtons);
                                }}
                                value={item.title}
                              />
                              </div>
                               <button
                                type="button"
                                style={{flexShrink: 1, marginLeft:10}}
                                className="btn btn-light"
                                onClick={() => {
                                  const updatedButtons = [...callToActionButtons];
                                  updatedButtons.splice(index, 1);
                                  setCallToActionButtons(updatedButtons);
                                }}
                              >
                                &#10006;
                              </button>
                            </div>
                            )
                            if(item.type=="dynamicURL") return (
                              <div key={"callToActionNumber" + index} className="mb-2" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <Label style={{marginRight: 10}}>
                                  Dynamic URL:
                                </Label>
                                <div style={{flex: 1}}>
                                <AvField
                                  className="avInput"
                                  name={"callToActionURL" + index}
                                  type="text"
                                  placeholder="Enter URL"
                                  errorMessage="Invalid URL"
                                  validate={{
                                    required: { value: true },
                                    pattern: {value: URL_REGEX}
                                  }}
                                  onChange={(e: any) => {
                                    const updatedButtons = [...callToActionButtons];
                                    updatedButtons[index].url = e.target.value;
                                    setCallToActionButtons(updatedButtons);
                                  }}
                                  value={item.url}
                                />
                                </div>
                                <Label style={{marginRight: 10, marginLeft: 5}}>
                                  {"{{1}}" }
                                </Label>
                                <Label style={{marginRight: 10,}}>
                                  Title:
                                </Label>
                                <div style={{flex: 1}}>
                                <AvField
                                  className="avInput"
                                  name={"callToActionTitle" + index}
                                  type="text"
                                  placeholder="Enter Title"
                                  errorMessage="Invalid Title"
                                  validate={{
                                    required: { value: true },
                                    maxLength: { value: 20, errorMessage: "Title must be less than 20 characters" },
                                  }}
                                  onChange={(e: any) => {
                                    const updatedButtons = [...callToActionButtons];
                                    updatedButtons[index].title = e.target.value;
                                    setCallToActionButtons(updatedButtons);
                                  }}
                                  value={item.title}
                                />
                                </div>
                                 <button
                                  type="button"
                                  style={{flexShrink: 1, marginLeft:10}}
                                  className="btn btn-light"
                                  onClick={() => {
                                    const updatedButtons = [...callToActionButtons];
                                    updatedButtons.splice(index, 1);
                                    setCallToActionButtons(updatedButtons);
                                  }}
                                >
                                  &#10006;
                                </button>
                              </div>
                              )
                      })
                    }
                </div>
                  }
                  {
                    (callToActionButtons.length == 0 && quickReplyButtons.length == 0 ) &&
                    <div className="mb-2">
                      <p>No Buttons Added</p>
                    </div>
                  }
                  {/* <div className="mb-2" style={{display: 'flex', flexDirection: 'row', gap: 5,}}>
                    <AvField
                      className="avInput"
                      name="meta"
                      type="checkbox"
                      value={false}
                    />
                      <p>Allow Meta to change the category of this template while approving</p>
                  </div>
                  <div className="mb-2" style={{display: 'flex', flexDirection: 'row', gap: 5,}}>
                    <AvField
                      className="avInput"
                      name="SMSfallover"
                      type="checkbox"
                      value={false}
                    />
                      <p>Allow SMS fallover on this template</p>
                  </div> */}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end"> 
                 <button
                    type="button"
                    className="btn btn-light w-sm"
                    style={{marginRight: 20}}
                    onClick={() => {
                      setShowingPreview(!showingPreview)
                    }}
                  >
                   {showingPreview? "Show Message" : "Show Preview"}
                  </button>

                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={toggle}
                  >
                    Close
                  </button>
                
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default EditTemplate;
