import { gql } from "@apollo/client";

export const CONTACT_DATA = gql`
query getContactCount($start:date $end:date){
  	reporting_ContactsCreatedByReseller(where: {
        Date:{_gte: $start ,_lte:$end }
      }) {
        Date
      Count
    }
}
`;  