import { gql } from '@apollo/client';

export const UN_DELETE_TEMPLATE = gql`
mutation delete_template ($id: Int!) {
    update_msgbox_Template(
      where: {TemplateId: {_eq: $id}},
      _set: {
        IsDeleted:false,
        DeletedBy: null,
        DeletedOn:null,
        Status: Pending
				RejectionReason: null
      }) {
      affected_rows
    }
  }
  `
  