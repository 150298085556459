import React, { useState , useEffect} from "react";
import { useQuery } from "@apollo/client";
import { Row, Card, CardBody, CardHeader, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import MessageChart from "../../AllCharts/apex/MessageChart";
import {MESSAGES_SENT_DATA} from "./messagesSent.query";
import convertTimestampToDate from "src/helpers/convertTimestampToDate";

const Message = () => {
   
    const[totalMessages, setTotalMessages] = useState(0);
    const[dataForLast30Day, setDataForLast30Day] =  useState<string[]>([]);

    const{data:msgData} = useQuery(MESSAGES_SENT_DATA, {
            fetchPolicy: "no-cache", 
            variables: {
                end: convertTimestampToDate(new Date()),
                start: convertTimestampToDate((new Date(new Date().setDate(new Date().getDate() - 30))))
            }
        })

    useEffect(() => {
        if(msgData){ 
            let arr = [...msgData.reporting_MessageStatusByReseller ]
            setDataForLast30Day(arr);
            setTotalMessages(arr.reduce((acc: any, day: any) => acc + day.SentCount, 0))
        }       
    },[msgData]);



    return (
        <React.Fragment>
            <Card>
                <CardHeader className="bg-transparent">
                    <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                            <h5 className="card-title mb-0">Last 30 days Messages</h5>
                        </div>                        
                    </div>
                </CardHeader>
                <CardBody className="border-bottom py-3">
                    <Row className="gx-lg-5">
                        <div className="col-md-auto">
                            <div>
                                <p className="text-muted mb-2">Total Messages</p>
                                <h4 className="mb-0">{totalMessages} </h4>
                            </div>
                        </div>
                        
                    </Row>
                </CardBody>
                <div>
                    <div id="chart-area" className="apex-charts" dir="ltr">
                        <MessageChart  dataForLast30Day={dataForLast30Day}/>
                    </div>
                </div>
            </Card>
        </React.Fragment>
    );
};

export default Message;