import client from "../../apollo";
import { ADD_BOT_RESPONSE } from "./query";

async function AddEstateAgentAutomation (org, api){
    let orgId = org.OrganisationId
    let apiId = api.APIAccountId
    let orgName = org.Name
    if(!orgName || !apiId || !orgId) return false

    let botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
        variables: {

          organisationId: orgId,
          
          apiAccountId: apiId,
          keyword: "0",
          reply:"In order to assist you with your enquiry, please choose from the following options 👇\n\n👉 1 - for sales related enquires\n👉 2 - for a lettings related enquiry👉 3 - to book a mortgage appointment\n👉 4 - if you rent a property from " + orgName + " and would like to report an issue\n👉 5 - for all other enquiries",
          isDeletable: true,
          isEnabled: true,
          botResponseType: "KeywordOnly",
        },
      });
      
      if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
        return false;
      }
      
      botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
        variables: {

          organisationId: orgId,
          
          apiAccountId: apiId,
          keyword: "1",
          reply:
            "Thank you. Please reply using one of the keywords below:\n\n👉 V - to book a viewing on a property you have seen listed\n👉 S - if you want to sell your property\n👉 A - if you want to ask a general question about a property",
          isDeletable: true,
          isEnabled: true,
          botResponseType: "KeywordOnly",
        },
      });
      
      if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
        return false;
      }
      
      botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
        variables: {

          organisationId: orgId,
          
          apiAccountId: apiId,
          keyword: "2",
          reply:
            "Thank you. Please reply using one of the keywords below:\n\n👉 B - to book a viewing on a property you have seen listed for rent\n👉 Q - if you want to ask a general question about a property\n👉 L - if you want to let your property",
          isDeletable: true,
          isEnabled: true,
          botResponseType: "KeywordOnly",
        },
      });
      
      if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
        return false;
      }
      
      let parentBotResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
        variables: {

          organisationId: orgId,
          
          apiAccountId: apiId,
          keyword: "3",
          reply:
            "We’d love to arrange a mortgage appointment for you. But first we just need to take some details.",
          isDeletable: true,
          isEnabled: true,
          botResponseType: "KeywordOnly",
        },
      });
      
      if (parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
        return false;
      }
    // need full flow for mortgage appointments
    botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
        variables: {

          organisationId: orgId,
          
          apiAccountId: apiId,
          keyword: "2",
          reply:
            "Thank you. Please reply using one of the keywords below:\n\n👉 B - to book a viewing on a property you have seen listed for rent\n👉 Q - if you want to ask a general question about a property\n👉 L - if you want to let your property",
          isDeletable: true,
          isEnabled: true,
          botResponseType: "KeywordOnly",
          parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
          order: 1,
          requiresResponse :false,
          captureEmailAddress :false
        },
      });
      
      if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
        return false;
      }

      botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
        variables: {

          organisationId: orgId,
          
          apiAccountId: apiId,
          keyword: null,
          reply: "Please confirm your full name?",
          isDeletable: true,
          isEnabled: true,
          botResponseType: "KeywordOnly",
          parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
          order: 2,
          requiresResponse: true,
          captureEmailAddress: false
        },
      });

    if (botResponseId == 0){
        return false;
    }

    botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

        organisationId: orgId,
        
        apiAccountId: apiId,
        keyword: null,
        reply: "Please could you confirm your email address?",
        isDeletable: true,
        isEnabled: true,
        botResponseType: "KeywordOnly",
        parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
        order: 3,
        requiresResponse: true,
        captureEmailAddress: true
    },
    });

    if (botResponseId == 0){
        return false;
    }

    botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

        organisationId: orgId,
        
        apiAccountId: apiId,
        keyword: null,
        reply:
        "Please can you confirm your reason for enquiring?\n\n👉 First time buyer\n👉 Homemover\n👉 Buy-to-let\n👉 Save money on current mortgage\n👉 Other",
        isDeletable: true,
        isEnabled: true,
        botResponseType: "KeywordOnly",
        parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
        order: 4,
        requiresResponse: true,
        captureEmailAddress: false
    },
    });

    if (botResponseId == 0){
        return false;
    }

    botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

        organisationId: orgId,
        
        apiAccountId: apiId,
        keyword: null,
        reply:
        "Thank you, that's the end of the questions for now, please can you let us have 2 days/times that work best for you, so we can arrange a suitable appointment? 📆\n\n👉 Monday AM or PM\n👉 Tuesday AM or PM\n👉 Wednesday AM or PM\n👉 Thursday AM or PM\n👉 Friday AM or PM\n👉 Saturday AM or PM",
        isDeletable: true,
        isEnabled: true,
        botResponseType: "KeywordOnly",
        parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
        order: 5,
        requiresResponse: true,
        captureEmailAddress: false
    },
    });

    if (botResponseId == 0){
        return false;
    }


    botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

        organisationId: orgId,
        
        apiAccountId: apiId,
        keyword: null,
        reply:
        "One of our team will call you to confirm the next steps in regards to your viewing. Please let us know when it would be best to contact you? ☎\n\n👉 Mornings\n👉 Lunchtime\n👉 Early afternoon\n👉 Late afternoon\n👉 Evenings",
        isDeletable: true,
        isEnabled: true,
        botResponseType: "KeywordOnly",
        parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
        order: 6,
        requiresResponse: true,
        captureEmailAddress: false
    },
    });

    if (botResponseId == 0){
        return false;
    }

    botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

        organisationId: orgId,
        
        apiAccountId: apiId,
        keyword: null,
        reply: "Perfect, thank you. We’ll be in touch.",
        isDeletable: true,
        isEnabled: true,
        botResponseType: "KeywordOnly",
        parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
        order: 7,
        requiresResponse: false,
        captureEmailAddress: false
    },
    });

    if (botResponseId == 0){
        return false;
    }

    botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

        organisationId: orgId,
        
        apiAccountId: apiId,
        keyword: "4",
        reply: "Thank you. To report a fault, please use our fault reporting system.",
        isDeletable: true,
        isEnabled: true,
        botResponseType: "KeywordOnly"
    },
    });

    if (botResponseId == 0){
        return false;
    }

botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
  variables: {

    organisationId: orgId,
    
    apiAccountId: apiId,
    keyword: "5",
    reply: "Please let us know the nature of your enquiry, and we will get back to you as soon as we can.",
    isDeletable: true,
    isEnabled: true,
    botResponseType: "KeywordOnly"
  },
});
    if (botResponseId == 0){
        return false;
    }
    botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
        variables: {

          organisationId: orgId,
          
          apiAccountId: apiId,
          keyword: "A",
          reply: "If you have any specific questions about a property, please just let us know here (and don't forget to let us know the property in question), and one of our team will be in touch to help you.",
          isDeletable: true,
          isEnabled: true,
          botResponseType: "KeywordOnly"
        },
      });    
      
    if (botResponseId == 0){
        return false;
    }

    parentBotResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

        organisationId: orgId,
        
        apiAccountId: apiId,
        keyword: "B",
        reply: "We'd love to arrange a viewing for you. But first, we just need to take some details from you.",
        isDeletable: true,
        isEnabled: true,
        botResponseType: "KeywordOnly"
    },
    });

    if (parentBotResponseId == 0){
        return false;
    }
    //full flow for buying

botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
  variables: {

    organisationId: orgId,
    
    apiAccountId: apiId,
    keyword: null,
    reply: "We're going to ask you some questions, and it's important that you only reply with a single response message to each question.",
    isDeletable: true,
    isEnabled: true,
    botResponseType: "KeywordOnly",
    parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
    order: 1,
    requiresResponse: false,
    captureEmailAddress: false
  },
});

if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
  return false;
}

botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
  variables: {

    organisationId: orgId,
    
    apiAccountId: apiId,
    keyword: null,
    reply: "Please confirm your full name?",
    isDeletable: true,
    isEnabled: true,
    botResponseType: "KeywordOnly",
    parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
    order: 2,
    requiresResponse: true,
    captureEmailAddress: false
  },
});

if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
  return false;
}

botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
  variables: {

    organisationId: orgId,
    
    apiAccountId: apiId,
    keyword: null,
    reply: "What property would you like to view?",
    isDeletable: true,
    isEnabled: true,
    botResponseType: "KeywordOnly",
    parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
    order: 3,
    requiresResponse: true,
    captureEmailAddress: false
  },
});

if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
  return false;
}

botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
  variables: {

    organisationId: orgId,
    
    apiAccountId: apiId,
    keyword: null,
    reply: "And your current address?",
    isDeletable: true,
    isEnabled: true,
    botResponseType: "KeywordOnly",
    parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
    order: 4,
    requiresResponse: true,
    captureEmailAddress: false
  },
});

if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
  return false;
}

botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
  variables: {

    organisationId: orgId,
    
    apiAccountId: apiId,
    keyword: null,
    reply: "Please could you confirm your email address?",
    isDeletable: true,
    isEnabled: true,
    botResponseType: "KeywordOnly",
    parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
    order: 5,
    requiresResponse: true,
    captureEmailAddress: true
  },
});

if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
  return false;
}

botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
  variables: {

    organisationId: orgId,
    
    apiAccountId: apiId,
    keyword: null,
    reply: "Thank you, that's the end of the questions for now. Please let us have 2 days/times that work best for you, so we can arrange a suitable appointment. 📆\n\n👉 Monday AM or PM\n👉 Tuesday AM or PM\n👉 Wednesday AM or PM\n👉 Thursday AM or PM\n👉 Friday AM or PM\n👉 Saturday AM or PM",
    isDeletable: true,
    isEnabled: true,
    botResponseType: "KeywordOnly",
    parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
    order: 6,
    requiresResponse: true,
    captureEmailAddress: false
  },
});

if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
  return false;
}

botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
  variables: {

    organisationId: orgId,
    
    apiAccountId: apiId,
    keyword: null,
    reply: "One of our team will call you to confirm the next steps in regards to your viewing. Please let us know when it would be best to contact you? ☎\n\n👉 Mornings\n👉 Lunchtime\n👉 Early afternoon\n👉 Late afternoon\n👉 Evenings",
    isDeletable: true,
    isEnabled: true,
    botResponseType: "KeywordOnly",
    parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
    order: 7,
    requiresResponse: true,
    captureEmailAddress: false
  },
});

if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
  return false;
}

botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
  variables: {

    organisationId: orgId,
    
    apiAccountId: apiId,
    keyword: null,
    reply: "Perfect, thank you. We'll be in touch.",
    isDeletable: true,
    isEnabled: true,
    botResponseType: "KeywordOnly",
    parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
    order: 8,
    requiresResponse: false,
    captureEmailAddress: false
  },
});

if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
  return false;
}

parentBotResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
  variables: {

    organisationId: orgId,
    
    apiAccountId: apiId,
    keyword: "L",
    reply: "We'd love to help you let your property!\n\nWe're going to ask you 4 simple questions, and it's important that you only reply with a single response message to each question.",
    isDeletable: true,
    isEnabled: true,
    botResponseType: "KeywordOnly"
  },
});

if (parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
  return false;
}

botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
  variables: {

    organisationId: orgId,
    
    apiAccountId: apiId,
    keyword: null,
    reply: "Please confirm your full name?",
    isDeletable: true,
    isEnabled: true,
    botResponseType: "KeywordOnly",
    parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
    order: 1,
    requiresResponse: true,
    captureEmailAddress: false
  },
});

if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
  return false;
}

botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
  variables: {

    organisationId: orgId,
    
    apiAccountId: apiId,
    keyword: null,
    reply: "And your current address?",
    isDeletable: true,
    isEnabled: true,
    botResponseType: "KeywordOnly",
    parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
    order: 2,
    requiresResponse: true,
    captureEmailAddress: false
  },
});

if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
  return false;
}

botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
  variables: {

    organisationId: orgId,
    
    apiAccountId: apiId,
    keyword: null,
    reply: "Please could you confirm your email address?",
    isDeletable: true,
    isEnabled: true,
    botResponseType: "KeywordOnly",
    parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
    order: 3,
    requiresResponse: true,
    captureEmailAddress: true
  },
});

if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
  return false;
}

botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
  variables: {

    organisationId: orgId,
    
    apiAccountId: apiId,
    keyword: null,
    reply: "Can I please ask if you need Mortgage advice?",
    isDeletable: true,
    isEnabled: true,
    botResponseType: "KeywordOnly",
    parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
    order: 4,
    requiresResponse: true,
    captureEmailAddress: false
  },
});

if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
  return false;
}
botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

      organisationId: orgId,
      
      apiAccountId: apiId,
      keyword: null,
      reply: "Perfect - thank you. One of our team will be in touch shortly to discuss booking in your market appraisal.",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
      order: 5,
      requiresResponse: true,
      captureEmailAddress: false
    }
  });
  if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
    return false;
  }
  
  botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

      organisationId: orgId,
      
      apiAccountId: apiId,
      keyword: "Q",
      reply: "If you have any specific questions about a property, please just let us know here (and don't forget to let us know the property in question), and one of our team will be in touch to help you.",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly"
    }
  });
  if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
    return false;
  }
  
  parentBotResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

      organisationId: orgId,
      
      apiAccountId: apiId,
      keyword: "S",
      reply: "We'd love to help you sell your home!\n\nWe're going to ask you 4 simple questions, and it's important that you only reply with a single response message to each question.",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly"
    }
  });
  if (parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
    return false;
  }
  
  botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

      organisationId: orgId,
      
      apiAccountId: apiId,
      keyword: null,
      reply: "Please confirm your full name?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
      order: 1,
      requiresResponse: true,
      captureEmailAddress: false
    }
  });
  if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
    return false;
  }
  
  botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

      organisationId: orgId,
      
      apiAccountId: apiId,
      keyword: null,
      reply: "and your current address?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
      order: 2,
      requiresResponse: true,
      captureEmailAddress: false
    }
  });
  if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
    return false;
  }
    
  botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

      organisationId: orgId,
      
      apiAccountId: apiId,
      keyword: null,
      reply: "Please could you confirm your email address?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
      order: 3,
      requiresResponse: true,
      captureEmailAddress: true
    }
  });
  if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
    return false;
  }
  
  botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

      organisationId: orgId,
      
      apiAccountId: apiId,
      keyword: null,
      reply: "Can I please ask if you need Mortgage advice?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
      order: 4,
      requiresResponse: true,
      captureEmailAddress: false
    }
  });
  if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
    return false;
  }
  
  botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

      organisationId: orgId,
      
      apiAccountId: apiId,
      keyword: null,
      reply: "Perfect - thank you. One of our team will be in touch shortly to discuss booking in your market appraisal.",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
      order: 5,
      requiresResponse: true,
      captureEmailAddress: false
    }
  });
  if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
    return false;
  }
  
  parentBotResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

      organisationId: orgId,
      
      apiAccountId: apiId,
      keyword: "V",
      reply: "We'd love to arrange a viewing for you. But first, we just need to take some details from you.",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly"
    }
  });
  if (parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
    return false;
  }
  
  botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

      organisationId: orgId,
      
      apiAccountId: apiId,
      keyword: null,
      reply: "We're going to ask you some questions, and it's important that you only reply with a single response message to each question.",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
      order: 1,
      requiresResponse: false,
      captureEmailAddress: false
    }
  });
  if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
    return false;
  }
  
  botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

      organisationId: orgId,
      
      apiAccountId: apiId,
      keyword: null,
      reply: "Please confirm your full name?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
      order: 2,
      requiresResponse: true,
      captureEmailAddress: false
    }
  });
  if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
    return false;
  }
    
  botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

      organisationId: orgId,
      
      apiAccountId: apiId,
      keyword: null,
      reply: "What property would you like to view?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
      order: 3,
      requiresResponse: true,
      captureEmailAddress: false
    }
  });
  if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
    return false;
  }
  
  botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

      organisationId: orgId,
      
      apiAccountId: apiId,
      keyword: null,
      reply: "and your current address?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
      order: 4,
      requiresResponse: true,
      captureEmailAddress: false
    }
  });
  if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
    return false;
  }
  
  botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

      organisationId: orgId,
      
      apiAccountId: apiId,
      keyword: null,
      reply: "Please could you confirm your email address?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
      order: 5,
      requiresResponse: true,
      captureEmailAddress: true
    }
  });
  if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
    return false;
  }
  
  botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

      organisationId: orgId,
      
      apiAccountId: apiId,
      keyword: null,
      reply: "Do you have a property you would also like to sell?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
      order: 6,
      requiresResponse: true,
      captureEmailAddress: false
    }
  });
  if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
    return false;
  }
  
  botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

      organisationId: orgId,
      
      apiAccountId: apiId,
      keyword: null,
      reply: "Can I please ask if you need Mortgage advice?",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
      order: 7,
      requiresResponse: true,
      captureEmailAddress: false
    }
  });
  if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
    return false;
  }
    
  botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

      organisationId: orgId,
      
      apiAccountId: apiId,
      keyword: null,
      reply: "Thank you, that's the end of the questions for now, please can you let us have 2 days/times that work best for you, so we can arrange a suitable appointment? 📆\n\n👉 Monday AM or PM\n👉 Tuesday AM or PM\n👉 Wednesday AM or PM\n👉 Thursday AM or PM\n👉 Friday AM or PM\n👉 Saturday AM or PM",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
      order: 8,
      requiresResponse: true,
      captureEmailAddress: false
    }
  });
  if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
    return false;
  }
  
  botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

      organisationId: orgId,
      
      apiAccountId: apiId,
      keyword: null,
      reply: "One of our team will call you to confirm the next steps in regards to your viewing. Please let us know when it would be best to contact you? ☎\n\n👉 Mornings\n👉 Lunchtime\n👉 Early afternoon\n👉 Late afternoon\n👉 Evenings",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
      order: 9,
      requiresResponse: true,
      captureEmailAddress: false
    }
  });
  if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
    return false;
  }
  
  botResponseId = await client.mutate({
        mutation: ADD_BOT_RESPONSE,
    variables: {

      organisationId: orgId,
      
      apiAccountId: apiId,
      keyword: null,
      reply: "Perfect, thank you. We’ll be in touch.",
      isDeletable: true,
      isEnabled: true,
      botResponseType: "KeywordOnly",
      parentBotReplyId: parentBotResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId,
      order: 10,
      requiresResponse: false,
      captureEmailAddress: false
    }
  });
  if (botResponseId.data.insert_msgbox_BotResponse.returning[0].BotResponseId === 0) {
    return false;
  }
    return true
}

export default AddEstateAgentAutomation