import { gql } from "@apollo/client";

export const GET_TEMPLATES = gql`
query MyQuery($apiAccountId: Int!) {
  msgbox_Template(where: {APIAccount: {_eq: $apiAccountId}, IsDeleted:{_eq:false}, Status:{_eq: Approved}}) {
    Name
    TemplateId
  }
}`;

  export const GET_PARSERTYPES = gql`
  query MyQuery {
    msgbox_EmailParserType {
      Name
    }
  }`;

export const MUTATE_EMAILPARSERS = gql`
mutation MyMutation($_eq: Int!,$DoNotForwardIfRepliedTo: Boolean, $DoNotForwardIfSentOK: Boolean, $DoNotForwardIfReadOK: Boolean, $DoNotForwardIfDeliveredOK: Boolean,$ForwardingEmail: String, $ParserType: String!, $TemplateId: Int!) {
  update_msgbox_EmailParserConfig(where: {EmailParserConfigId: {_eq: $_eq}}, _set: {DoNotForwardIfReadOK: $DoNotForwardIfReadOK, DoNotForwardIfDeliveredOK: $DoNotForwardIfDeliveredOK, DoNotForwardIfSentOK: $DoNotForwardIfSentOK, DoNotForwardIfRepliedTo: $DoNotForwardIfRepliedTo, ForwardingEmail: $ForwardingEmail, ParserType: $ParserType, TemplateId: $TemplateId}) {
    affected_rows
  }
}`
