
import client from "../apollo";
import { gql } from "@apollo/client";
import path from 'path';

export interface ICreateTemplate {
  organisationId: number;
  name: string;
  created: string;
  user: number;
  tempTypeId: number;
  tempCatId: number;
  apiId: number;
  tempText: string;
  tempExample: string;
  footer: string;
  attachmentType: string;
  attachmentFileType: string;
  attachmentExample: string;
}

export const createTemplate = async (props: ICreateTemplate) => {
  console.log("Creating: ", props);

  var attachmentId
  if (props.attachmentType == "Image" || props.attachmentType == "Document" || props.attachmentType == "Video") {
    attachmentId = await uploadFile(props.attachmentExample, props.attachmentFileType);
  }

  const createTemplate = await client.mutate({
    mutation: CREATE_TEMPLATE,
    variables: {
      organisationId: props.organisationId,
      name: props.name,
      tempTypeId: props.tempTypeId,
      tempCatId: props.tempCatId,
      apiId: props.apiId,
      tempText: props.tempText,
      tempExample: props.tempExample,
      tempFooter: props.footer,
      attachmentId: attachmentId,
      attachmentType: props.attachmentType

    },
  });

  const newTempId =
    createTemplate.data.insert_msgbox_Template.returning[0].APIAccountId;
  return newTempId;

};


const uploadFile = async (attachment: string, fileType: string) => {

  const attachmentResponse = await fetch(attachment);
  if (attachmentResponse) {
    const blob: Blob = await attachmentResponse.blob()

    const fileExtension = path.extname(attachment)
    const accesstoken = localStorage.getItem("access_token");
    var access_token = "";
    if (accesstoken) {
      access_token = JSON.parse(accesstoken.toString());
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}files`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${access_token}`,
          "content-disposition": `filename=attachment.${fileExtension}`,
          "content-type": fileType,
        },
        body: blob,
      }
    );
    const { attachmentId } = await response.json();
    return attachmentId;
  }
  return 0;
}

const CREATE_TEMPLATE = gql`
mutation MyMutation(
  $organisationId: Int!
  $name: String!
  $tempTypeId: Int!
  $tempCatId: Int!
  $apiId: Int!
  $tempText: String!
  $tempExample: String!
  $tempFooter: String
  $attachmentId: Int
  $attachmentType: String
) {
  insert_msgbox_Template(
    objects: {
      OrganisationId: $organisationId
      Name: $name
      TemplateText: $tempText
      TemplateTypeId: $tempTypeId
      TemplateExample: $tempExample
      TemplateCategoryId: $tempCatId
      FooterText: $tempFooter
      APIAccount: $apiId
      AttachmentId: $attachmentId
      Attachment: $attachmentType
    }
  ) {
    returning {
      TemplateId
    }
  }
}
`;