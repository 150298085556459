import { gql } from "@apollo/client";

export const GET_ORGANISATIONS = gql`
  query MyQuery {
    msgbox_Organisation(order_by: { CreatedOn: desc }, limit: 10) {
      Name
      OrganisationId
      IsEnabled
      CreatedOn
      users_aggregate(
        where: { IsDeleted: { _eq: false }, IsBot: { _eq: false } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;
