interface MenuItemsProps {
  id: number;
  novidade?: any;
  label: string;
  icon?: string;
  link?: string;
  badge?: string;
  badgecolor?: string;
  subItems?: any;
  isHeader?: boolean;
}
// uses the feathericons.tsx set of icons
const menuItems: Array<MenuItemsProps> = [
  {
    id: 1,
    label: "Dashboards",
    isHeader: true,
  },
  {
    id: 2,
    label: "Sales",
    icon: "monitor",
    link: "/sales",
    badgecolor: "badge-soft-secondary",
  },
  {
    id: 139,
    label: "Analytics",
    icon: "pie-chart",
    link: "/dashboards-analytics",
  },
  {
    id: 1000,
    label: "Configuration",
    isHeader: true,
  },
  {
    id: 1001,
    label: "Organisations",
    icon: "share-2",
    link: "/organisations",
  },
  {
    id: 1002,
    label: "Users",
    icon: "user",
    link: "/users",
  },
  {
    id: 1003,
    label: "Inboxes",
    icon: "inbox",
    link: "/inboxes",
  },
  {
    id: 1001,
    label: "Teams",
    icon: "users",
    link: "/teams",
  },
  {
    id: 1004,
    label: "Templates",
    icon: "grid",
    link: "/templates",
  },
  {
    id: 1005,
    label: "Messages",
    icon: "message-square",
    link: "/messages",
  },
  {
    id: 1005,
    label: "Widgets",
    icon: "message-circle",
    link: "/widgets",
  },
  {
    id: 2000,
    label: "Automation",
    isHeader: true,
  },
  {
    id: 2001,
    label: "Email Parsers",
    icon: "arrow-left",
    link: "/emailparsers",
  },
  {
    id: 2002,
    label: "Email Forwarders",
    icon: "arrow-right",
    link: "/emailforwarders",
  },
  {
    id: 2003,
    label: "Bot Responses",
    icon: "play",
    link: "/botresponses",
  },
  {
    id: 3000,
    label: "Logs",
    isHeader: true,
  },
  {
    id: 3001,
    label: "Email Parser",
    icon: "share-2",
    link: "/emailparserlogs",
  },
];

export { menuItems };
