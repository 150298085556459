import React, { useState } from "react";

import { Switch, BrowserRouter as Router } from "react-router-dom";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

//redux
import { useSelector } from "react-redux";

//themes
import { ThemeContext } from "./helpers/themeContext";
import { WhatEx } from "./assets/themes/WhatEx/data";
import { msgboxx } from "./assets/themes/msgboxx/data";
import { voiceflex } from "./assets/themes/voiceflex/data";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/index";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

import fakeBackend from "./helpers/AuthType/fakeBackend";

//api config
// import config from "./config";

// Activating fake backend
fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

//init firebase backend
// initFirebaseBackend(config["firebase"]);

const App = () => {
  const { layoutType } = useSelector((state: any) => ({
    layoutType: state.Layout.layoutType,
  }));

  const [theme, setTheme] = useState<any>(false);

  function getLayout() {
    let layoutCls: Object = VerticalLayout;
    switch (layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  async function getTheme() {
    const url = window.location.hostname;
    switch (url) {
      case "reseller.omnichat.co.uk":
        await import("./assets/themes/voiceflex/scss/theme.scss");
        setTheme(voiceflex);
        return;
      case "reseller.whatex.app":
        await import("./assets/themes/WhatEx/scss/theme.scss");
        setTheme(WhatEx);
        return;
      default:
        await import("./assets/themes/msgboxx/scss/theme.scss");
        setTheme(msgboxx);
        return;
    }
  }

  getTheme();

  const Layout = getLayout();
  
  return (
    <React.Fragment>
      {theme && (
        <ThemeContext.Provider value={theme}>
          <Router>
            <Switch>
              {authRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                  exact
                />
              ))}

              {userRoutes.map((route: any, idx: number) => (
                <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              ))}
            </Switch>
          </Router>
        </ThemeContext.Provider>
      )}
    </React.Fragment>
  );
};

export default App;
