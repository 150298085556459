import { gql } from '@apollo/client';

export const DELETE_PARSER = gql`
mutation delete_parser ($id: Int!, $userId: Int, $time: timestamptz) {
    update_msgbox_EmailParserConfig(
      where: {EmailParserConfigId: {_eq: $id}},
      _set: {IsDeleted:true, DeletedBy: $userId, DeletedOn:$time}) {
      affected_rows
    }
  }
  
  `
  