import { gql } from '@apollo/client';

export const APPROVE_TEMPLATE = gql`
mutation approveTemplate(
  		$Id: Int
	) {
		update_msgbox_Template(
      where:{
        TemplateId: {_eq: $Id}
      }
			_set: {
        Status: Approved
				RejectionReason: null
			}
		) {
			affected_rows
		}
	}
  `
  