import React from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

interface IProps {
  isOpen: boolean;
  cancel: any;
  submit: any;
}

const DeleteModal = (props: IProps) => {

  return (
    <React.Fragment>
      <Modal
        isOpen={props.isOpen}
        toggle={() =>props.cancel()}
        size="xs"
      >
        <ModalHeader  tag="h4">
        Are you sure you want to delete this?
        </ModalHeader>
        <ModalBody className="modelStyle">
            <Row>
              <Col>
                <div className="text-end"> 

                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={() => props.cancel()}
                  >
                    Cancel
                  </button>
                
                  <button onClick={() => props.submit()} className="btn btn-success save-user">
                    Delete
                  </button>
                </div>
              </Col>
            </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteModal;
