import logo from "./images/OmniChat-black-bg-logo.png";
import loginImage from "./images/loginimage.png";
import favIcon from "./images/favIcon.png";
import icon from "./images/icon.png";

import authBackground from "../voiceflex/images/authBackground.jpg";

export const voiceflex = {
  name: "omnichat",
  styles: {
    primary:'#ffffff',
    secondary: '#eafbec',
    primaryBold: '#043a5a',
    textColour1: '#000000'   
 },
  images: {
    logo,
    loginImage,
    icon,
    favIcon,
    authBackground,
  },
};
