import { gql } from "@apollo/client";

export const ORGANIZATION_DATA_COUNT = gql`
    query getOrgCount($start:date $end:date){
        reporting_OrganisationsByReseller(where: {
            Date:{_gte: $start ,_lte:$end }
          }) {
            AddedCount
            Date
        }
    }
    `