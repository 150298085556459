import { useQuery } from "@apollo/client";
import{useState, useEffect} from "react";
import {MESSAGES_DATA_COUNT} from "./messagesAnalytics.query";
import convertTimestampToDate from "src/helpers/convertTimestampToDate";

const MessageAnalysisWidget = () => {

    var percentange: any;
    var badge: any;
    var arrow: any;

    const [countYesterDay, setCountYesterday] = useState(null);
    const [countDayBefore, setCountDayBefore] = useState(null);


    const today = new Date();

    const firstDayThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    // Messages count  Yesterday
    const {data:MessagesData} = useQuery(MESSAGES_DATA_COUNT,{
        fetchPolicy: "no-cache",
        variables: {
            start: convertTimestampToDate(firstDayThisMonth) ,
            end: convertTimestampToDate(today) 
        }
    });
    
    const firstDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1); 
    let lastDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    if (lastDayLastMonth > lastDayOfLastMonth) {
        lastDayLastMonth = lastDayOfLastMonth;
    }

    const {data:DayBeforeMsgsData} = useQuery(MESSAGES_DATA_COUNT,{
        fetchPolicy: "no-cache",
        variables: {
            start: convertTimestampToDate(firstDayLastMonth),
            end: convertTimestampToDate(lastDayLastMonth)
        }    
    });
   

    useEffect(() => {
        if(MessagesData) {
            let count: any = 0
            MessagesData.reporting_MessageStatusByReseller.forEach((item: any) => {
                count += item.SentCount
            })
            setCountYesterday(count)
        }
    }, [MessagesData])

    useEffect(() => {
        if(DayBeforeMsgsData) {
            let count: any = 0
            DayBeforeMsgsData.reporting_MessageStatusByReseller.forEach((item: any) => {
                count += item.SentCount
            })
            setCountDayBefore(count)
        }
    }, [DayBeforeMsgsData])

    if(countYesterDay !== null && countDayBefore !== null){
        const diff = countYesterDay - countDayBefore;
        if (diff === 0) {
            percentange = "0";
            badge = "badge badge-soft-light ms-2";
            arrow = "";
        }
        else {
            percentange = ((diff / countDayBefore) * 100).toFixed(1) ;
            if (percentange > 0) {
                badge = "badge badge-soft-success ms-2";
                arrow = "uil uil-arrow-up-right text-success ms-1"
            } else {
                badge = "badge badge-soft-danger ms-2";
                arrow = "uil uil-arrow-down-left text-danger ms-1";
            }
        }
    }

    return (
        <div>

            <ul className="list-group list-group-flush" style={{marginBottom: 10}}>
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-3">
                            <div className="avatar-sm">
                                <div className="avatar-title rounded-circle font-size-12">
                                    <i className="far fa-comment-dots" ></i>
                                </div>
                            </div>
                        </div>
                        <div className="flex-grow-1">
                            <p className="text-muted mb-1">Messages</p>
                            <h5 className="font-size-16 mb-0">{countYesterDay}</h5>
                        </div>
                        <div className="flex-shrink-0 align-self-end">
                            <div className={badge}>
                                {percentange} %
                                <i className={arrow}></i>
                            </div>
                        </div>
                    </div>
            </ul>

        </div>
    );
};
export default MessageAnalysisWidget;