import { gql } from "@apollo/client";

export const GET_LOCATION = gql`
  query MyQuery {
    msgbox_WidgetLocations {
      LocationId
      WidgetId
      Text
      Url
    }
  }
`;


