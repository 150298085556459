import { gql } from "@apollo/client";

export const GET_ORGANISATIONS = gql`
  query MyQuery {
    msgbox_Organisation(order_by: { Name: asc }) {
      OrganisationId
      Name
    }
  }
`

export const GET_DEFAULTS = gql`
query MyQuery ($apiAccountId: Int!)  {
  msgbox_EmailParserOutgoingEmail(where: {APIAccountId: {_eq: $apiAccountId}, IsDefault:{_eq: true}}) {
    IsDefault
  }
}`;

export const GET_API_ACCOUNTS = gql`
  query MyQuery($orgId: Int) {
    msgbox_APIAccount(
      where: { OrganisationId: { _eq: $orgId } }
      order_by: { Name: asc }
    ) {
      APIAccountId
      Name
    }
  }
`;

export const GET_TEMPLATE_TYPES = gql`
  query MyQuery {
    msgbox_TemplateType {
      TemplateTypeId
      Description
    }
  }
`;

export const GET_TEMPLATE_CATEGORIES = gql`
  query MyQuery {
    msgbox_TemplateCategory {
      Name
      TemplateCategoryId
    }
  }
`;
