import { gql } from "@apollo/client";

export const GET_ORGANISATIONS = gql`
  query MyQuery {
    msgbox_Organisation(order_by: { Name: asc }) {
      OrganisationId
      Name
    }
  }
`;

export const GET_API_ACCOUNTS = gql`
  query MyQuery($orgId: Int) {
    msgbox_APIAccount(
      where: { OrganisationId: { _eq: $orgId } }
      order_by: { Name: asc }
    ) {
      APIAccountId
      Name
    }
  }
`;

export const GET_BASE_DATA = gql`
  query MyQuery {
    msgbox_Organisation(order_by: { Name: asc }) {
      OrganisationId
      Name
    }
    msgbox_TemplateType {
        TemplateTypeId
        Description
      }
    msgbox_TemplateCategory {
      Name
      TemplateCategoryId
    }
  }

`