import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useContext,
} from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { ThemeContext } from "src/helpers/themeContext";
import SweetAlert from "react-bootstrap-sweetalert";
import { toNumber } from "lodash";
import {
  ICreateWidgetProps,
  CreateWidget,
  getWidgetBrandId,
} from "src/common/widgetUtility";

interface IProps {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  onRefetch: any;
}

const AddWidget = (props: IProps) => {
  const handleShow = () => {
    props.setModal(false);
  };
  const toggle = () => {
    props.setModal(!props.modal);
  };
  const Theme = useContext(ThemeContext);
  const [selectedSize, setSelectedSize] = useState<string>("normal");
  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);

  const handleWidgetSubmit = async (values: any) => {
    console.log("Email Parser Submitted");
    console.log("values:", values);
    const brandId = await getWidgetBrandId();
    var widget: ICreateWidgetProps | null = {
      widgetId: 0,
      publicId: values["publicId"],
      name: values["name"],
      headerText: values["headerText"],
      optionText: values["optionText"],
      size: selectedSize,
      url: values["url"],
      brandId: brandId,
    };
    console.log("widget:", widget);
    var newWidget = await CreateWidget(widget).catch(err => {
      console.log("error:", err);
      setdynamic_description("Failed to create Web Widget");
      setfailure_dlg(true);
      toggle();
      return null;
    });
    if (newWidget == null) {
      return false;
    }

    setdynamic_description("Web Widget created successfully");
    setsuccess_dlg(true);
    toggle();
  };

  const handleModalOpen = () => {};

  const handleSizeSelect = (target: string) => {
    //console.log("handleApiSelect");
    setSelectedSize(target);
  };

  return (
    <React.Fragment>
      {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
            props.onRefetch();
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      {failure_dlg ? (
        <SweetAlert
          error
          title={Theme.name}
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      <Modal
        isOpen={props.modal}
        toggle={toggle}
        size="lg"
        onOpened={() => handleModalOpen()}
      >
        <ModalHeader toggle={toggle} tag="h4">
          Add New Web Widget
        </ModalHeader>
        <ModalBody className="modelStyle">
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleWidgetSubmit(values);
            }}
          >
            <Row form className="d-flex flex-row mb-3">
              <Col>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="publicId"
                    label="Public ID - supply this to customer for widget"
                    //type="publicId"
                    placeholder="Public Id"
                    validate={{
                      required: { value: true },
                      pattern: {
                        value: "/^[A-Z0-9]{3,20}$/gm",
                        errorMessage:
                          "String must be block capitals, or numbers and between 3 and 20 characters",
                      },
                    }}
                    value={""}
                  />
                </div>

                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="name"
                    label="Name"
                    placeholder="Enter company name"
                    errorMessage="Invalid company name"
                    validate={{
                      required: { value: true },
                    }}
                    value={""}
                  />
                </div>
                <div className="mb-3">
                  <FormGroup className="mb-3">
                    <Label>Size</Label>
                    <Input
                      className="avInput"
                      id="size"
                      name="size"
                      type="select"
                      value={selectedSize}
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        handleSizeSelect(e.currentTarget.value);
                      }}
                    >
                      <option key="normal" value="normal">
                        Normal
                      </option>
                      <option key="small" value="small">
                        Small
                      </option>
                    </Input>
                  </FormGroup>
                </div>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="headerText"
                    label="Header Text"
                    placeholder="Enter company name"
                    errorMessage="Invalid company name"
                    value="👋 We're on WhatsApp, so press Start chat to message us 👇"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="optionText"
                    label="Option Text"
                    placeholder="first option"
                    errorMessage="Invalid Text"
                    value="👉 Start chat"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="url"
                    label="Destination Url"
                    placeholder="Enter destination url"
                    errorMessage="Invalid url"
                    value="https://wa.me/44numberhere?text=Hi"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={handleShow}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddWidget;
