import React, { useContext } from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Col, Container, Row } from "reactstrap";
import EmailParserLogs from "./emailparserlogs";
import { ThemeContext } from "src/helpers/themeContext";

const EmailParserLogsPage = () => {
    const Theme = useContext(ThemeContext)
    return (
        
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Logs | {Theme.name} email parser logs</title>
                </MetaTags>

                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Home" breadcrumbItem="Email Parser Logs" />

                    <Row>
                        <Col lg={12}>
                            <EmailParserLogs />
                        </Col>
                    </Row>

                    
                </Container>
            </div>
        </React.Fragment>
    );
}

export default EmailParserLogsPage;