import React, { Dispatch, SetStateAction, useState, useContext } from "react";
import { Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useQuery } from "@apollo/client";
import { GET_API_PROVIDER, GET_USER_ORGADMIN_ROLE } from "./query";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  AddOpeningHours,
  CreateOrganisation,
  IAddOpeningHoursProps,
  ICreateOrgProps,
} from "src/common/orgUtility";
import {
  AddUserToRole,
  CreateUser,
  IAddUserToRoleProps,
  ICreateUserProps,
} from "src/common/userUtility";
import {
  AddUserToInbox,
  CreateApiAccount,
  CreateAutomation,
  CreateFolder,
  CreateInbox,
  IAddUserToInboxProps,
  ICreateApiProps,
  ICreateAutomationReponse,
  ICreateFolderProps,
  ICreateInboxProps,
} from "src/common/inboxUtility";
import { ThemeContext } from "src/helpers/themeContext";
interface IProps {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  onRefetch: any;
}

const AddOrganisation = (props: IProps) => {
  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);

  const { data: apidata } = useQuery(GET_API_PROVIDER, {
    fetchPolicy: "no-cache",
  });
  const { data: orgAdminRoleDate } = useQuery(GET_USER_ORGADMIN_ROLE, {
    fetchPolicy: "no-cache",
  });
  const Theme = useContext(ThemeContext);
  const handleShow = () => {
    props.setModal(false);
  };
  const toggle = () => {
    props.setModal(!props.modal);
  };

  const handleValidOrganisationSubmit = async (values: any) => {
    console.log("apidata", apidata);

    var newOrg: ICreateOrgProps | null = {
      orgId: 0,
      companyName: values["companyname"],
    };
    newOrg = await CreateOrganisation(newOrg).catch(err => {
      setdynamic_description("Failed to create New Organisation");
      setfailure_dlg(true);
      toggle();
      return null;
    });
    if (newOrg == null) {
      return false;
    }
    console.log("newOrg", newOrg);

    var newBot: ICreateUserProps | null = {
      firstName: "automation",
      lastName: "bot",
      isBot: true,
      isApi: false,
      userId: 0,
      orgId: newOrg.orgId,
      inboxId: 0,
      imageUrl: "images/bot.png",
      hasImage: true,
    };
    newBot = await CreateUser(newBot).catch(err => {
      setdynamic_description("Failed to create automation bot user");
      setfailure_dlg(true);
      toggle();
      return null;
    });
    if (newBot == null) {
      return false;
    }

    for (let i = 0; i < 5; i++) {
      var openingHours: IAddOpeningHoursProps = {
        orgId: newOrg.orgId,
        dayOfWeek: i,
        open: "09:00:00+00",
        close: "17:00:00+00",
      };
      await AddOpeningHours(openingHours);
    }

    var newApiAccount: ICreateApiProps | null = {
      apiAccountId: 0,
      providerId: apidata.msgbox_APIProvider[0].APIProviderId,
      orgId: newOrg.orgId,
      name: values["inboxname"],
      apiKey: values["apikey"],
      appName: values["appname"],
      partnerId: values["partnerid"],
      partnerToken: values["partnertoken"],
      phoneNumber: values["phonenumber"],
    };
    newApiAccount = await CreateApiAccount(newApiAccount).catch(err => {
      setdynamic_description("Failed to create API Account");
      setfailure_dlg(true);
      toggle();
      return null;
    });
    if (newApiAccount == null) {
      return false;
    }

    var newInbox: ICreateInboxProps | null = {
      inboxId: 0,
      orgId: newOrg.orgId,
      apiAccountId: newApiAccount.apiAccountId,
      name: values["inboxname"],
    };
    newInbox = await CreateInbox(newInbox).catch(err => {
      setdynamic_description("Failed to create Inbox");
      setfailure_dlg(true);
      toggle();
      return null;
    });
    if (newInbox == null) {
      return false;
    }

    var inboxFolder: ICreateFolderProps | null = {
      folderId: 0,
      inboxId: newInbox.inboxId,
      name: "Inbox",
      order: 1,
      isInbox: true,
      isArchive: false,
      isSpam: false,
    };
    inboxFolder = await CreateFolder(inboxFolder).catch(err => {
      setdynamic_description("Failed to create Inbox Folder");
      setfailure_dlg(true);
      toggle();
      return null;
    });
    if (inboxFolder == null) {
      return false;
    }

    var archiveFolder: ICreateFolderProps | null = {
      folderId: 0,
      inboxId: newInbox.inboxId,
      name: "Archive",
      order: 2,
      isInbox: false,
      isArchive: true,
      isSpam: false,
    };
    archiveFolder = await CreateFolder(archiveFolder).catch(err => {
      setdynamic_description("Failed to create Archive Folder");
      setfailure_dlg(true);
      toggle();
      return null;
    });
    if (archiveFolder == null) {
      return false;
    }

    var spamFolder: ICreateFolderProps | null = {
      folderId: 0,
      inboxId: newInbox.inboxId,
      name: "Spam",
      order: 3,
      isInbox: false,
      isArchive: false,
      isSpam: true,
    };
    spamFolder = await CreateFolder(spamFolder).catch(err => {
      setdynamic_description("Failed to create Spam Folder");
      setfailure_dlg(true);
      toggle();
      return null;
    });
    if (spamFolder == null) {
      return false;
    }

    var defaultResponse: ICreateAutomationReponse = {
      orgId: newOrg.orgId,
      apiAccountId: newApiAccount.apiAccountId,
      keyword: "default response",
      reply:
        "Thank you for messaging " +
        values["inboxname"] +
        ". How can we help you today?",
      isDeletable: false,
    };
    const defaultReponse = await CreateAutomation(defaultResponse).catch(
      err => {
        setdynamic_description("Failed to create default automation rule");
        setfailure_dlg(true);
        toggle();
        return null;
      }
    );
    if (defaultReponse == null) {
      return false;
    }

    var defaultAwayResponse: ICreateAutomationReponse = {
      orgId: newOrg.orgId,
      apiAccountId: newApiAccount.apiAccountId,
      keyword: "default away response",
      reply:
        "Thank you for messaging " +
        values["inboxname"] +
        ". We're currently closed, so we'll be in touch as soon as we can.",
      isDeletable: false,
    };
    const defaultAwayReponse = await CreateAutomation(
      defaultAwayResponse
    ).catch(err => {
      setdynamic_description("Failed to create default away automation rule");
      setfailure_dlg(true);
      toggle();
      return null;
    });
    if (defaultAwayReponse == null) {
      return false;
    }

    var newUser: ICreateUserProps | null = {
      firstName: "API",
      lastName: "User",
      isBot: false,
      isApi: true,
      userId: 0,
      orgId: newOrg.orgId,
      inboxId: newInbox.inboxId,
      email: values["email"],
      hasImage: false,
    };
    newUser = await CreateUser(newUser).catch(err => {
      setdynamic_description("Failed to create api user account");
      setfailure_dlg(true);
      toggle();
      return null;
    });
    if (newUser == null) {
      return false;
    }

    var addUserToInbox: IAddUserToInboxProps = {
      inboxId: newInbox.inboxId,
      orgId: newOrg.orgId,
      userId: newUser.userId,
    };
    const inboxSuccess: boolean = await AddUserToInbox(addUserToInbox).catch(
      err => {
        setdynamic_description("Failed to add api user to inbox");
        setfailure_dlg(true);
        toggle();
        return false;
      }
    );
    if (inboxSuccess == false) {
      return false;
    }

    var addUserToRole: IAddUserToRoleProps = {
      userId: newUser.userId,
      orgId: newOrg.orgId,
      roleId: orgAdminRoleDate.msgbox_Role[0].RoleId,
    };
    const roleSuccess: boolean = await AddUserToRole(addUserToRole).catch(
      err => {
        setdynamic_description("Failed to add api user to orgadmin role");
        setfailure_dlg(true);
        toggle();
        return false;
      }
    );
    if (roleSuccess == false) {
      return false;
    }

    setdynamic_description("Organisation created successfully");
    setsuccess_dlg(true);
    toggle();
  };

  const modalBodyStyle = {
    color: "#000",
  };

  return (
    <React.Fragment>
      {failure_dlg ? (
        <SweetAlert
          error
          title={Theme.name}
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
            props.onRefetch();
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      <Modal isOpen={props.modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle} tag="h4">
          Add New Organisation
        </ModalHeader>
        <ModalBody className="modelStyle">
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleValidOrganisationSubmit(values);
            }}
          >
            <Row form className="d-flex flex-row mb-3">
              <Col>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="companyname"
                    label="Company Name"
                    type="text"
                    placeholder="Enter company name"
                    errorMessage="Invalid company name"
                    validate={{
                      required: { value: true },
                    }}
                    value={""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="email"
                    label="Email"
                    type="email"
                    placeholder="Enter email for reseller admin user"
                    errorMessage="Invalid Email"
                    validate={{
                      email: true,
                      required: { value: true },
                    }}
                    value={""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="inboxname"
                    label="Inbox Name"
                    type="text"
                    placeholder="Enter inbox name"
                    errorMessage="Invalid inbox name"
                    validate={{
                      required: { value: true },
                    }}
                    value={""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="apikey"
                    label="API Key"
                    placeholder="Enter App API Key from GupShup"
                    type="text"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter API Key",
                      },
                      minLength: {
                        value: 32,
                        errorMessage: "API Key must be 32 characters long",
                      },
                      maxLength: {
                        value: 32,
                        errorMessage: "API Key must be 32 characters long",
                      },
                    }}
                    value={""}
                  />
                </div>
              </Col>
              <Col className="px-2">
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="appname"
                    label="App Name"
                    type="text"
                    placeholder="Enter App name from GupShup"
                    errorMessage="Invalid App Name"
                    validate={{
                      required: { value: true },
                    }}
                    value={""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="partnerid"
                    label="Partner Id"
                    type="text"
                    placeholder="Enter App ID from GupShup"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter partner id from Gupshup",
                      },
                      pattern: {
                        value:
                          "^[A-Za-z0-9]{8}-([A-Za-z0-9]{4}-){3}[A-Za-z0-9]{12}$",
                        errorMessage:
                          "Should be in the format of 97067c1d-29d2-4303-ad0b-a92b81619ae9",
                      },
                    }}
                    value={""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="partnertoken"
                    label="Partner Token"
                    type="text"
                    placeholder="Enter App ID Token from GupShup"
                    errorMessage="Invalid Partner Token"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter partner token from Gupshup",
                      },
                      minLength: {
                        value: 35,
                        errorMessage:
                          "Partner Token must be 35 characters long",
                      },
                      maxLength: {
                        value: 35,
                        errorMessage:
                          "Partner Token must be 35 characters long",
                      },
                      pattern: {
                        value: "^sk_[a-z0-9]{32}$",
                        errorMessage:
                          "Should be in the format of sk_b3hd8f60eaf456db2a7e925b73a81f53",
                      },
                    }}
                    value={""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="phonenumber"
                    label="Phone Number"
                    type="text"
                    placeholder="Enter Phone Number (ISO Format eg +44777777777)"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter phone number",
                      },
                      pattern: {
                        value: "^\\+[0-9]{11,14}$",
                        errorMessage:
                          "Phone Number must be in the format +44777777777",
                      },
                    }}
                    value={""}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={handleShow}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn  btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddOrganisation;
