import { gql } from "@apollo/client";

export const GET_ORGS = gql`
query getOrgs {
  msgbox_Organisation {
    Name
    OrganisationId
  }
}
`;

export const GET_INBOXES = gql`
  query get_inboxes($org: Int) {
    msgbox_Inbox(where: {
      OrganisationId:{_eq:$org}
    }){
      Name
      InboxId
      OrganisationId
	  APIAccountId
    }
  }
`

export const GET_TEMPLATE_CATEGORIES = gql`
	query getTemplateCategories {
		msgbox_TemplateCategory(where: { IsEnabled: { _eq: true } }) {
			TemplateCategoryId
			Name
		}
	}
`;

export const GET_TEMPLATE_TYPES = gql`
	query getTemplateTypes {
		msgbox_TemplateType {
			Description
			TemplateTypeId
		}
	}
`;

export const GET_TEMPLATE_LANGUAGES = gql`
	query GetTeplateLanguages {
		msgbox_TemplateLanguage(order_by: { Order: asc }) {
			LanguageCode
			Description
		}
	}
`;


export const ADD_MSGBOX_TEMPLATE = gql`
mutation addTemplateMessage(
		$name: String!
  		$org:Int!
		$messageText: String!
		$attachment: String!
		$category: Int!
		$templateType: Int!
		$account: Int!
		$exampleMessage: String!
		$attachmentId: Int
		$language: String!
		$buttonMetaData: String
	) {
		insert_msgbox_Template(
			objects: {
				Name: $name
        		OrganisationId:$org
				TemplateText: $messageText
				TemplateCategoryId: $category
				Attachment: $attachment
				TemplateTypeId: $templateType
				APIAccount: $account
				TemplateExample: $exampleMessage
				AttachmentId: $attachmentId
				LanguageCode: $language
				ButtonMetadata: $buttonMetaData
			}
		) {
			affected_rows
		}
	}
`