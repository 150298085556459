import { gql } from "@apollo/client";

export const MESSAGES_SENT_DATA = gql`
query getMessageCount($start:date $end:date){
  	reporting_MessagesOutboundByReseller(where: {
        Date:{_gte: $start ,_lte:$end }
      }) {
        Count
    		Date
    }
  	reporting_MessagesInboundByReseller(where: {
        Date:{_gte: $start ,_lte:$end }
      }) {
        Count
    		Date
    }
    reporting_TemplatesSentByReseller(where: {
        Date:{_gte: $start ,_lte:$end }
      }) {
        Count
    		Date
    }
}
`;  