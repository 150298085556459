import { gql } from '@apollo/client';

export const DELETE_LOCATION = gql`
mutation MyMutation( $LocationId: Int!) {
    delete_msgbox_WidgetLocations(where: {LocationId: {_eq: $LocationId}}){
      affected_rows
    }
  }
`;  

