import React, {
  Dispatch,
  SetStateAction,
  useState,
  useContext,
  useEffect,
} from "react";
import { Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useLazyQuery, useQuery } from "@apollo/client";
import client from "src/apollo";
import {
  GET_API_PROVIDER,
  GET_USER_ORGADMIN_ROLE,
  GET_ORGS,
  GET_INBOXES,
  GET_USERS,
  ADD_INBOXES,
  INSERT_USER_DEPARTMENTS,
} from "./query";
import SweetAlert from "react-bootstrap-sweetalert";
import { ADD_TEAM } from "./query";
import Select from "react-select";
import { ThemeContext } from "src/helpers/themeContext";
import { useMutation } from "@apollo/client";
import TeamForm from "../TeamForm/TeamForm";
interface IProps {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  onRefetch: any;
}

const AddTeam = (props: IProps) => {
  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);
  const [orgs, setOrgs] = useState<any>({ msgbox_Organisation: [] });
  const [inboxes, setInboxes] = useState<any>([]);
  const [selectedOrgId, setSelectedOrgId] = useState<any>(0);
  const [selectedInboxes, setSelectedInboxes] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [teamName, setTeamName] = useState("");
  const [filteredUsers, setFilteredUsers] = useState<any>([]);
  const [userSearchInput, setUserSearchInput] = useState("");
  const [selectedUser, setSelectedUser] = useState<any>("");
  const [addTeam, { data, loading, error }] = useMutation(ADD_TEAM);
  const [addInboxes] = useMutation(ADD_INBOXES);
  const [insertUserDepartments] = useMutation(INSERT_USER_DEPARTMENTS);

  const { data: OrgData } = useQuery(GET_ORGS, {
    fetchPolicy: "no-cache",
  });

  const [triggerInboxQuery, { data: InboxData }] = useLazyQuery(GET_INBOXES, {
    variables: {
      orgId: selectedOrgId,
    },
    fetchPolicy: "no-cache",
  });

  const handleTeamDelete = () => {};

  const submit = async () => {
    if (teamName.length !== 0 && selectedOrgId !== 0) {
      try {
        const temp = await addTeam({
          variables: {
            name: teamName,
            selectedOrganisationId: selectedOrgId,
          },
        });

        for (let i = 0; i < selectedInboxes.length; i++) {
          let obj = {
            inbox: selectedInboxes[i].value,
            orgId: selectedOrgId,
            department: temp.data.insert_msgbox_Department_one.DepartmentId,
          };
          await addInboxes({
            variables: obj,
          });
        }

        for (let i = 0; i < selectedUser.length; i++) {
          let obj = {
            departmentId: temp.data.insert_msgbox_Department_one.DepartmentId,
            userId: selectedUser[i].value,
            orgId: selectedOrgId,
          };
          await insertUserDepartments({
            variables: obj,
          });
        }
        setfailure_dlg(false)
        setsuccess_dlg(true)
      } catch (e) {
        console.log(e);
        setsuccess_dlg(false)
        setfailure_dlg(true);
        setdynamic_description("Oops, something went wrong")
      }
      props.setModal(false);
    }
  };

  const loadUsers = async (orgId: number) => {
    const users = await client.query({
      query: GET_USERS,
      variables: {
        orgId: orgId,
      },
    });
    const userData = users?.data?.msgbox_User || [];

    let options: { value: any; label: string }[] = [];
    userData.forEach((element: any) => {
      const name = element.FirstName + " " + element.LastName;
      const data = { value: element.UserId, label: name };
      options.push(data);
    });
    setUsers(options);
    setFilteredUsers(options);
  };

  function filterUsers() {
    if (users[0].label) {
      let filtered = users.filter((user: any) => {
        return user.label.toLowerCase().includes(userSearchInput.toLowerCase());
      });
      setFilteredUsers(filtered);
    }
  }

  const handleSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const value = event.target.value;

    setSelectedOrgId(Number(value));
    loadUsers(Number(value));
  };

  useEffect(() => {
    triggerInboxQuery().then(data => {
      let newArray = [];
      for (let index = 0; index < data.data.msgbox_Inbox.length; index++) {
        const element = data.data.msgbox_Inbox[index];
        newArray.push({
          value: element.InboxId,
          label: element.Name,
        });
      }

      setInboxes(newArray);
    });
  }, [selectedOrgId]);

  useEffect(() => {
    if (users[0]) {
      filterUsers();
    }
  }, [userSearchInput]);

  useEffect(() => {
    OrgData ? setOrgs(OrgData) : setOrgs({ msgbox_Organisation: [] });
  }, [OrgData]);

  const Theme = useContext(ThemeContext);

  const handleShow = () => {
    props.setModal(false);
  };
  const toggle = () => {
    props.setModal(!props.modal);
  };

  const modalBodyStyle = {
    color: "#000",
  };

  return (
    <React.Fragment>

      {failure_dlg ? (
        <SweetAlert
          error
          title="Error"
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      {success_dlg ? (
        <SweetAlert
          success
          hideOverlay={true}
          title={Theme.name}
          onConfirm={() => {
            props.onRefetch();
            setsuccess_dlg(false)
          }}
        >
          Team added successfully
        </SweetAlert>
      ) : null}


      <Modal isOpen={props.modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle} tag="h4">
          Add New Team
        </ModalHeader>
        <ModalBody className="modelStyle">
          <TeamForm
            teamName={teamName}
            setTeamName={setTeamName}
            handleSelectChange={handleSelectChange}
            orgs={orgs}
            inboxes={inboxes}
            selectedInboxes={selectedInboxes}
            setSelectedInboxes={setSelectedInboxes}
            filteredUsers={filteredUsers}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            handleShow={handleShow}
            submit={submit}
            isEdit={false}
            existingTeam={{}}
            handleTeamDelete={handleTeamDelete}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddTeam;
