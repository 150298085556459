import React, {
  Dispatch,
  SetStateAction,
  useState,
  useContext,
  useEffect,
} from "react";
import { Row, Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import { ThemeContext } from "src/helpers/themeContext";
import { GET_PERMISSIONS, UPDATE_PERMISSIONS } from "./query";
import { useMutation, useQuery } from "@apollo/client";

interface IProps {
  orgId: number;
  isOpen: boolean;
  cancel: any;
  setsuccess_dlg: any;
  setdynamic_description: any;
}

const PermissionsForm = (props: IProps) => {
  const Theme = useContext(ThemeContext);

  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const inputOptions = [
    { value: 1, label: "Everyone" },
    { value: 2, label: "Administrators" },
    { value: 3, label: "Administators & Team Leaders" },
  ];

  function handleSelectChange(e: any, permissionToUpdate: any) {
    const temp = { ...selectedOptions };
    temp[permissionToUpdate] = e.value;
    setSelectedOptions(temp);
  }

  async function submit() {
    try {
      await updatePermissions({
        variables: {
          orgId: selectedOptions.OrganisationId,
          assignedConversationVisibility:
            selectedOptions.InboxSeeOthersAssignedConversations,
          contactsDeleteContacts: selectedOptions.ContactsDeleteContacts,
          automationsSeeAutomations: selectedOptions.AutomationsSeeAutomations,
          analyticsSeeAnalytics: selectedOptions.AnalyticsSeeAnalytics,
          ContactsDownloadContacts: selectedOptions.ContactsDownloadContacts,
          TemplatesAddEditTemplates: selectedOptions.TemplatesAddEditTemplates,
          TemplatesDeleteTemplates: selectedOptions.TemplatesDeleteTemplates,
          InboxSeeUnassignedConversations:
            selectedOptions.InboxSeeUnassignedConversations,
          ContactsSeePhoneNumbers: selectedOptions.ContactsSeePhoneNumbers,
          BroadcastBulkBroadcasts: selectedOptions.BroadcastBulkBroadcasts,
        },
      });
      refetchPermissionsData()
      props.cancel();
      props.setdynamic_description("Organisation edited successfully")
      props.setsuccess_dlg(true);
      
    } catch (e) {
      console.log(e);
    }
  }

  const selectStyle = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: Theme.styles.primary, // primary
      border: `1px solid ${Theme.styles.primaryBold}`, //primary bold
      color: Theme.styles.textColour1, //textColor1
      borderColor: Theme.styles.primary,
    }),
    input: (provided: any) => ({
      ...provided,
      color: Theme.styles.textColour1, // Change the text color of the search input
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: Theme.styles.textColour1, //textColor1
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: Theme.styles.primary, // primary
      border: `1px solid ${Theme.styles.primaryBold}`, //primary bold
      color: Theme.styles.textColour1, //textColor1
    }),
    menuList: (provided: any) => ({
      ...provided,
      backgroundColor: Theme.styles.primary, // primary
      border: `1px solid ${Theme.styles.primaryBold}`, //primary bold
      color: Theme.styles.textColour1, //textColor1
    }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: isFocused ? Theme.styles.secondary : null,
        color: Theme.styles.textColour1, //textColor1
      };
    },
    multiValue: (styles: any) => {
      return {
        ...styles,
        backgroundColor: Theme.styles.secondary,
        color: "white",
      };
    },
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: Theme.styles.textColour1, //textColor1
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      color: Theme.styles.textColour1, //textColor1
      ":hover": {
        backgroundColor: Theme.styles.primary,
        color: "white",
      },
    }),
  };

  const {
    data: permissions,
    refetch: refetchPermissionsData, 
    loading: loadingPermissions,
    error: errorFetchingPermissions,
  } = useQuery(GET_PERMISSIONS, {
    variables: {
      orgId: props.orgId,
    },
  });

  const [
    updatePermissions,
    { error: errorUpdatingPermissions, loading: updatingPermissions },
  ] = useMutation(UPDATE_PERMISSIONS);

  useEffect(() => {
    if (permissions?.msgbox_Permission[0]) {
      setSelectedOptions(permissions?.msgbox_Permission[0]);
    }
  }, [permissions]);

  return (
    <AvForm>
      <Row form className="d-flex flex-row mb-3" style={{ padding: "5px" }}>
        {permissions && selectedOptions ? (
          <Col>
            <h5>Conversation Management</h5>
            <Row>
              {/* InboxSeeOthersAssignedConversations */}
              <div className=" w-50">
                <label>Select who can view assigned conversations</label>
                <Select
                  name="Select who can view assigned conversations"
                  placeholder="Select"
                  options={inputOptions}
                  defaultValue={inputOptions.find(option => {
                    if (
                      option.value ===
                      permissions?.msgbox_Permission[0]
                        ?.InboxSeeOthersAssignedConversations
                    ) {
                      return option;
                    }
                  })}
                  onChange={(e: any) =>
                    handleSelectChange(e, "InboxSeeOthersAssignedConversations")
                  }
                  styles={selectStyle}
                />
              </div>

              {/* InboxSeeUnassignedConversations */}
              <div className="mb-3 w-50">
                <label>Select who can view unassigned conversations</label>
                <Select
                  name="Select who can view unassigned conversations"
                  placeholder="Select"
                  options={inputOptions}
                  defaultValue={inputOptions.find(option => {
                    if (
                      option.value ===
                      permissions?.msgbox_Permission[0]
                        ?.InboxSeeUnassignedConversations
                    ) {
                      return option;
                    }
                  })}
                  onChange={(e: any) =>
                    handleSelectChange(e, "InboxSeeUnassignedConversations")
                  }
                  styles={selectStyle}
                />
              </div>
            </Row>

            <div
              style={{
                borderBottom: "1px solid ",
                margin: "10px",
                marginBottom: "30px",
              }}
            />

            <h5>Contact Management</h5>
            <Row>
              {/* ContactsSeePhoneNumbers */}
              <div className="mb-3 w-50">
                <label>Select who can see phone numbers</label>
                <Select
                  name="Select who can see phone numbers"
                  placeholder="Select"
                  options={inputOptions}
                  defaultValue={inputOptions.find(option => {
                    if (
                      option.value ===
                      permissions?.msgbox_Permission[0]?.ContactsSeePhoneNumbers
                    ) {
                      return option;
                    }
                  })}
                  onChange={(e: any) =>
                    handleSelectChange(e, "ContactsSeePhoneNumbers")
                  }
                  styles={selectStyle}
                />
              </div>

              {/* ContactsDeleteContacts */}
              <div className="mb-3 w-50">
                <label>Select who can delete contacts</label>
                <Select
                  name="Select who can delete contacts"
                  placeholder="Select"
                  options={inputOptions}
                  defaultValue={inputOptions.find(option => {
                    if (
                      option.value ===
                      permissions?.msgbox_Permission[0]?.ContactsDeleteContacts
                    ) {
                      return option;
                    }
                  })}
                  onChange={(e: any) =>
                    handleSelectChange(e, "ContactsDeleteContacts")
                  }
                  styles={selectStyle}
                />
              </div>

              {/* ContactsDownloadContacts */}
              <div className="mb-3 w-50">
                <label>Select who can download contacts</label>
                <Select
                  name="Select who can download contacts"
                  placeholder="Select"
                  options={inputOptions}
                  defaultValue={inputOptions.find(option => {
                    if (
                      option.value ===
                      permissions?.msgbox_Permission[0]
                        ?.ContactsDownloadContacts
                    ) {
                      return option;
                    }
                  })}
                  onChange={(e: any) =>
                    handleSelectChange(e, "ContactsDownloadContacts")
                  }
                  styles={selectStyle}
                />
              </div>
            </Row>

            <div
              style={{
                borderBottom: "1px solid ",
                margin: "10px",
                marginBottom: "30px",
              }}
            />

            <h5>Template & Automation Management</h5>

            <Row>
              {/* BroadcastBulkBroadcasts */}
              <div className="mb-3 w-50">
                <label>Select who can send bulk broadcasts (10+ people)</label>
                <Select
                  name="Select who can send bulk broadcasts to more than 10 people"
                  placeholder="Select"
                  options={inputOptions}
                  defaultValue={inputOptions.find(option => {
                    if (
                      option.value ===
                      permissions?.msgbox_Permission[0]?.BroadcastBulkBroadcasts
                    ) {
                      return option;
                    }
                  })}
                  onChange={(e: any) =>
                    handleSelectChange(e, "BroadcastBulkBroadcasts")
                  }
                  styles={selectStyle}
                />
              </div>

              {/* TemplatesAddEditTemplates */}
              <div className="mb-3 w-50">
                <label>Select who can add & edit templates</label>
                <Select
                  name="Select who can add & edit templates"
                  placeholder="Select"
                  options={inputOptions}
                  defaultValue={inputOptions.find(option => {
                    if (
                      option.value ===
                      permissions?.msgbox_Permission[0]
                        ?.TemplatesAddEditTemplates
                    ) {
                      return option;
                    }
                  })}
                  onChange={(e: any) =>
                    handleSelectChange(e, "TemplatesAddEditTemplates")
                  }
                  styles={selectStyle}
                />
              </div>

              {/* TemplatesDeleteTemplates */}
              <div className="mb-3 w-50">
                <label>Select who can delete templates</label>
                <Select
                  name="Select who can delete templates"
                  placeholder="Select"
                  options={inputOptions}
                  defaultValue={inputOptions.find(option => {
                    if (
                      option.value ===
                      permissions?.msgbox_Permission[0]
                        ?.TemplatesDeleteTemplates
                    ) {
                      return option;
                    }
                  })}
                  onChange={(e: any) =>
                    handleSelectChange(e, "TemplatesDeleteTemplates")
                  }
                  styles={selectStyle}
                />
              </div>

              {/* AutomationsSeeAutomations */}
              <div className="mb-3 w-50">
                <label>Select who can administer automations</label>
                <Select
                  name="Select who can administer automations"
                  placeholder="Select"
                  options={inputOptions}
                  defaultValue={inputOptions.find(option => {
                    if (
                      option.value ===
                      permissions?.msgbox_Permission[0]
                        ?.AutomationsSeeAutomations
                    ) {
                      return option;
                    }
                  })}
                  onChange={(e: any) =>
                    handleSelectChange(e, "AutomationsSeeAutomations")
                  }
                  styles={selectStyle}
                />
              </div>
            </Row>

            <div
              style={{
                borderBottom: "1px solid ",
                margin: "10px",
                marginBottom: "30px",
              }}
            />

            <h5>Analytics & Reporting </h5>
            <Row>
              {/* AnalyticsSeeAnalytics */}
              <div className="mb-3 w-50">
                <label>Select who can view analytics</label>
                <Select
                  name="Select who can view analytics"
                  placeholder="Select"
                  options={inputOptions}
                  defaultValue={inputOptions.find(option => {
                    if (
                      option.value ===
                      permissions?.msgbox_Permission[0]?.AnalyticsSeeAnalytics
                    ) {
                      return option;
                    }
                  })}
                  onChange={(e: any) =>
                    handleSelectChange(e, "AnalyticsSeeAnalytics")
                  }
                  styles={selectStyle}
                />
              </div>
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-light w-sm"
                  onClick={() => {
                    props.cancel();
                  }}
                >
                  Close
                </button>

                <button
                  type="submit"
                  className="btn  btn-success save-user"
                  onClick={() => {
                    submit();
                  }}
                >
                  Save
                </button>
              </div>
            </Row>
          </Col>
        ) : (
          <p>Loading permissions...</p>
        )}
      </Row>
    </AvForm>
  );
};

export default PermissionsForm;
