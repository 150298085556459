import React from "react";

import { Card, CardBody} from "reactstrap";

import ContactApexBar from "../AllCharts/apex/ContactApexbar/ContactApexBar";

const ContactActivity = () => {
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="d-flex align-items-start">
                        <div className="flex-grow-1 overflow-hidden">
                            <h5 className="card-title text-truncate mb-4">New Contacts Per Month</h5>
                        </div>
                    </div>
                    <div>
                        <div id="chart-column" className="apex-charts" dir="ltr">
                            <ContactApexBar />
                        </div>
                    </div>

                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default ContactActivity;