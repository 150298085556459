import React from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Col, Container, Row } from "reactstrap";
import EmailParser from "./EmailParser/EmailParser";
import Message from "./Message/Message";
import Widget from "./Widget";
import ContactActivity from "./ContactActivity";
import MessageActivity from "./MessageActivity";

const index = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Analytics | Dashonic - React Admin & Dashboard Template</title>
                </MetaTags>

                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Dashboards" breadcrumbItem="Analytics" />

                    <Row>
                        <Col className="col-xxl-9" lg={8}>
                            <Message/>    
                            {/* <EmailParser/>                         */}
                        </Col>
                        <Col className="col-xxl-3" lg={4}>
                            <Widget />
                            <MessageActivity/>
                            <ContactActivity />                          
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default index;