import { gql } from "@apollo/client";

export const GET_BOT_RESPONSES = gql`
  query GetBotResponses {
    msgbox_BotResponse(
      where: { ParentBotReplyId: { _is_null: true } }
      order_by: { BotResponseId: desc }
    ) {
      BotResponseId
      Organisation {
        Name
      }
      APIAccount {
        Name
      }
      AssignTo
      AssignToDepartment
      IsEnabled
      IsDeleted
      Keyword
      Order
      Reply
      RequiresResponse
    }
  }
`;
