import { Redirect } from "react-router-dom";

//Sales
import Sales from "../pages/Sales";
import Customers from "../pages/Customers";
//Analytics
import Analytics from "../pages/Analytics";
//Calendar

//Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login1";
import Login2 from "../pages/AuthenticationInner/Login2";
import Register1 from "../pages/AuthenticationInner/Register1";
import Register2 from "../pages/AuthenticationInner/Register2";
import Signout1 from "../pages/AuthenticationInner/Signout1";
import Signout2 from "../pages/AuthenticationInner/Signout2";
import ForgotPassword1 from "../pages/AuthenticationInner/ForgotPassword1";
import ForgotPassword2 from "../pages/AuthenticationInner/ForgotPassword2";
import ResetPassword1 from "../pages/AuthenticationInner/ResetPassword1";
import ResetPassword2 from "../pages/AuthenticationInner/ResetPassword2";

import EmailVerification1 from "../pages/AuthenticationInner/EmailVerification1";
import EmailVerification2 from "../pages/AuthenticationInner/EmailVerification2";
import TwoStep1 from "../pages/AuthenticationInner/TwoStep1";
import TwoStep2 from "../pages/AuthenticationInner/TwoStep2";
import LockScreen1 from "../pages/AuthenticationInner/LockScreen1";
import LockScreen2 from "../pages/AuthenticationInner/LockScreen2";
import Thankyou1 from "../pages/AuthenticationInner/Thankyou1";
import Thankyou2 from "../pages/AuthenticationInner/Thankyou2";

//Error
import Error1 from "../pages/Error/Error1";
import Error2 from "../pages/Error/Error2";
import ErrorBasic from "../pages/Error/ErrorBasic";
import ErrorCover from "../pages/Error/ErrorCover";

//Authentication pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// msgboxx specific
import OrganisationsPage from "../pages/Organisations";
import UsersPage from "../pages/Users";
import InboxesPage from "../pages/Inboxes";
import TemplatesPage from "src/pages/Templates";
import MessagesPage from "src/pages/Messages";
import EmailParserLogsPage from "src/pages/EmailParserLogs";
import EmailParsersPage from "src/pages/EmailParsers";
import EmailForwardersPage from "src/pages/EmailForwarders";
import BotResponsesPage from "src/pages/BotResponses";
import WidgetPage from "src/pages/WebWidget";
import TeamsPage from "src/pages/Teams";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [
  //msgboxx
  { path: "/organisations", component: OrganisationsPage },
  { path: "/users", component: UsersPage },
  { path: "/inboxes", component: InboxesPage },
  { path: "/templates", component: TemplatesPage },
  { path: "/messages", component: MessagesPage },
  { path: "/emailparsers", component: EmailParsersPage },
  { path: "/emailforwarders", component: EmailForwardersPage },
  { path: "/botresponses", component: BotResponsesPage },
  { path: "/widgets", component: WidgetPage },
  { path: "/teams", component: TeamsPage },

  //dashboard
  { path: "/customers", component: Customers },
  { path: "/sales", component: Sales },
  { path: "/dashboards-analytics", component: Analytics },

  //logs
  { path: "/emailparserlogs", component: EmailParserLogsPage },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/sales" /> },
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/register", component: Register },
  { path: "/forgot-password", component: ForgetPwd },

  // Authentication Inner
  { path: "/auth-signin-basic", component: Login1 },
  { path: "/auth-signin-cover", component: Login2 },
  { path: "/auth-signup-basic", component: Register1 },
  { path: "/auth-signup-cover", component: Register2 },
  { path: "/auth-signout-basic", component: Signout1 },
  { path: "/auth-signout-cover", component: Signout2 },
  { path: "/auth-lockscreen-basic", component: LockScreen1 },
  { path: "/auth-lockscreen-cover", component: LockScreen2 },
  { path: "/auth-forgotpassword-basic", component: ForgotPassword1 },
  { path: "/auth-forgotpassword-cover", component: ForgotPassword2 },
  { path: "/auth-resetpassword-basic", component: ResetPassword1 },
  { path: "/auth-resetpassword-cover", component: ResetPassword2 },
  { path: "/auth-emailverification-basic", component: EmailVerification1 },
  { path: "/auth-emailverification-cover", component: EmailVerification2 },
  { path: "/auth-2step-basic", component: TwoStep1 },
  { path: "/auth-2step-cover", component: TwoStep2 },
  { path: "/auth-thankyou-basic", component: Thankyou1 },
  { path: "/auth-thankyou-cover", component: Thankyou2 },

  { path: "/error-404-basic", component: Error1 },
  { path: "/error-404-cover", component: Error2 },
  { path: "/error-500-basic", component: ErrorBasic },
  { path: "/error-500-cover", component: ErrorCover },
];

export { userRoutes, authRoutes };
