import React, { Dispatch, SetStateAction, useState, useEffect, useContext } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { useMutation } from "@apollo/client";
import SweetAlert from "react-bootstrap-sweetalert";
import { DELETE_LOCATION } from "./deleteLocation.query";
import { ThemeContext } from "src/helpers/themeContext";


interface IProps {
    deleteModel: boolean;
    setDeleteModel: Dispatch<SetStateAction<boolean>>;
    PassedUser : any;  
    onRefetch: any; 
  }

const DeleteLocation = (props: IProps)=>{
  
  const Theme = useContext(ThemeContext)
  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);
  const [Id, setId] = useState('');
  
  useEffect(() => {
    if (props.PassedUser) {
        setId(props.PassedUser.LocationId);
    }
  }, [props]);

   

      const handleShow = () => {
        props.setDeleteModel(false);
      };
      const toggle = () => {
        props.setDeleteModel(!props.deleteModel);
      };

    const handleModalOpen = () => { };
  
    const [deleteLocation] = useMutation(DELETE_LOCATION);

    const handleSubmit =() => {
      deleteLocation({
            variables :{
              LocationId:Id,  
            }
          })
          .then((response)=>{
            console.log("Data Updated successfully");
            setdynamic_description("Data deleted successfully");
            setsuccess_dlg(true);
            toggle();        
          })
          .catch((error)=>{
            console.log(error.message);
            setdynamic_description("Failed to delete the data");
            setfailure_dlg(true);
            toggle();
          })     
        }

    return(
    <React.Fragment>
        {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
            props.onRefetch();
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}

      {failure_dlg ? (
        <SweetAlert
          error
          title={Theme.name}
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      
     <Modal
             isOpen={props.deleteModel}
             toggle={toggle}
             size="lg"
             onOpened={() => handleModalOpen()}
        >

        <ModalHeader toggle={toggle} tag="h4">
          Delete Location
        </ModalHeader>

        <ModalBody className="modelStyle">
            Are you sure you want to delete this Location?
        </ModalBody>
        <ModalFooter>
        <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={handleShow} >
                    Close
                  </button>
                  <button 
                    type="button" 
                    className="btn btn-danger save-user"
                    onClick={handleSubmit} >
                    Delete
                  </button>
                </div>
        </ModalFooter>
    </Modal>

    </React.Fragment>
    );
};

export default DeleteLocation;