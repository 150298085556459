import { gql } from '@apollo/client';

export const DELETE_BOT = gql`
mutation delete_botResponse ($id: Int!, $userId: Int, $time: timestamptz) {
    update_msgbox_BotResponse(
      where: {BotResponseId: {_eq: $id}},
      _set: {IsDeleted:true, DeletedBy: $userId, DeletedOn:$time}) {
      affected_rows
    }
  }
  `
  