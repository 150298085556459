import { gql } from "@apollo/client";

export const GET_API_PROVIDER = gql`
  query MyQuery {
    msgbox_APIProvider(where: { Name: { _nlike: "gupshup" } }) {
      APIProviderId
    }
  }
`;

export const GET_USER_ORGADMIN_ROLE = gql`
  query MyQuery {
    msgbox_Role(where: { Name: { _eq: "OrgAdmin" } }) {
      RoleId
    }
  }
`;
