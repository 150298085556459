import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { withTranslation } from "react-i18next";

import { ThemeContext } from "src/helpers/themeContext";
//import drawer
import ReactDrawer from "react-drawer";
import "react-drawer/lib/react-drawer.css";

//import component
import RightSidebar from "../CommonForBoth/RightSidebar";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";


//Import Icons
import Icon from "@ailibs/feather-react-ts";

const Header = (props: any) => {

  const [isSearch, setSearch] = useState<boolean>(false);
  const [socialDrp, setsocialDrp] = useState<boolean>(false);
  const [position, setPosition] = useState<string>();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [megaMenu, setmegaMenu] = useState(false);
  const [categoryMenu, setcategoryMenu] = useState(false);
  /**
   * Rightsidebar drawer
   */
  const Theme = useContext(ThemeContext)
  const toggleTopDrawer = () => {
    setPosition("right");
    setOpenDrawer(!openDrawer);
  };

  const onDrawerClose = () => {

    setOpenDrawer(false);
  };

  /*** Sidebar menu icon and default menu set */
  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    }
    document.body.setAttribute("data-sidebar-size", "lg");
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">

            <div className="navbar-brand-box">
              <Link to="/sales" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={Theme.images.icon} alt="" height="50" />
                </span>
                <span className="logo-lg">
                  <img src={Theme.images.logo} alt="" height="50" />
                </span>
              </Link>

              <Link to="/sales" className="logo logo-light">
                <span className="logo-sm">
                  <img src={Theme.images.icon} alt="" height="50" />
                </span>
                <span className="logo-lg">
                  <img src={Theme.images.logo} alt="" height="50" />
                </span>
              </Link>
            </div>

            <button
              onClick={() => {
                tToggle();
              }}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>

            

            

          </div>

          <div className="d-flex">
            <div className="dropdown d-inline-block">


            </div>





            <NotificationDropdown />


            <ProfileMenu />

          </div>
        </div>
      </header>



    </React.Fragment>
  );
};

export default withTranslation()(Header);