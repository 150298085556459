import { gql } from "@apollo/client";

export const GET_TEMPLATES = gql`
  query MyQuery {
    msgbox_Template(order_by: { TemplateId: desc }) {
      TemplateId
      Name
      Status
      RejectionReason
      TemplateText
      CreatedOn
      IsDeleted
      TemplateType {
        TemplateTypeId
        Description
      }
      TemplateText
      TemplateExample
      Attachment
      AttachmentId
      ButtonMetadata
      LanguageCode
      OrganisationId
      Organisation {
        Name
      }
      TemplateId
      TemplateCategory {
        TemplateCategoryId
      }
      aPIAccountByApiaccount {
        APIAccountId
        Name
        Inboxes {
          InboxId
          Name
        }
      }
    }
  }
`;
