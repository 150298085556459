import React, { useState, Dispatch, SetStateAction } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

interface IProps {
  handleTeamDelete: any;
  setConfirmationModalOpen: Dispatch<SetStateAction<boolean>>;
}
const ConfirmDeleteModal = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Modal isOpen={isOpen} size="sm" style={{ marginTop: "10%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          padding: "20px",
        }}
      >
        <h3>Are you sure you want to delete this team?</h3>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "20px",
          }}
        >
          <button
            className="btn w-25 btn-danger"
            onClick={() => {
              setIsOpen(false), props.setConfirmationModalOpen(false);
            }}
          >
            No
          </button>
          <button
            className="btn  w-25 btn-success"
            onClick={() => {
              props.handleTeamDelete();
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;
