import { gql } from '@apollo/client';

export const DELETE_ORG = gql`
mutation delete_org ($OrganisationId: Int!, $userId: Int, $time: timestamptz) {
    update_msgbox_Organisation_many(updates: {
      where: {OrganisationId: {_eq: $OrganisationId}},
      _set: {IsDeleted:true, DeletedBy: $userId, DeletedOn:$time}}) {
      affected_rows
    }
  }
  
  `
  