import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { useQuery } from "@apollo/client";
import { GET_ORGANISATIONS } from "./organisations.query";
import AddOrganisation from "src/components/AddOrganisation/AddOrganisation";
import AddInbox from "src/components/AddInbox/AddInbox";
import EditOrgName from "src/components/EditOrgName/EditOrgName";
import { useMutation } from "@apollo/client";
import { UPDATE_ENABLED } from "./enabled.query";
import { DELETE_ORG } from "./deleteQuery";
import SweetAlert from "react-bootstrap-sweetalert";
import { ThemeContext } from "src/helpers/themeContext";
import DeleteModal from "src/components/deleteModal/deleteModal";
import { GET_TEAMS } from "./teamsQuery";
import AddTeam from "src/components/AddTeam/AddTeam";
import EditTeam from "src/components/EditTeam/EditTeam";

const Teams = () => {
  const [tableData, setTableData] = useState([]);
  const [addNewModal, setAddNewModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false)
  const [inboxModal, setInboxModal] = useState<boolean>(false);
  const [editOrgNameModel, setEditOrgNameModel] = useState(false);

  const [orgs, setOrgs] = useState<{ [key: string]: number }>({});

  const [selectedTeam, setSelectedTeam] = useState<number>(-1);
  const [PassedUser, setPassedUser] = useState(null);

  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const Theme = useContext(ThemeContext);
  const [deleteModal, setDeleteModal] = useState(false);
  const [teams, setTeams] = useState<{ [key: string]: number }>({});

  const columns = [
    {
      dataField: "Id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "Name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "OrgName",
      text: "Org name",
      sort: true,
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, user: any) => {
        const textString: String = user.Enabled
          ? "\u{1F6AB} Disable Organisation"
          : "\u{2713} Enable Organisation";

        return (
         
          <button className="btn btn-light btn-sm" onClick={()=>handleEditClick(user)}>
            <i className="mdi mdi-pencil font-size-16 text-success  " />
          </button>
        );
      },
    },
  ];

  const defaultSorted: any = [
    {
      dataField: "Id",
      order: "asc",
    },
  ];
  const selectRow: any = {
    mode: "checkbox",
  };
  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length,
    custom: true,
  };
  const { SearchBar } = Search;

  const { data, refetch } = useQuery(GET_TEAMS, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data && data.msgbox_Department) {
      var tmpData = data.msgbox_Department.map(function (item: {
        DepartmentId: any;
        Name: any;
        Organisation: any;
      }) {
        teams[item.Name] = item.DepartmentId;
        setTeams({ ...teams });

        return {
          Id: item.DepartmentId,
          Name: item.Name,
          OrgName: item.Organisation.Name,
        };
      });
      setTableData(tmpData);
    }
  }, [data]);

  //handle Enabel/Disable
  const [addEnable] = useMutation(UPDATE_ENABLED);
  const [deleteOrg] = useMutation(DELETE_ORG);

  const handleDelete = async (user: any) => {
    setDeleteModal(false);
    await deleteOrg({
      variables: {
        OrganisationId: user.Id,
        userId: localStorage.getItem("user_id"),
        time: new Date(Date.now()).toISOString(),
      },
    });
    setsuccess_dlg(true);
    setdynamic_description(
      "Item will not be available to the user now, it will be permanently deleted in 60 days"
    );
    refetch();
  };

  //handle Addrename
  const toggleRename = () => {
    setEditOrgNameModel(!editOrgNameModel);
  };

  const handleEditClick = (data: any) => {
    setSelectedTeam(teams[data.Name])
    setEditModal(!editModal)
  };

  useEffect(() => {
    if (selectedTeam != -1) setInboxModal(!inboxModal);
  }, [selectedTeam]);

  const handleUserClicks = () => {
    setAddNewModal(!addNewModal);
  };
  const refetchData = () => {
    window.location.reload()
    refetch();
  };

  return (
    <React.Fragment>
      {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      <DeleteModal
        isOpen={deleteModal !== false}
        cancel={() => setDeleteModal(false)}
        submit={() => handleDelete(deleteModal)}
      />

      <Card>
        <CardHeader>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <h4 className="card-title">All Teams</h4>
              </div>
            </Col>
            <Col md={6}>
              <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                <div>
                  <Link to="#" className="btn btn-light" onClick={refetchData}>
                    <i className="uil uil-refresh me-1"></i> Refresh
                  </Link>
                </div>

                <div>
                  <Link
                    to="#"
                    className="btn btn-light"
                    onClick={handleUserClicks}
                  >
                    <i className="uil uil-plus me-1"></i> Add New
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardHeader>

        <CardBody>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            // columns={columns}
            // data={productData}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="Id"
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            // responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            selectRow={selectRow}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                          <EditOrgName
                            PassedUser={PassedUser}
                            editOrgNameModel={editOrgNameModel}
                            setEditOrgNameModel={setEditOrgNameModel}
                            onRefresh={refetchData}
                          />
                          <AddTeam
                            onRefetch={refetchData}
                            modal={addNewModal}
                            setModal={setAddNewModal}
                          />
                          <EditTeam
                            modal={editModal}
                            setModal={setEditModal}
                            onRefetch={refetchData}
                            teamId={selectedTeam}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Teams;
