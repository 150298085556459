import React, { useState } from 'react';
import { UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Card, CardBody, Form, Input } from "reactstrap";
import { Link } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import { OrderData } from "../../common/data/dashboard";
import { GET_ORGANISATIONS } from './customers.query';

const Customers = () => {
    const [menu1, setMenu1] = useState<boolean>(false);
    const [search_Menu, setsearch_Menu] = useState<boolean>(false);


    const {
		loading,
		error,
		data,
	    } = useQuery(GET_ORGANISATIONS, {
            fetchPolicy: "no-cache"
	});
console.log("data:", data)
    //Toggle search
    const toggleSearch = () => {
        setsearch_Menu(!search_Menu);
    };

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="d-flex justify-content-between">
                        <h4 className="card-title mb-4">Customers</h4>

                       
                    </div>

                    <div className="table-responsive">
                        <table className="table table-hover table-nowrap mb-0 align-middle table-check">
                            <thead className="bg-light">
                                <tr>
                                    <th className="rounded-start" style={{ width: "15px" }}>
                                        <div className="form-check">
                                            <input className="form-check-input font-size-16" type="checkbox"
                                                value="" id="checkAll" />
                                            <label className="form-check-label" htmlFor="checkAll"> </label>
                                        </div>
                                    </th>
                                    <th>ID</th>
                                    <th>Created</th>
                                    <th>Organisation</th>
                                    <th>Users</th>
                                    <th>Enabled</th>
                                    <th className="rounded-end">Actions</th>
                                </tr>

                            </thead>

                            <tbody>
                                {(data?.msgbox_Organisation || []).map((order: any, key: number) => (
                                    <tr key={key}>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input font-size-16" type="checkbox"
                                                    value="" id="flexCheckexampleone" />
                                                <label className="form-check-label" htmlFor="flexCheckexampleone">
                                                </label>
                                            </div>
                                        </td>
                                        <td className="fw-medium">
                                            {order['OrganisationId']}
                                        </td>
                                        <td>
                                            { new Date(order['CreatedOn']).toLocaleDateString()}
                                        </td>

                                        <td>
                                            <div className="d-flex">
                                                <div>
                                                    <p className="mb-0">{order['Name']}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {order['users_aggregate']['aggregate']['count']}    
                                        </td>
                                        <td>
                                            { order['IsEnabled'] ? 'Yes' : 'No' }
                                        </td>


                                        <td>
                                            <UncontrolledDropdown>
                                                <DropdownToggle className="card-drop" tag="a">
                                                    <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-end">
                                                    <DropdownItem>
                                                        <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>Enable
                                                    </DropdownItem>

                                                    <DropdownItem>
                                                        <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>Disable
                                                    </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </td>
                                    </tr>
                                ))}

                        </tbody>
                    </table>
                </div>
            </CardBody>
        </Card>
        </React.Fragment >
    );
};

export default Customers;