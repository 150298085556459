import { gql } from '@apollo/client';

export const DELETE_USER = gql`
mutation delete_user($id: Int!) {
    update_msgbox_User(
      where: {UserId: {_eq: $id}},
      _set: {IsDeleted:true}) {
      affected_rows
  }
}
  `
  