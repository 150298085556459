import React, {
  Dispatch,
  SetStateAction,
  useState,
  useContext,
  useEffect,
} from "react";
import { Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation, useQuery } from "@apollo/client";
import SweetAlert from "react-bootstrap-sweetalert";
import { ThemeContext } from "src/helpers/themeContext";
import Select, { OnChangeValue } from "react-select";
import {
  ADD_INBOX,
  EDIT_ROLE,
  EDIT_USER,
  GET_INBOXES,
  GET_ORGANISATIONS,
  GET_ROLES,
  REMOVE_INBOX,
  GET_TEAMS,
  GET_TEAMS_FOR_USER,
  REMOVE_USER_DEPARTMENT,
  ADD_USER_TO_TEAM,
} from "./query";
import Organisations from "src/pages/Organisations/Organisations";

interface IProps {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  onRefetch: any;
  data: any;
}

const EditUser = (props: IProps) => {
  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);

  const [orgs, setOrgs] = useState([]);
  const [org, setOrg] = useState<any>(false);

  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState<any>(false);

  const [inboxes, setInboxes] = useState([]);
  const [inbox, setInbox] = useState<any>([]);

  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState<any>([]);

  const [email, setEmail] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [userId, setUserId] = useState(-1);

  const [editUser] = useMutation(EDIT_USER);
  const [removeInboxes] = useMutation(REMOVE_INBOX);
  const [editRole] = useMutation(EDIT_ROLE);
  const [addInbox] = useMutation(ADD_INBOX);
  const [removeUserDepartments] = useMutation(REMOVE_USER_DEPARTMENT)
  const [addUserToTeam] = useMutation(ADD_USER_TO_TEAM)

  const { data: orgData, refetch: refetchOrgData } =
    useQuery(GET_ORGANISATIONS);

  const { data: roleData, refetch: refetchRoleData } = useQuery(GET_ROLES);

  const { data: teamData, refetch: refetchTeamData } = useQuery(GET_TEAMS, {
    variables: {
      orgId: org?.value || -1,
    },
  });

  const { data: inboxData, refetch: refetchInboxData } = useQuery(GET_INBOXES, {
    variables: {
      org: org?.value || -1,
    },
  });

  const { data: userTeamsData, refetch: refetchUserTeamsData } = useQuery(
    GET_TEAMS_FOR_USER,
    {
      variables: {
        userId: userId || -1,
      },
    }
  );

  const Theme = useContext(ThemeContext);

  const toggle = () => {
    setInbox([]);
    setRole(false);
    setOrg(false);
    props.setModal(!props.modal);
  };

  useEffect(() => {
    if (!props.data) return;

    setEmail(props.data.Email);
    setFname(props.data.FirstName);
    setLname(props.data.LastName);
    setOrg({ label: props.data.OrgName, value: props.data.OrganisationId });
    setUserId(props.data.Id);
    let arr = props.data.Inboxes.filter((item: any) => item.Inbox).map(
      (item: any) => {
        return { value: item.Inbox.InboxId, label: item.Inbox.Name };
      }
    );
    setInbox(arr);
    if (props?.data?.role?.[0]?.Role)
      setRole({
        value: props.data.role[0].Role.RoleId,
        label: props.data.role[0].Role.Description,
      });
  }, [props]);

  useEffect(() => {
    if (!roleData) return;
    let arr = roleData.msgbox_Role
      .filter((item: any) => {
        return item.RoleId !== 2;
      })
      .map((item: any) => {
        return { label: item.Description, value: item.RoleId };
      });
    setRoles(arr);
  }, [roleData]);

  useEffect(() => {
    if (!orgData) return;
    let arr = orgData.msgbox_Organisation.map((item: any) => {
      return { label: item.Name, value: item.OrganisationId };
    });
    setOrgs(arr);
  }, [orgData]);

  useEffect(() => {
    if (!teamData) return;
    let arr = teamData.msgbox_Department.map((team: any) => {
      return { label: team.Name, value: team.DepartmentId };
    });
    setTeams(arr);
  }, [teamData]);

  useEffect(() => {
    refetchInboxData();
    refetchTeamData();
    refetchUserTeamsData();
  }, [org]);

  useEffect(() => {
    if (!inboxData) return;
    let arr = inboxData.msgbox_Inbox.map((item: any) => {
      return { label: item.Name, value: item.InboxId };
    });
    setInboxes(arr);
  }, [inboxData]);

  useEffect(() => {
    if (!userTeamsData) return;
    let userTeamArr = userTeamsData.msgbox_UserDepartment.map((team: any) => {
      return { label: team.Department.Name, value: team.DepartmentId};
    });

    setTeam(userTeamArr);
  }, [userTeamsData]);

  const handleSubmit = async (values: any) => {
    try {
      if (
        !org ||
        !inbox ||
        !role ||
        !values.fname ||
        !values.email ||
        !values.lname
      )
        return;

      await editUser({
        variables: {
          firstName: values.fname,
          lastName: values.lname,
          id: props.data.Id,
        },
      });

      await removeInboxes({
        variables: {
          id: props.data.Id,
        },
      });

      await removeUserDepartments({
        variables: {
          id: props.data.Id,
        },
      });

      await editRole({
        variables: {
          roleId: role.value,
          id: props.data.Id,
        },
      });

      for (let i of inbox) {
        await addInbox({
          variables: {
            inboxId: i.value,
            id: props.data.Id,
            OrganisationId: org.value,
          },
        });
      }

      for (let i of team){
        await addUserToTeam({
          variables: {
            departmentId: i.value,
            id: props.data.Id,
            OrganisationId: org.value,
          },
        })
      }

      setdynamic_description("User edited successfully");
      setsuccess_dlg(true);
      toggle();
    } catch {
      setdynamic_description("User edit failed");
      setfailure_dlg(true);
    }
  };

  const selectStyle = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: Theme.styles.primary, // primary
      border: `1px solid ${Theme.styles.primaryBold}`, //primary bold
      color: Theme.styles.textColour1, //textColor1
      borderColor: Theme.styles.primary,
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: Theme.styles.textColour1, //textColor1
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: Theme.styles.primary, // primary
      border: `1px solid ${Theme.styles.primaryBold}`, //primary bold
      color: Theme.styles.textColour1, //textColor1
    }),
    menuList: (provided: any) => ({
      ...provided,
      backgroundColor: Theme.styles.primary, // primary
      border: `1px solid ${Theme.styles.primaryBold}`, //primary bold
      color: Theme.styles.textColour1, //textColor1
    }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: isFocused ? Theme.styles.secondary : null,
        color: Theme.styles.textColour1, //textColor1
      };
    },
    multiValue: (styles: any) => {
      return {
        ...styles,
        backgroundColor: Theme.styles.secondary,
      };
    },
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: Theme.styles.textColour1, //textColor1
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      color: Theme.styles.textColour1, //textColor1
      ":hover": {
        backgroundColor: Theme.styles.primary,
      },
    }),
  };

  return (
    <React.Fragment>
      {failure_dlg ? (
        <SweetAlert
          error
          title={Theme.name}
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
            props.onRefetch();
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      <Modal isOpen={props.modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle} tag="h4">
          Edit User
        </ModalHeader>
        <ModalBody className="modelStyle">
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleSubmit(values);
            }}
          >
            <Row form className="d-flex flex-row mb-3">
              <Col>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="fname"
                    label="First Name"
                    type="text"
                    placeholder="Enter first name"
                    errorMessage="Invalid first name"
                    validate={{
                      required: { value: true },
                    }}
                    value={fname}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="email"
                    label="Email"
                    type="email"
                    disabled={true}
                    placeholder="Enter email"
                    errorMessage="Invalid Email"
                    validate={{
                      email: true,
                      required: { value: true },
                    }}
                    value={email}
                  />
                </div>
                <div className="mb-3">
                  <label>Select Inboxes</label>
                  <Select
                    isMulti
                    isClearable={true}
                    name="inbox"
                    placeholder="Select"
                    options={inboxes}
                    onChange={(e: any) => setInbox(e)}
                    value={inbox}
                    styles={selectStyle}
                  />
                </div>

                <div className="mb-3">
                  <label>Select Role</label>

                  <Select
                    isClearable={true}
                    name="role"
                    placeholder="Select"
                    options={roles}
                    onChange={(e: any) => setRole(e)}
                    styles={selectStyle}
                    value={role}
                  />
                </div>
              </Col>
              <Col className="px-2">
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="lname"
                    label="Last Name"
                    type="text"
                    placeholder="Enter last name"
                    errorMessage="Invalid Last Name"
                    validate={{
                      required: { value: true },
                    }}
                    value={lname}
                  />
                </div>
                <div className="mb-3">
                  <label>Select Organisation</label>

                  <Select
                    isClearable={true}
                    name="organisation"
                    isDisabled={true}
                    placeholder="Select"
                    options={orgs}
                    onChange={(e: any) => setOrg(e)}
                    styles={selectStyle}
                    value={org}
                  />
                </div>

                <div className="mb-3">
                  <label>Select Teams</label>
                  <Select
                    isMulti
                    isClearable={true}
                    name="teams"
                    placeholder="Select"
                    options={teams}
                    onChange={(e: any) => setTeam(e)}
                    value={team}
                    styles={selectStyle}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={toggle}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn  btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default EditUser;
