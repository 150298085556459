import { gql } from "@apollo/client";

export const GET_EMAILPARSERS = gql`
  query GetEmailParsers {
    msgbox_EmailParserConfig {
      EmailParserConfigId
      DoNotForwardIfDeliveredOK
      DoNotForwardIfReadOK
      DoNotForwardIfRepliedTo
      DoNotForwardIfSentOK
      EmailAddress
      ForwardingEmail
      ParserType
      Organisation {
        Name
      }
      Template {
        Name
        TemplateId
        IsDeleted
        Status
      }
      APIAccount {
        Name
        APIAccountId
    }
  }
}
`;
