import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import jwt_decode, { JwtPayload } from "jwt-decode";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { withRouter } from "react-router-dom";

//redux
import { useSelector } from "react-redux";
import { GetUser } from "src/common/userUtility";

const ProfileMenu = (props: any) => {
  const { success } = useSelector((state: any) => ({
    success: state.profile.success,
  }));

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState<boolean>(false);

  const [username, setusername] = useState("");
  const [email, setemail] = useState<string>("");
  const [imageURI, setImageURI] = useState("");

  useEffect(() => {
    let isMounted = true;

    const getInfo = async () => {
      const getAuthUser = localStorage.getItem("authUser");
      if (getAuthUser) {
        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
          const obj = JSON.parse(getAuthUser);
          if (obj.displayName) {
            setusername(obj.displayName);
          } else {
            setusername(obj.fullName);
          }
          setemail(obj.email);
        } else if (
          process.env.REACT_APP_DEFAULTAUTH === "fake" ||
          process.env.REACT_APP_DEFAULTAUTH === "jwt"
        ) {
          const obj = JSON.parse(getAuthUser);
          const jwt = obj.access_token;
          const decoded: any = jwt_decode<JwtPayload>(jwt);
          const jwtEmail: string =
            decoded["https://hasura.io/jwt/claims"]["x-hasura-email"];
          setemail(jwtEmail);
          await fetchUser(jwtEmail);
        }
      }
    };

    const fetchUser = async (email: string) => {
      const user = await GetUser(email);
      if (isMounted) {
        setusername(user?.firstName + " " + user?.lastName);
        if (user?.imageURL) {
          setImageURI(user.imageURL);
        } else {
          setImageURI("/images/defaultUser.jpg");
        }
      }
    };

    getInfo();

    return () => {
      isMounted = false;
    };
  }, [success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item user text-start d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={imageURI}
            alt="Header Avatar"
          />
          <span className="ms-2 d-none d-sm-block user-item-desc">
            <span className="user-name">{username}</span>
            <span className="user-sub-title text-muted">Reseller</span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end pt-0" dir="left">
          <div className="p-3 border-bottom">
            <h6 className="mb-0">{username}</h6>
            <p className="mb-0 font-size-11 fw-semibold">
              {email}
            </p>
          </div>

          <DropdownItem tag="a" href="/logout">
            <i className="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i>{" "}
            <span className="align-middle">{props.t("Logout")}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(ProfileMenu));
