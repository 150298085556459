import { gql } from '@apollo/client';

export const DELETE_INBOX = gql`
mutation delete_inbox ($InboxId: Int!, $userId: Int, $time: timestamptz) {
    update_msgbox_Inbox(
      where: {InboxId: {_eq: $InboxId}},
      _set: {IsDeleted:true, DeletedBy: $userId, DeletedOn:$time}) {
      affected_rows
  }
}
  `
  