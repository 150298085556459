import React, {useContext} from "react";
import MetaTags from "react-meta-tags";
import { ThemeContext } from "src/helpers/themeContext";
//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Col, Container, Row } from "reactstrap";
import BotResponses from "./BotResponses";

const BotResponsesPage = () => {
  const theme = useContext(ThemeContext)
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Bot Responses | {theme.name} bot responses</title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="Bot Responses" />

          <Row>
            <Col lg={12}>
              <BotResponses />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BotResponsesPage;
