import React, { useState } from "react";
import { Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import PermissionsForm from "../PermissionsForm/PermissionsForm";
import SweetAlert from "react-bootstrap-sweetalert";

interface IProps {
  isOpen: boolean;
  cancel: any;
  orgId: number;
  orgName: string;
  setsuccess_dlg: any;
  setdynamic_description: any;
}

const PermissionsModal = (props: IProps) => {

  return (
    <React.Fragment>
      <Modal isOpen={props.isOpen} toggle={() => props.cancel()} size="lg">
        <ModalHeader tag="h4">
          Organisation Permissions: {props.orgName}
        </ModalHeader>
        <ModalBody className="modelStyle">
          <PermissionsForm
            orgId={props.orgId}
            isOpen={props.isOpen}
            cancel={props.cancel}
            setsuccess_dlg={props.setsuccess_dlg}
            setdynamic_description={props.setdynamic_description}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default PermissionsModal;
