import { gql } from '@apollo/client';

export const UPDATE_LOCATION_WIDGET= gql`
mutation MyMutation( $LocationId: Int!, $Text: String!, $Url:String! ) {
    update_msgbox_WidgetLocations(where: {LocationId: {_eq:$LocationId}}, _set: {Text: $Text, Url: $Url}){
        affected_rows
      }
  }
`;  

