import { gql } from "@apollo/client";

export const GET_PARSERLOGS = gql`
  query MyQuery {
    msgbox_EmailParserLog {
      EmailParserLogId
      MessageArrived
      OrganisationId
      S3Identifier
      TemplateMessageSent
      ErrorDetails
      EmailForwardedAt
      ConversationId
      CustomerReplied
      ParserType
      Organisation {
        Name
      }
    }
  }
`;
