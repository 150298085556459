import React, { Dispatch, SetStateAction, useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation } from "@apollo/client";
import SweetAlert from "react-bootstrap-sweetalert";
import { UPDATE_LOCATION_WIDGET } from "./EditLocationWidget.query";
import { ThemeContext } from "src/helpers/themeContext";

interface IProps {
    editLocationModel: boolean;
    setEditLocationModel: Dispatch<SetStateAction<boolean>>;
    PassedUser : any;  
    onRefetch: any; 
  }

const EditLocationWidget = (props: IProps)=>{

  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);
  const Theme = useContext(ThemeContext)

  const [Id, setId] = useState('');
  const [text, setText] = useState('');
  const [url, setUrl] = useState('');
  

  useEffect(() => {
    if (props.PassedUser) {
        setUrl(props.PassedUser.Url)
        setText(props.PassedUser.Text);
        setId(props.PassedUser.LocationId);
    }
  }, [props]);

  
  const handleShow = () => {
      props.setEditLocationModel(false);
  };
  const toggle = () => {
      props.setEditLocationModel(!props.editLocationModel);
  };

  const handleModalOpen = () => { };
  
  const [updateLocationWidget] = useMutation(UPDATE_LOCATION_WIDGET);

  const handleSubmit =  (values: any) => {
    updateLocationWidget({
          variables :{
            LocationId:Id,
            Text:values.Text,
            Url:values.Url       
          }
        })
        .then((response)=>{
          console.log("Data Updated successfully");
          setdynamic_description("Data Updated successfully");
          setsuccess_dlg(true);
          toggle();        
        })
        .catch((error)=>{
          console.log(error.message);
          setdynamic_description("Failed to Update the data");
          setfailure_dlg(true);
          toggle();
        })     
     }

    return(
    <React.Fragment>
        {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
            props.onRefetch();
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}

      {failure_dlg ? (
        <SweetAlert
          error
          title={Theme.name}
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      
     <Modal
             isOpen={props.editLocationModel}
             toggle={toggle}
             size="lg"
             onOpened={() => handleModalOpen()}
        >

         <ModalHeader toggle={toggle} tag="h4">
         Edit Location Details
        </ModalHeader>

        <ModalBody className="modelStyle">
            <AvForm  
                onValidSubmit={(e: any, values: any) => {
                handleSubmit(values);                
              }}> 
                <Row form className="d-flex flex-row mb-3">
                    <Col>
                    <div className="mb-3">
                    <AvField
                        className="avInput"
                        name="Text"
                        label="Edit Text"
                        type="Text"
                        placeholder="Edit Text"
                        errorMessage="Invalid URL"
                        validate={{
                            required : { value: true , errorMessage: "Text is required"},
                        }}
                        value={text}
                        onChange={(e:any) => setText(e.target.value)}
                    />
                    </div>
                    
                    <div className="mb-3">
                    <AvField
                        className="avInput"
                        name="Url"
                        label="Edit URL"
                        type = "text"
                        placeholder="Edit URL"
                        validate={{
                        required: { value: true, errorMessage: "URL is required"},
                        url:{errorMessage:"Invalid URL"},
                        }}
                        value={url}
                        onChange={(e:any) => setUrl(e.target.value)}
                    />
                    </div>
                    </Col>
                </Row>
                <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={handleShow}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
            </AvForm>
        </ModalBody>
        </Modal>

    </React.Fragment>
    );
};

export default EditLocationWidget;