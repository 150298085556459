import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query MyQuery {
    msgbox_Widget(order_by: { WidgetId: desc }) {
      Name
      PublicId
      WelcomeText
      WidgetId
      WidgetBrand {
        Name
      }
    }
  }
`;
