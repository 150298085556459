import { gql } from '@apollo/client';

export const UPDATE_INBOX_NAME = gql`
mutation MyMutation($Name: String!, $InboxId: Int!, $APIAccountId: Int!) {
  update_msgbox_Inbox(where: {InboxId: {_eq: $InboxId}}, _set: {Name: $Name}) {
    affected_rows
  }
  update_msgbox_APIAccount(where: {APIAccountId: {_eq: $APIAccountId}}, _set: {Name: $Name}) {
    affected_rows
  }
}
`;

