import { gql } from "@apollo/client";

export const GET_ORGANISATIONS = gql`
  query MyQuery {
    msgbox_Organisation(order_by: { Name: asc }) {
      OrganisationId
      Name
      APIAccounts {
        Name
        APIAccountId
      }
    }
  }
`;

export const ADD_BOT_RESPONSE = gql`
  mutation MyMutation(
      $apiAccountId: Int!,
      $isDeletable: Boolean!,
      $isEnabled: Boolean!,
      $keyword: String,
      $reply: String!,
      $organisationId: Int!,
      $botResponseType: String!,
      $parentBotReplyId: Int = null,
      $order: Int = 0,
      $requiresResponse: Boolean = false,
      $captureEmailAddress: Boolean = false,
      $listMetadata: String = null,
      $replyMetadata: String = null,
    ) {
    insert_msgbox_BotResponse(
      objects: {
        APIAccountId: $apiAccountId,
        IsDeletable: $isDeletable,
        IsEnabled: $isEnabled,
        Keyword: $keyword,
        Reply: $reply,
        OrganisationId: $organisationId,
        BotResponseType: $botResponseType,
        ParentBotReplyId: $parentBotReplyId,
        Order: $order,
        RequiresResponse: $requiresResponse,
        CaptureEmailAddress: $captureEmailAddress,
        ListMetadata: $listMetadata,
        ReplyMetadata: $replyMetadata
      }) {
      returning {
            BotResponseId
          }
      }
  }
`
