import { useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import {USER_DATA_COUNT} from "./userAnalytics.query";
import convertTimestampToDate from "src/helpers/convertTimestampToDate";


const UserAnalysisWidget = () => {
    var percentange: any;
    var badge: any;
    var arrow: any;

    const [count, setCount] = useState(null);
    const [countThirtyDaysAgo, setCountThirtyDaysAgo] = useState(null);


        const today = new Date();

        const firstDayThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    
        const {data:userData} = useQuery(USER_DATA_COUNT,{
                fetchPolicy: "no-cache",
                variables: {
                    start: convertTimestampToDate(firstDayThisMonth) ,
                    end: convertTimestampToDate(today) 
                }
         });
            
         const firstDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1); 
         let lastDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
         const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
         if (lastDayLastMonth > lastDayOfLastMonth) {
             lastDayLastMonth = lastDayOfLastMonth;
         }
        const {data:userthirtyDaysAgoData} = useQuery(USER_DATA_COUNT,{
            fetchPolicy: "no-cache",
            variables: {
                start: convertTimestampToDate(firstDayLastMonth),
                end: convertTimestampToDate(lastDayLastMonth)
            }
        });


        useEffect(() => {
            if(userData) {
                let count: any = 0
                userData.reporting_UsersByReseller.forEach((item: any) => {
                    count += item.AddedCount
                })
                setCount(count)
            }
        }, [userData])
    
        useEffect(() => {
            if(userthirtyDaysAgoData) {
                let count: any = 0
                userthirtyDaysAgoData.reporting_UsersByReseller.forEach((item: any) => {
                    count += item.AddedCount
                })
                setCountThirtyDaysAgo(count)
            }
        }, [userthirtyDaysAgoData])



    if(count !== null && countThirtyDaysAgo !== null){
        const diff = count - countThirtyDaysAgo;
        if (diff === 0) {
            percentange = "0";
            badge = "badge badge-soft-light ms-2";
            arrow = "";
        }
        else {
            percentange = ((diff / countThirtyDaysAgo) * 100).toFixed(1) ;
            if (percentange > 0) {
                badge = "badge badge-soft-success ms-2";
                arrow = "uil uil-arrow-up-right text-success ms-1"
            } else {
                badge = "badge badge-soft-danger ms-2";
                arrow = "uil uil-arrow-down-left text-danger ms-1";
            }
        }
    }
   

    return (
        <div>
            <ul className="list-group list-group-flush" style={{marginBottom: 10}}>
                    <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-3">
                            <div className="avatar-sm">
                                <div className="avatar-title rounded-circle font-size-12">
                                    <i className="fas fa-user"></i>
                                </div>
                            </div>
                        </div>
                        <div className="flex-grow-1">
                            <p className="text-muted mb-1">Users</p>
                            <h5 className="font-size-16 mb-0">{count}</h5>
                        </div>
                        <div className="flex-shrink-0 align-self-end">
                            <div className={badge}>
                                {percentange} %
                                <i className={arrow}></i>
                            </div>
                        </div>
                    </div>
            </ul>
        </div>
    );
}

export default UserAnalysisWidget;