//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import jwt_decode from "jwt-decode";

// action
import {
    apiError,
    loginSuccess
} from "./reducer";

import {
    postFakeLogin,
    postJwtLogin,
    postSocialLogin,
} from "../../../helpers/fakebackend_helper";

export const loginUser = (user: any, history: any) => async (dispatch: any) => {
    try {
        let response;
        let fireBaseBackend = getFirebaseBackend();
        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {

            response = fireBaseBackend.loginUser(
                user.email,
                user.password
            );

        } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {

            response = postJwtLogin({
                username: user.email,
                password: user.password
            });

        } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
            response = postFakeLogin({
                email: user.email,
                password: user.password,
            });
        }

        const data = await response;

        if (data) {
            console.log("data:", data)
            const decoded: any = jwt_decode(data.access_token);
            if (
                decoded["https://hasura.io/jwt/claims"]["x-hasura-reseller-id"] &&
                decoded["https://hasura.io/jwt/claims"]["x-hasura-reseller-id"].length > 0
            ) {
                localStorage.setItem("authUser", JSON.stringify(data));
                localStorage.setItem("access_token", JSON.stringify(data.access_token));
                localStorage.setItem("user_id", decoded["https://hasura.io/jwt/claims"]["x-hasura-msgbox-id"]);
                dispatch(loginSuccess(data));
                history.push("/sales");
            } else {
                console.log("JWT token is not valid for reseller");
                dispatch(apiError("Please login with reseller account"));
            }
        }
    } catch (error) {
        console.log("errorr:", error);
        dispatch(apiError("Invalid credentials"));
    }
};


export const socialLogin = (data: any, history: any, type: any) => async (dispatch: any) => {
    try {
        let response;

        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {

            let fireBaseBackend = getFirebaseBackend();

            response = fireBaseBackend.socialLoginUser(
                data,
                type
            );

        } else {
            response = postSocialLogin(data);
        }

        const socialdata = await response;

        if (socialdata) {
            localStorage.setItem("authUser", JSON.stringify(socialdata));
            dispatch(loginSuccess(socialdata));
            history.push("/sales");
        }

    } catch (error) {
        dispatch(apiError(error));
    }
};

export const logoutUser = (history: any) => async (dispatch: any) => {
    try {
        localStorage.removeItem("authUser");

        history.push("/login");
    } catch (error) {
        dispatch(apiError(error));
    }
};