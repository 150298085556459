import React, { Dispatch, SetStateAction, useState, useEffect,useContext } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation } from "@apollo/client";
import SweetAlert from "react-bootstrap-sweetalert";
import { UPDATE_INBOX_NAME } from "./EditInboxName.query";
import { ThemeContext } from "src/helpers/themeContext";


interface IProps {
   
  editInboxModel: boolean;
  setEditInboxModel: Dispatch<SetStateAction<boolean>>;
    PassedUser: any;
    onRefresh: any; 
  }

const EditInboxName = (props: IProps)=>{

  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);
  const Theme = useContext(ThemeContext)

  const [InboxName, setInboxName] = useState('');
  const [Id, setId] = useState('');
  const [ApiId, setApiId] = useState('');
  useEffect(() => {
    if (props.PassedUser) {
       
        setInboxName(props.PassedUser.InboxName);
        setId(props.PassedUser.Id);
        setApiId(props.PassedUser.APIAccountId);
    }
  }, [props]);

      const handleShow = () => {
        props.setEditInboxModel(false);
      };
      const toggle = () => {
        props.setEditInboxModel(!props.editInboxModel);
      };

  const handleModalOpen = () => { };
 
  const [addRenameInbox] = useMutation(UPDATE_INBOX_NAME);

  const handleSubmit =  (values: any) => {
    addRenameInbox({
          variables :{
            Name:values.Rename,
            InboxId:Id ,
            APIAccountId:ApiId         
          }
        })
        .then((response)=>{
          console.log("Data Updated successfully");
          setdynamic_description("Organization edited successfully");
          setsuccess_dlg(true);
          toggle();   
        })
        .catch((error)=>{
          console.log(error.message);
          setdynamic_description("Failed to Update the data");
          setfailure_dlg(true);
          toggle();
        })
      
     }

    return(
    <React.Fragment>
        {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
            props.onRefresh();
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}

      {failure_dlg ? (
        <SweetAlert
          error
          title={Theme.name}
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      
        <Modal
             isOpen={props.editInboxModel}
             toggle={toggle}
             size="lg"
             onOpened={() => handleModalOpen()}
        >

         <ModalHeader toggle={toggle} tag="h4">
          Rename Inbox 
        </ModalHeader>

        <ModalBody className="modelStyle">
            <AvForm  
                onValidSubmit={(e: any, values: any) => {
                handleSubmit(values);
                 }}
              > 
                <Row form className="d-flex flex-row mb-3">
                    <Col>
                    <div className="mb-3">
                    <AvField
                        className="avInput"
                        name="Name"
                        label="Inbox Name"
                        type="Text"
                        placeholder="Inbox Name"
                        readOnly = {true}
                        value={InboxName}
                    />
                    </div>
                    
                    <div className="mb-3">
                    <AvField
                        className="avInput"
                        name="Rename"
                        label="Rename Inbox"
                        placeholder="Enter Inbox Name"
                        errorMessage="Invalid Name"
                        validate={{
                        required: { value: true },
                        }}
                        value={""}
                    />
                    </div>
                    </Col>
                </Row>
                <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={handleShow}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
            </AvForm>
        </ModalBody>
        </Modal>

    </React.Fragment>
    );
};

export default EditInboxName;