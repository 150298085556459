import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,  Modal,
  ModalHeader,
  ModalBody, } from "reactstrap";
import { Link } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { useMutation, useQuery } from "@apollo/client";
import { GET_TEMPLATES } from "./templates.query";
import AddPrebuiltTemplate from "src/components/AddPrebuiltTemplate/AddPrebuiltTemplate";
import { DELETE_TEMPLATE } from "./deleteQuery";
import { ThemeContext } from "src/helpers/themeContext";
import SweetAlert from "react-bootstrap-sweetalert";
import AddTemplate from "src/components/AddTemplate/AddTemplate";
import EditTemplate from "src/components/EditTemplate/EditTemplate";
import DeleteModal from "src/components/deleteModal/deleteModal";
import { UN_DELETE_TEMPLATE } from "./unDeleteQuery";
import { APPROVE_TEMPLATE } from "./approveQuery";


const Templates = () => {
  console.log("Templates Started");
  const [tableData, setTableData] = useState([]);
  const [modal, setModal] = useState<boolean>(false);
  const [customTemplateModal, setCustomTemplateModal] = useState(false)
  const [editTemplateModal, setEditTemplateModal] = useState(false)
  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const Theme = useContext(ThemeContext);
  const [deleteTemplate] = useMutation(DELETE_TEMPLATE);
  const [unDeleteTemplate] = useMutation(UN_DELETE_TEMPLATE);
  const [approveTemplate] = useMutation(APPROVE_TEMPLATE);

  const [deleteModal, setDeleteModal] = useState(false)
  const [approveModal, setApproveModal] = useState(false)

  const columns = [
    {
      dataField: "Id",
      text: "Id",
      sort: true,
    },

    {
      dataField: "Name",
      text: "Template Name",
      sort: true,
    },
    {
      dataField: "Status",
      text: "Status",
      sort: true,
      
    },
    {
      dataField: "RejectionReason",
      text: "Rejection Reason",
      sort: true,    
      formatter: (cellContent:any, row:any) => {
        return (
          <div style={{ whiteSpace: 'pre-wrap', width: '200px' }}>
            {cellContent}
          </div>
        );
      },
    },
    {
      dataField: "OrgName",
      text: "Org Name",
      sort: true,  
    },
    {
      dataField: "InboxName",
      text: "Inbox Name",
      sort: true,
    },
    {
      dataField: "CreatedOn",
      text: "Created",
      sort: true,
    },
    {
      dataField: "IsDeleted",
      text: "IsDeleted",
      sort: true,
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (cellContent: any, user: any) => {
        return (
          <React.Fragment>
            <UncontrolledDropdown>
              <DropdownToggle tag="button" className="btn btn-light btn-sm">
                <i className="uil uil-ellipsis-h"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                  { !user.IsDeleted && <DropdownItem onClick={() => setDeleteModal(user)}>
                  <i className="mdi mdi-delete font-size-16 me-1"></i>
                    Delete Template
                  </DropdownItem>}
                  { user.IsDeleted && <DropdownItem onClick={() => reactivateTemplate(user)}>
                  <i className="mdi mdi-replay font-size-16 me-1"></i>
                    Re-Activate Template
                  </DropdownItem>}
                 
                  {!user.IsDeleted && <DropdownItem onClick={() => {
                    setEditTemplateModal(user)
                    }}>
                    <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                    Edit
                  </DropdownItem>} 
                  { (!user.IsDeleted && user.Status !== "Approved")&& <DropdownItem onClick={() => setApproveModal(user.Id)}>
                  <i className="mdi mdi-check-decagram font-size-16 me-1"></i>
                    Approve Template
                  </DropdownItem>}
              </DropdownMenu>
            </UncontrolledDropdown>
          </React.Fragment>
        );
      },
    },
  ];
  const defaultSorted: any = [
    {
      dataField: "id",
      order: "asc",
    },
  ];
  const selectRow: any = {
    mode: "checkbox",
  };
  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
  };
  const { SearchBar } = Search;
  console.log("About to get Templates");

  const { data, refetch } = useQuery(GET_TEMPLATES, {
    fetchPolicy: "no-cache",
  });
  
  console.log("Templates call in progress");


  const handleDelete = async (user: any) => {
    setDeleteModal(false)
    await deleteTemplate({
      variables: {
        id: user.Id,
        userId: localStorage.getItem("user_id"),
        time: new Date(Date.now()).toISOString()
      },
    })
    setsuccess_dlg(true)
    setdynamic_description("Item will not be available to the user now, it will be permanently deleted in 60 days")
    refetch();
  };

  useEffect(() => {
    console.log("Data has changed");
    if (data && data.msgbox_Template) {
      console.log("We have some templates");
      var tmpData = data.msgbox_Template.filter((item: any) => item.Organisation).map(function (item: {
        TemplateId: any;
        CreatedOn: string | number | Date;
        Name: any;
        IsDeleted :any;
        Status: any;
        RejectionReason : any;
        Organisation: { Name: any, OrganisationId: any };
        aPIAccountByApiaccount: { APIAccountId: any, Inboxes: { Name: any }[] };
        LanguageCode: any;
        ButtonMetadata: any;
        TemplateText: any;
        TemplateExample: any;
        AttachmentId: any;
        Attachment: any;
        TemplateType: {
          TemplateTypeId: any,
          Description: any
        };
        OrganisationId: any;
        TemplateCategory: {TemplateCategoryId: any};
      }) {
        return {
          Id: item.TemplateId,
          CreatedOn: new Date(item.CreatedOn).toLocaleDateString(),
          Name: item.Name,
          TemplateCategoryId: item?.TemplateCategory?.TemplateCategoryId,
          IsDeleted :item.IsDeleted,
          Status: item.Status,
          TemplateType:item.TemplateType,
          RejectionReason : item.RejectionReason,
          OrgName: item?.Organisation?.Name || "",
          OrgId: item.OrganisationId,
          ButtonMetadata: item.ButtonMetadata,
          TemplateText: item.TemplateText,
          TemplateExample: item.TemplateExample,
          Attachment: item.Attachment,
          AttachmentId: item.AttachmentId,
          LanguageCode: item.LanguageCode,
          InboxName:
            item?.aPIAccountByApiaccount?.Inboxes?.[0]?.Name ||
            "Error: No Inbox Found",
          APIAccountId: item?.aPIAccountByApiaccount?.APIAccountId || ""
        };
      });
      console.log(data)
      console.log("Setting TableData");
      setTableData(tmpData);
    }
  }, [data]);

  const handleUserClicks = () => {
    setModal(!modal);
  };
  const refetchData = () => {
    refetch();
  };

  async function reactivateTemplate (user: any) {
    await unDeleteTemplate({
      variables: {
        id: user.Id,
      },
    })
    setsuccess_dlg(true)
    setdynamic_description("Item has been reactivated")
    refetch();
  }
  
  console.log("About to render some html");
  return (
    <React.Fragment>
            {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
            <DeleteModal isOpen={deleteModal !== false} cancel={()=> setDeleteModal(false)} submit={() => handleDelete(deleteModal)}/>

      <Card>
        <CardHeader>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <h4 className="card-title">All Templates</h4>
              </div>
            </Col>
            <Col md={6}>
              <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                <div>
                  <Link to="#" className="btn btn-light" onClick={() => refetchData()}>
                    <i className="uil uil-refresh me-1"></i> Refresh
                  </Link>
                </div>
                <div>
                  <Link
                    to="#"
                    className="btn btn-light"
                    onClick={() => {
                      setCustomTemplateModal(true)
                    }}
                  >
                    <i className="uil uil-plus me-1"></i> Add Custom Template
                  </Link>
                </div>
                <div>
                  <Link
                    to="#"
                    className="btn btn-light"
                    onClick={handleUserClicks}
                  >
                    <i className="uil uil-plus me-1"></i> Add Prebuilt Templates
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <PaginationProvider pagination={paginationFactory(pageOptions)}>
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="Id"
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            // responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            selectRow={selectRow}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                          <AddPrebuiltTemplate 
                            onRefetch={refetchData}
                            modal={modal} 
                            setModal={setModal} 
                          />
                           <AddTemplate 
                            onRefetch={refetchData}
                            modal={customTemplateModal} 
                            setModal={setCustomTemplateModal} 
                          />
                           <EditTemplate 
                            onRefetch={refetchData}
                            modal={editTemplateModal !== false} 
                            setModal={setEditTemplateModal} 
                            data={editTemplateModal}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>


      <Modal
        isOpen={approveModal !== false}
        toggle={() => setApproveModal(false)}
        size="xs"
      >
        <ModalHeader  tag="h5">
        Are you sure you want to approve this template, if its not approved in GupShup then all messages will fail to send?
        </ModalHeader>
        <ModalBody className="modelStyle">
            <Row>
              <Col>
                <div className="text-end"> 

                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={() => setApproveModal(false)}
                  >
                    Cancel
                  </button>
                
                  <button onClick={async () => {
                      await approveTemplate({
                        variables: {
                          Id: approveModal,
                        },
                      })
                      setsuccess_dlg(true)
                      setApproveModal(false)
                      setdynamic_description("Template Approved")
                      refetch();
                  }} className="btn btn-success save-user">
                    Approve
                  </button>
                </div>
              </Col>
            </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Templates;
