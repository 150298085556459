import { gql } from "@apollo/client";

export const MUTATE_EmailParserOutgoingEmail = gql`
mutation MyMutation($_eq: Int!, $defaultEmail: Boolean!, $emailAddress: String!, $matchExpression: String!) {
  update_msgbox_EmailParserOutgoingEmail(where: {EmailParserOutgoingEmailId: {_eq: $_eq}}, _set: {RegEx: $matchExpression, EmailAddress: $emailAddress, IsDefault: $defaultEmail}) {
    affected_rows
  }
}
`

export const GET_DEFAULTS = gql`
query MyQuery ($apiAccountId: Int!)  {
  msgbox_EmailParserOutgoingEmail(where: {APIAccountId: {_eq: $apiAccountId}, IsDefault:{_eq: true}}) {
    IsDefault
  }
}`;