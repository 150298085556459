import React, { useContext } from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Col, Container, Row } from "reactstrap";
import Messages from "./Messages";
import RecentFailedMessages from "./RecentFailedMessages";
import { ThemeContext } from "src/helpers/themeContext";

const MessagesPage = () => {
    const Theme = useContext(ThemeContext)
    return (
        
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Inboxes | {Theme.name} messages</title>
                </MetaTags>

                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Home" breadcrumbItem="Messages" />

                    <Row>
                        <Col lg={12}>
                            <Messages />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <RecentFailedMessages />
                        </Col>
                    </Row>
                    
                </Container>
            </div>
        </React.Fragment>
    );
}

export default MessagesPage;