import React, { Dispatch, SetStateAction, useState, useEffect,useContext } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation, useQuery } from "@apollo/client";
import SweetAlert from "react-bootstrap-sweetalert";
import { GET_WIDGET_DETAILS } from "./getWidgetDetails.query";
import { Edit_WIDGET_DETAILS } from "./editWidgetDetails.query";
import { ThemeContext } from "src/helpers/themeContext";




interface IProps {
    editWidgetModel: boolean;
    setEditWidgetModel: Dispatch<SetStateAction<boolean>>;
    PassedUserId : any;  
    onRefetch: any; 
  }

const EditWidget = (props: IProps)=>{

  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);
  const Theme = useContext(ThemeContext);

  const [name, setName] = useState('');
  const [welcometext, setWelcomeText] = useState('');
  const [size, setSize] = useState('');
  


  const {data}= useQuery(GET_WIDGET_DETAILS , {
    fetchPolicy: "no-cache",
    variables: {WidgetId:props.PassedUserId}
  });

  useEffect(() => {
    if(data && data.msgbox_Widget && data.msgbox_Widget?.length){
        console.log("Data from Inside:", data);
        setName(data.msgbox_Widget[0].Name);
        setWelcomeText(data.msgbox_Widget[0].WelcomeText);
        setSize(data.msgbox_Widget[0].Size);
    }
  },[data])

  const handleShow = () => {
      props.setEditWidgetModel(false);
  };
  const toggle = () => {
      props.setEditWidgetModel(!props.editWidgetModel);
  };

  const handleModalOpen = () => { };

  const [updateWidgetDetails] = useMutation(Edit_WIDGET_DETAILS);

  const handleSubmit =  (values: any) => {
   // console.log(values);
    updateWidgetDetails({
          variables :{
            WidgetId:props.PassedUserId,
            Name:values.Name,
            Size:values.Size ,
            WelcomeText:values.WelcomeText      
          }
        })
        .then((response)=>{
          console.log("Data Updated successfully");
          setdynamic_description("Data Updated successfully");
          setsuccess_dlg(true);
          toggle();        
        })
        .catch((error)=>{
          console.log(error.message);
          setdynamic_description("Failed to Update the data");
          setfailure_dlg(true);
          toggle();
        })     
     }

    return(
    <React.Fragment>
        {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
            props.onRefetch();
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}

      {failure_dlg ? (
        <SweetAlert
          error
          title={Theme.name}
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      
     <Modal
             isOpen={props.editWidgetModel}
             toggle={toggle}
             size="lg"
             onOpened={() => handleModalOpen()}
        >

         <ModalHeader toggle={toggle} tag="h4">
         Edit Widget Details
        </ModalHeader>

        <ModalBody className="modelStyle">
            <AvForm  
                onValidSubmit={(e: any, values: any) => {
                handleSubmit(values);                
             }}
              > 
                <Row form className="d-flex flex-row mb-3">
                    <Col>
                    <div className="mb-3">
                    <AvField
                        className="avInput"
                        name="Name"
                        label="Name"
                        type="Text"
                        placeholder="Edit Name"
                        errorMessage="Invalid URL"
                        validate={{
                            required : { value: true , errorMessage: "Text is require"},
                        }}
                        value={name}
                        onChange={(e:any) => setName(e.target.value)}
                    />
                    </div>
                    
                    <div className="mb-3">
                    <AvField
                        className="avInput"
                        name="WelcomeText"
                        label="Welcome Text"
                        type = "text"
                        placeholder="Edit WelcomeText"
                        validate={{
                            required: { value: true, errorMessage: "WelcomeText is require"},
                        
                        }}
                        value={welcometext}
                        onChange={(e:any) => setWelcomeText(e.target.value)}
                    />
                    </div>

                    <div className="mb-3">
                    <AvField
                        className="avInput"
                        name="Size"
                        label="Select Size"
                        type="select"
                        validate = {{
                            required:{value:true, errorMessage:"Size is require"}
                        }}
                        value={size}
                        onChange={(e:any) => setSize(e.target.value)}
                    >
                        <option key="normal" value="normal">Normal </option>
                        <option key="small" value="small">Small</option>
                    </AvField>
                    
                    </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-end">
                        <button
                            type="button"
                            className="btn btn-light w-sm"
                            onClick={handleShow}
                        >
                            Close
                        </button>
                        <button type="submit" className="btn btn-success save-user">
                            Save
                        </button>
                        </div>
                    </Col>
                </Row>
            </AvForm>
        </ModalBody>
        </Modal>

    </React.Fragment>
    );
};

export default EditWidget;