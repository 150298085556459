import React, {
  Dispatch,
  SetStateAction,
  useState,
  useContext,
  useEffect,
} from "react";
import { Row, Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import { ThemeContext } from "src/helpers/themeContext";
import { validate } from "graphql";
import { PromptProps } from "react-router-dom";
import ConfirmDeleteModal from "../ConfirmDeleteModal/ConfirmDeleteModal";

interface IProps {
  teamName: string;
  setTeamName: Dispatch<SetStateAction<string>>;
  handleSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  orgs: any;
  inboxes: object;
  selectedInboxes: object;
  setSelectedInboxes: Dispatch<SetStateAction<object>>;
  filteredUsers: object;
  selectedUser: string;
  setSelectedUser: Dispatch<SetStateAction<string>>;
  handleShow: () => void;
  submit: () => void;
  isEdit: boolean;
  existingTeam: any;
  handleTeamDelete: any;
}

const TeamForm = (props: IProps) => {
  const Theme = useContext(ThemeContext);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  function handleDeleteConfirmation() {
    props.handleTeamDelete;
  }

  const selectStyle = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: Theme.styles.primary, // primary
      border: `1px solid ${Theme.styles.primaryBold}`, //primary bold
      color: Theme.styles.textColour1, //textColor1
      borderColor: Theme.styles.primary,
    }),
    input: (provided: any) => ({
      ...provided,
      color: Theme.styles.textColour1, // Change the text color of the search input
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: Theme.styles.textColour1, //textColor1
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: Theme.styles.primary, // primary
      border: `1px solid ${Theme.styles.primaryBold}`, //primary bold
      color: Theme.styles.textColour1, //textColor1
    }),
    menuList: (provided: any) => ({
      ...provided,
      backgroundColor: Theme.styles.primary, // primary
      border: `1px solid ${Theme.styles.primaryBold}`, //primary bold
      color: Theme.styles.textColour1, //textColor1
    }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: isFocused ? Theme.styles.secondary : null,
        color: Theme.styles.textColour1, //textColor1
      };
    },
    multiValue: (styles: any) => {
      return {
        ...styles,
        backgroundColor: Theme.styles.secondary,
        color: "white",
      };
    },
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: Theme.styles.textColour1, //textColor1
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      color: Theme.styles.textColour1, //textColor1
      ":hover": {
        backgroundColor: Theme.styles.primary,
        color: "white",
      },
    }),
  };

  return (
    <AvForm>
      {confirmationModalOpen ? (
        <ConfirmDeleteModal
          handleTeamDelete={props.handleTeamDelete}
          setConfirmationModalOpen={setConfirmationModalOpen}
        />
      ) : null}
      <Row form className="d-flex flex-row mb-3">
        <Col>
          <div className="mb-3">
            <AvField
              className="avInput"
              name="teamname"
              label="Team Name"
              type="text"
              placeholder="Enter Team name"
              errorMessage="Invalid team name"
              validate={{
                required: { value: true },
              }}
              value={props.teamName}
              onChange={(e: any) => {
                props.setTeamName(e.target.value);
              }}
            />
          </div>

          <div className="mb-3">
            <AvField
              disabled={props.isEdit}
              className="avInput"
              name="organisation"
              label="Organisation"
              type="select"
              value={props?.existingTeam?.Organisation?.OrganisationId || ""}
              validate={{
                required: { value: true },
              }}
              onChange={props.handleSelectChange}
            >
              {!props?.existingTeam?.Organisation?.Name && (
                <option value="" disabled>
                  Select
                </option>
              )}
              {props.orgs.msgbox_Organisation.map((org: any, index: any) => (
                <option
                  key={index}
                  value={org.OrganisationId}
                  defaultValue={
                    props?.existingTeam?.Organisation?.OrganisationId ===
                    org.OrganisationId
                      ? props?.existingTeam?.Organisation?.OrganisationId
                      : org.Name
                  }
                >
                  {org.Name}
                </option>
              ))}
            </AvField>
          </div>

          <div className="mb-3">
            <label>Select Inboxes</label>
            <Select
              isMulti
              isClearable={true}
              name="inbox"
              placeholder="Select"
              options={props.inboxes}
              value={props.selectedInboxes}
              onChange={(e: any) => props.setSelectedInboxes(e)}
              styles={selectStyle}
            ></Select>
          </div>

          <div className="mb-3">
            <label>Select Users</label>
            <Select
              isMulti
              placeholder="Select"
              options={props.filteredUsers}
              value={props.selectedUser}
              onChange={(e: any) => {
                props.setSelectedUser(e);
              }}
              styles={selectStyle}
            ></Select>
          </div>
        </Col>
      </Row>
      <Row>
        {props.isEdit ? (
          <Col>
            <div className="text-start">
              <button
                type="button"
                className="btn btn-danger w-sm"
                onClick={() => {
                  setConfirmationModalOpen(true);
                }}
              >
                Delete
              </button>
            </div>
          </Col>
        ) : null}
        <Col>
          <div className="text-end">
            <button
              type="button"
              className="btn btn-light w-sm"
              onClick={props.handleShow}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn  btn-success save-user"
              onClick={() => props.submit()}
            >
              Save
            </button>
          </div>
        </Col>
      </Row>
    </AvForm>
  );
};

export default TeamForm;
