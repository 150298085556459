import { gql } from "@apollo/client";

export const GET_TEAMS = gql`
query MyQuery {
  msgbox_Department(where: {IsDeleted: {_eq: false}}) {
    DepartmentId
    Name
    Organisation {
      Name
    }
  }
}
`

export const GET_TEAM = gql`
query MyQuery ($id: Int) 
{
  msgbox_Department
  (where: {DepartmentId: {_eq: $id}}
  ) 
  {
    Name
    DepartmentInboxes  {
      Inbox {
        InboxId
        Name
      }
    }
    Organisation {
      OrganisationId
      Name
    }
    UserDepartments {
      User {
        FirstName
        LastName
        UserId
      }
    }
  }
}
`