import logo from './images/logo.png'
import authBackground from './images/authBackground.jpg'
import icon from './images/Icon.png'
import favIcon from './images/favIcon.png'

export const WhatEx = {
    name: "whatex", 
    styles: {
        primary:'#ffffff',
        secondary: '#eafbec',
        primaryBold: '#043a5a',
        textColour1: '#000000'   
     },
    images: {
        logo,
        authBackground, 
        icon,
        favIcon
    }
}
