import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useQuery } from "@apollo/client";
import { MESSAGES_SENT_DATA } from "./messageActivity.query";
import convertTimestampToDate from "src/helpers/convertTimestampToDate";


const MessageApexbar = () => {

    const [templateGroup, setTemplateGroup] =  useState<any>([]);
    const [normalGroup, setNormalGroup] =  useState<any>([]);

    const { data } = useQuery(MESSAGES_SENT_DATA, {
        fetchPolicy: "no-cache",
        variables: {
            end: convertTimestampToDate(new Date()),
            start: convertTimestampToDate(new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1))
        }
    })
    
    const today = new Date();
    const sixMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 6, 1);
    const months:any = [];
    let currentMonth = new Date(sixMonthsAgo);
    while (currentMonth <= today) {
        months.push(new Date(currentMonth).toLocaleString('en-GB', { month: 'short' }));
        currentMonth.setMonth(currentMonth.getMonth() + 1);
    }
 

    // Template data
    useEffect(() => {
        if(data){               
            const groupedData:any = {};
            const groupedTemplateData: any = {}

            data.reporting_MessagesInboundByReseller.forEach((item: any) => {
                const month = new Date(item.Date).toLocaleString('en-GB', { month: 'short' });
                if (!groupedData[month]) {
                  groupedData[month] = item.Count;
                } else {
                  groupedData[month] = groupedData[month] + item.Count
                }
              });
            data.reporting_MessagesOutboundByReseller.forEach((item: any) => {
                const month = new Date(item.Date).toLocaleString('en-GB', { month: 'short' });
                if (!groupedData[month]) {
                  groupedData[month] = item.Count;
                } else {
                  groupedData[month] = groupedData[month] + item.Count
                }
             });

            data.reporting_TemplatesSentByReseller.forEach((item: any) => {
                const month = new Date(item.Date).toLocaleString('en-GB', { month: 'short' });
                if (!groupedTemplateData[month]) {
                    groupedTemplateData[month] = item.Count;
                } else {
                    groupedTemplateData[month] = groupedTemplateData[month] + item.Count
                }
             });
            const finalTemplateData = months.map((month:any) => ({ month, count: groupedTemplateData[month] || 0 }));

            finalTemplateData.forEach((item: any) => {
                if(groupedData[item.month] && item.count) groupedData[item.month] -= item.count
            })
        
            const finalData = months.map((month:any) => ({ month, count: groupedData[month] || 0 }));

            setTemplateGroup(finalTemplateData)
            setNormalGroup(finalData);
        }   
    }, [data])
    
    // Session Data 
    // useEffect(() => {
    //     if(normalData){               
    //         const filteredData = normalData.msgbox_Message.filter((item:any) => {
    //             const itemDate = new Date(item.CreatedOn);
    //             return itemDate >= sixMonthsAgo && itemDate <= today;
    //           }); 

    //         const groupedData:any = {};
    //         filteredData.forEach((item: any) => {
    //             const month = new Date(item.CreatedOn).toLocaleString('en-GB', { month: 'short' });
    //             if (!groupedData[month]) {
    //               groupedData[month] = 1;
    //             } else {
    //               groupedData[month]++;
    //             }
    //           });

    //         const finalData = months.map((month:any) => ({ month, count: groupedData[month] || 0 }));
    //         setNormalGroup(finalData);
    //     }   
    // }, [normalData])
   
    
    const series = [ {
        name: "Template",
        data: templateGroup.map((item:any) => item.count)
    },
    {
        name: "Session",
        data: normalGroup.map((item:any) => item.count)
    }];
    
    const countsByMonth = templateGroup.reduce((acc: any, {month, count}: any) => ({...acc, [month]: (acc[month] || 0) + count}), {});
    const roundedMax = Math.ceil(Math.max(...normalGroup.map(({month, count}: any) => count + (countsByMonth[month] || 0))) / 100) * 100;

    const options = {
        chart: {
            stacked: !0,
            toolbar: {
                show: !1,
            }
        },
        plotOptions: {
            bar: {
                horizontal: !1,
                columnWidth: "23%",
                borderRadius: 4,
            },
        },
        dataLabels: {
            enabled: !1,
        },
        legend: {
            position: "top",
            horizontalAlign: "right",
            markers: {
                width: 8,
                height: 8,
                radius: 30,
            }
        },
        stroke: {
            show: !0,
            width: 2,
            colors: ["transparent"]
        },
        colors: ["#c0e3f6", "#038edc"],
        xaxis: {
            categories: templateGroup.map((item:any) => item.month),
            axisTicks: {
                show: !1,
            },
        },
        yaxis: {
            labels: {
                formatter: function (value: any) {
                    return value;
                }
            },
            tickAmount: 2,
            min: 0,

            max: roundedMax
        },
        grid: {
            strokeDashArray: 10
        },
        fill: {
            opacity: 1
        }
    };
    return (
        <React.Fragment>
            <ReactApexChart options={options} series={series} type="bar" height={200} className="apex-charts" />
        </React.Fragment>
    );
};

export default MessageApexbar;