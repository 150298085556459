import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query MyQuery {
    msgbox_User(order_by: { UserId: desc }) {
      UserId
      OrganisationId
      userorganisation {
        Name
      }
      FirstName
      LastName
      EMail
      CreatedAt
      IsBot
      IsAPI
      IsDeleted
      Auth0Id
      UserInboxes {
        Inbox {
          InboxId
          Name
        }
      }
      UserRoles {
  			Role {
          Description 
          RoleId
        }
      }
    }
  }
`;
