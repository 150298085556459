import { gql } from "@apollo/client";

export const REMOVE_USER_DEPARTMENTS = gql`
  mutation MyMutation($userId: Int, $departmentId: Int) {
    delete_msgbox_UserDepartment(
      where: { UserId: { _eq: $userId }, DepartmentId: { _eq: $departmentId } }
    ) {
      returning {
        DepartmentId
      }
      affected_rows
    }
  }
`;

export const REMOVE_DEPARTMENT_INBOX = gql`
  mutation MyMutation($inboxId: Int, $departmentId: Int) {
    delete_msgbox_DepartmentInbox(
      where: {
        InboxId: { _eq: $inboxId }
        DepartmentId: { _eq: $departmentId }
      }
    ) {
      returning {
        DepartmentId
      }
      affected_rows
    }
  }
`;

export const UPDATE_DEPARTMENT_DETAILS = gql`
  mutation MyMutation($departmentId: Int, $departmentName: String) {
    update_msgbox_Department(
      where: { DepartmentId: { _eq: $departmentId } }
      _set: { Name: $departmentName }
    ) {
      affected_rows
      returning {
        DepartmentId
      }
    }
  }
`;

export const DELETE_DEPARTMENT = gql`
  mutation MyMutation($departmentId: Int, $departmentName: String) {
    update_msgbox_Department(
      where: {
        DepartmentId: { _eq: $departmentId }
        Name: { _eq: $departmentName }
      }
      _set: { IsDeleted: true }
    ) {
      affected_rows
      returning {
        DepartmentId
      }
    }
  }
`;
