import React, { useContext } from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Col, Container, Row } from "reactstrap";
import { ThemeContext } from "src/helpers/themeContext";
import Teams from "./Teams";

const TeamsPage = () => {
  const Theme = useContext(ThemeContext);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Teams | {Theme.name} Teams</title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="Teams" />

          <Row>
            <Col lg={12}>
              <Teams />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TeamsPage;
