async function UploadFile (file: any, orgId: any) {
    try {
        const { name, type } = file;

        const tokenString = localStorage.getItem("access_token");
        const token = tokenString ? JSON.parse(tokenString) : null;
        console.log(token, type, file)
        const signedUrlResponse = await fetch(
            `${process.env.REACT_APP_API_URL}filessignedurl`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": type,
                },
                body: JSON.stringify({orgId: orgId})
            }
        );
        const { attachmentId, presignedUrl } = await signedUrlResponse.json();

        const attachmentResponse = await fetch(presignedUrl, {
            method: "PUT",
            headers: {
                "Content-Disposition": `filename=${encodeURIComponent(name)}`,
                "Content-Type": type,
            },
            body: file,
        });

        if (
            attachmentResponse.status === 200 ||
            attachmentResponse.status === 201
        ) {
            return attachmentId;
        } else {
            console.error("failed:", attachmentResponse);
            return false
        }
    } catch (e) {
        console.log("error", e);
        return false
    }
}

export default UploadFile