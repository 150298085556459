import React, { Dispatch, SetStateAction, useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  GET_TEMPLATES,
  GET_PARSERTYPES,
  MUTATE_EMAILPARSERS
} from "./query";
import { ThemeContext } from "src/helpers/themeContext";
import client from "../../apollo";
import { toNumber } from "lodash";

interface IProps {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  data: any;
  refresh: any
}

const EditEmailParser = (props: IProps) => {

  const handleShow = () => {
    props.setModal(false);
  };
  const toggle = () => {
    props.setModal(!props.modal);
  };
  const Theme = useContext(ThemeContext)
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(-1);
  const [selectedParser, setSelectedParser] = useState(-1);
  const [parsers, setParsers] = useState<any>([]);
  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);
  const [defaultForwardingValue, setDefaultForwardingValue] = useState(1)

  useEffect(() => {
    if(!props.data.APIAccountId) return
    loadParserTypes()
    loadTemplates(props.data.APIAccountId)
    setSelectedTemplate(props.data.templateId)
    getForwardingValue(props.data)
  }, [props])

  const getForwardingValue = (data: any) => {
    let whenToForward = 1
    if(data.DoNotForwardIfSentOK) whenToForward = 2
    if(data.DoNotForwardIfDeliveredOK) whenToForward = 3
    if(data.DoNotForwardIfReadOK) whenToForward = 4
    if(data.DoNotForwardIfRepliedTo) whenToForward = 5
    setDefaultForwardingValue(whenToForward)
  }

  const loadParserTypes = async () => {
    const orgs = await client.query({
      query: GET_PARSERTYPES,
      fetchPolicy: "no-cache"
    });
    const parserTypes = orgs?.data?.msgbox_EmailParserType || [];
    setParsers(parserTypes);
    for(let i = 0; i < parserTypes.length; i++){
      if(parserTypes[i].Name === props.data.ParserType) setSelectedParser(i)
    }
  };

  const loadTemplates = async (apiAccountId: number) => {
    const templates = await client.query({
      query: GET_TEMPLATES,
      variables: {
        apiAccountId: apiAccountId,
      },
      fetchPolicy: "no-cache"
    });
    const templateData = templates?.data?.msgbox_Template || [];
    setTemplates(templateData);
    setSelectedTemplate(props.data.templateId)
  };

  const handleTemplateSelect = async (target: number) => {
    setSelectedTemplate(target);
  };

  const handleParserTypeSelect = async (target: any) => {
    setSelectedParser(target.selectedIndex);
  };

  const handleEditEmailParserSubmit = async (values: any) => {
    let obj = {_eq: props.data.Id,
      ForwardingEmail: values.forwardEmailAddress,
      ParserType: parsers[selectedParser].Name,
      TemplateId: selectedTemplate,
      DoNotForwardIfDeliveredOK: false,
      DoNotForwardIfRepliedTo: false,
      DoNotForwardIfSentOK: false,
      DoNotForwardIfReadOK: false,
    }
    if(values.forwardOption == 2) obj.DoNotForwardIfSentOK = true
    if(values.forwardOption == 3) obj.DoNotForwardIfDeliveredOK = true
    if(values.forwardOption == 4) obj.DoNotForwardIfReadOK = true
    if(values.forwardOption == 5) obj.DoNotForwardIfRepliedTo = true
    if(obj.ForwardingEmail === '') obj.ForwardingEmail = null

    let failed = false
    await client.mutate({
      mutation: MUTATE_EMAILPARSERS,
      variables: obj,
      fetchPolicy: "no-cache"
    }).catch(err => {
      console.log("error:",err)
      failed = true
    });
    if(failed){
      setdynamic_description("Failed to Edit Email Parser");
      setfailure_dlg(true);
    } else {
      setdynamic_description("Email Parser Edit successfully");
      setsuccess_dlg(true);
      props.refresh()
    }
    toggle();
  };

  return (
    <React.Fragment>
      {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      {failure_dlg ? (
        <SweetAlert
          error
          title={Theme.name}
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}     
      <Modal
        isOpen={props.modal}
        toggle={toggle}
        size="lg"
      >
        <ModalHeader toggle={toggle} tag="h4">
          Edit Email Parser
        </ModalHeader>
        <ModalBody className="modelStyle">
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleEditEmailParserSubmit(values);
            }}
          >
            <Row form className="d-flex flex-row mb-3">
              <Col>
                  <FormGroup className="mb-3">
                    <Label>Select Template</Label>
                    <Input
                      className="avInput"
                      id="template"
                      name="template"
                      type="select"
                      value={selectedTemplate}
                      onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        handleTemplateSelect(toNumber(e.currentTarget.value))
                      }
                    >
                      {Array.isArray(templates) && templates.length > 0 ? (
                        templates.map((order: any, key: number) => (
                          <option key={key} value={order["TemplateId"]}>
                            {order["Name"]}
                          </option>
                        ))
                      ) : (
                        <p>No Templates Found</p>
                      )}
                    </Input>
                  </FormGroup>
                

                  <FormGroup className="mb-3">
                    <Label>Select Parser Type</Label>
                    <Input
                      className="avInput"
                      id="parserType"
                      name="parserType"
                      type="select"
                      value={selectedParser}
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        handleParserTypeSelect(e.currentTarget);
                      }}
                    >
                      {Array.isArray(parsers) && parsers.length > 0 ? (
                        parsers.map((order: any, key: number) => (
                          <option key={key} value={key}>
                            {order["Name"]}
                          </option>
                        ))
                      ) : (
                        <p>No Parser Types Found</p>
                      )}
                    </Input>
                  </FormGroup>
                

                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="forwardEmailAddress"
                    label="Forwarding Email Address"
                    type="email"
                    placeholder="Forwarding email address"
                    errorMessage="Invalid email address"
                    validate={{
                      required: { value: false }
                    }}
                    value={props.data.ForwardingEmail}
                  />
                </div>
                <div className="mb-3">
                  <AvField   className="avInput" type="select" id="forwardOption" value={defaultForwardingValue} name="forwardOption" label="Forwarding Option">
                    <option value="1">Always forward</option>
                    <option value="2">Dont forward if successfully sent</option>
                    <option value="3">Dont forward if delivered</option>
                    <option value="4">Dont forward if customer read</option>
                    <option value="5">Dont forward if customer replied</option>
                  </AvField>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={handleShow}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default EditEmailParser;
