import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import EditLocationWidget from "src/components/EditLocationWidget/EditLocationWidget";
import DeleteLocation from "src/components/DeleteLocation/DeleteLocation";



const ExpandRowRenderer = (props :any) => {

    const {found, row, handleLocationWidget } = props

    const [editLocationModel, setEditLocationModel] = useState(false);
    const [PassedUser, setPassedUser] = useState(null);
    const [deleteModel, setDeleteModel]= useState(false);

    const columns =[
      {
        dataField: "LocationId",
        text: "Id",
        sort: true,      
      },
      {
        dataField: "Text",
        text: "Text",
        sort: true,
        hidden: false,       
      }, 
      {
        dataField: "Url",
        text: "URL",
        sort: true,
        hidden: false,
      },
      {
        dataField: "menu",
        isDummyField: true,
        editable: false,
        text: "Action",
         // eslint-disable-next-line react/display-name
        formatter: (cellContent: any, user: any) => (
          <React.Fragment>
            <UncontrolledDropdown>
              <DropdownToggle tag="button" className="btn btn-light btn-sm">
                <i className="uil uil-ellipsis-h"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={()=>handleEdit(user)}>
                  <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                  Edit
                </DropdownItem>
                <DropdownItem onClick={()=> handleDelete(user)}>
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1"></i>
                  Delete
                </DropdownItem>
             </DropdownMenu>
            </UncontrolledDropdown>
          </React.Fragment>
        ),
      }
    ];

    

    const defaultSorted: any = [
      {
        dataField: "LocationId",
        order: "asc",
      },
    ];

    //handle Delete
    const toggleDelete =()=>{
      setDeleteModel(!deleteModel);
    }
    const handleDelete =( user:any)=>{
      toggleDelete();
      setPassedUser(user);
    }
    //Handle Edit Location
    const toggle=() => {
      setEditLocationModel(!editLocationModel);
    };
    const handleEdit = (user:any)=>{
      toggle();
      setPassedUser(user);
    }

   
    return(
      
          <React.Fragment>
              <Card>
                <CardHeader>
                    <Row>
                      <Col md={6}>
                      </Col>
                      <Col md={6}>
                      <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                          <div>
                              <Link
                                to="#"
                                className="btn btn-light"  
                                onClick={() =>handleLocationWidget(row)}                         
                              >
                                <i className="uil uil-plus me-1"></i> Add New
                              </Link>
                          </div>                  
                      </div>
                      </Col>
                    </Row>
                </CardHeader>

                <CardBody>                 
                  <BootstrapTable
                    keyField="LocationId"
                    data={found}
                    columns ={columns}
                    defaultSorted ={defaultSorted}
                   
                  />
                  <EditLocationWidget
                      editLocationModel = {editLocationModel}
                      setEditLocationModel = {setEditLocationModel}
                      PassedUser = {PassedUser}
                      onRefetch ={props.onRefetch}
                  />
                  <DeleteLocation
                    deleteModel={deleteModel}
                    setDeleteModel={setDeleteModel}
                    PassedUser={PassedUser}
                    onRefetch ={props.onRefetch}
                  />
                </CardBody>
         </Card>
        </React.Fragment>      
    );
};

export default ExpandRowRenderer;