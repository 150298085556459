import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { CONTACT_DATA } from "./contactData.query.js.js";
import { useQuery } from "@apollo/client";
import convertTimestampToDate from "src/helpers/convertTimestampToDate";

const ContactApexBar = () => {

    const [groupedData, setGroupedData] =  useState<string[]>([]);
    const{data} = useQuery(CONTACT_DATA, {
        fetchPolicy: "no-cache", 
        variables: {
            end: convertTimestampToDate(new Date()),
            start: convertTimestampToDate(new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1))
        }
    })

    const today = new Date();
    const sixMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 6, 1);
    const months:any = [];
    let currentMonth = new Date(sixMonthsAgo);
    while (currentMonth <= today) {
        months.push(new Date(currentMonth).toLocaleString('en-GB', { month: 'short' }));
        currentMonth.setMonth(currentMonth.getMonth() + 1);
    }

    useEffect(() => {
        if(data){               
            const groupedData:any = {};
            data.reporting_ContactsCreatedByReseller.forEach((item: any) => {
                const month = new Date(item.Date).toLocaleString('en-GB', { month: 'short' });
                if (!groupedData[month]) {
                  groupedData[month] = item.Count;
                } else {
                  groupedData[month] = groupedData[month] + item.Count 
                }
              });

            const finalData = months.map((month:any) => ({ month, count: groupedData[month] || 0 }));
            setGroupedData(finalData);
        }   
    }, [data])
   

    const roundedMax = Math.ceil(Math.max(...groupedData.map(({month, count}: any) => count)) / 100) * 100;

    const series = [ {
        name: "This Month",
        data: groupedData.map((item:any) => item.count )
    }];

    const options = {
        chart: {
            stacked: !0,
            toolbar: {
                show: !1,
            }
        },
        plotOptions: {
            bar: {
                horizontal: !1,
                columnWidth: "23%",
                borderRadius: 4,
            },
        },
        dataLabels: {
            enabled: !1,
        },
        legend: {
            position: "top",
            horizontalAlign: "right",
            markers: {
                width: 8,
                height: 8,
                radius: 30,
            }
        },
        stroke: {
            show: !0,
            width: 2,
            colors: ["transparent"]
        },
        colors: ["#c0e3f6", "#038edc"],
        xaxis: {
            categories:groupedData.map((item:any) => item.month),

            axisTicks: {
                show: !1,
            },
        },
        yaxis: {
            labels: {
                formatter: function (value: any) {
                    return value;
                }
            },
            tickAmount: 2,
            min: 0,
            max: roundedMax
        },
        grid: {
            strokeDashArray: 10
        },
        fill: {
            opacity: 1
        }
    };
    return (
        <React.Fragment>
            <ReactApexChart options={options} series={series} type="bar" height={200} className="apex-charts" />
        </React.Fragment>
    );
};

export default ContactApexBar;