import { gql } from "@apollo/client";

export const GET_API_PROVIDER = gql`
  query MyQuery {
    msgbox_APIProvider(where: { Name: { _nlike: "gupshup" } }) {
      APIProviderId
    }
  }
`;

export const GET_USER_ORGADMIN_ROLE = gql`
  query MyQuery {
    msgbox_Role(where: { Name: { _eq: "OrgAdmin" } }) {
      RoleId
    }
  }
`;

export const GET_ORGS = gql`
  query MyQuery {
    msgbox_Organisation(where: { IsDeleted: { _eq: false } }) {
      Name
      OrganisationId
    }
  }
`;

export const GET_INBOXES = gql`
  query MyQuery($orgId: Int) {
    msgbox_Inbox(where: { OrganisationId: { _eq: $orgId } }) {
      InboxId
      Name
    }
  }
`;

export const GET_USERS = gql`
  query MyQuery($orgId: Int) {
    msgbox_User(
      where: { OrganisationId: { _eq: $orgId }, IsDeleted: { _eq: false } }
      order_by: { LastName: asc }
    ) {
      UserId
      FirstName
      LastName
    }
  }
`;

export const ADD_TEAM = gql`
  mutation MyMutation($name: String!, $selectedOrganisationId: Int!) {
    insert_msgbox_Department_one(
      object: { Name: $name, OrganisationId: $selectedOrganisationId }
    ) {
      Name
      DepartmentId
    }
  }
`;

export const ADD_INBOXES = gql`
  mutation addInboxes($inbox: Int, $department: Int, $orgId: Int) {
    insert_msgbox_DepartmentInbox_one(
      object: {
        InboxId: $inbox
        DepartmentId: $department
        OrganisationId: $orgId
      }
    ) {
      DepartmentId
    }
  }
`;

export const INSERT_USER_DEPARTMENTS = gql`
  mutation insertUserDepts($departmentId: Int, $userId: Int, $orgId: Int) {
    insert_msgbox_UserDepartment(
      objects: {
        DepartmentId: $departmentId
        UserId: $userId
        OrganisationId: $orgId
      }
    ) {
      affected_rows
      returning {
        DepartmentId
        UserId
      }
    }
  }
`;

export const DELETE_USER_FROM_TEAM = gql`
  mutation MyMutation($userId: Int, $teamId: Int) {
    delete_msgbox_UserDepartment(
      where: { UserId: { _eq: $userId }, DepartmentId: { _eq: $teamId } }
    ) {
      returning {
        DepartmentId
      }
      affected_rows
    }
  }
`;
