import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { useQuery } from "@apollo/client";
import { GET_USERS } from "./inboxes.query";
import { GET_LOCATION } from "./location.query";


import AddWidget from "src/components/AddWidget/AddWidget";
import AddexpandRowWidget from "src/components/AddWidget/AddexpandRowWidget";
import ExpandRowRenderer from "./ExpandRowRenderer";
import EditWidget from "src/components/EditWidget/EditWidget";


const Widgets = () => {
  const [tableData, setTableData] = useState([]);
  const [modal, setModal] = useState(false);
  const [locationModel, setLocationModel] = useState(false);
  const [editWidgetModel, setEditWidgetModel] = useState(false);
  const [PassedUserId, setPassedUserId] = useState(null);
  const [Id, setId] = useState(0);



  const columns = [
    {
      dataField: "Id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "PublicId",
      text: "Public Id",
      sort: true,
      hidden: false,
    },
    {
      dataField: "Name",
      text: "Name",
      sort: true,
      hidden: false,
    },
    {
      dataField: "WelcomeText",
      text: "Text",
      sort: true,
      hidden: false,
    },
    {
      dataField: "Brand",
      text: "Brand",
      sort: true,
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, user: any) => (
        <React.Fragment>
          <UncontrolledDropdown>
            <DropdownToggle tag="button" className="btn btn-light btn-sm">
              <i className="uil uil-ellipsis-h"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem onClick={() => handleEditClicked(user)}>
                <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                Edit
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </React.Fragment>
      ),
    },
  ];

  const defaultSorted: any = [
    {
      dataField: "id",
      order: "asc",
    },
  ];
  const selectRow: any = {
    mode: "checkbox"
  };
  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
  };
  const { SearchBar } = Search;

  const { data, refetch } = useQuery(GET_USERS, { fetchPolicy: "no-cache" });

  useEffect(() => {
    if (data && data.msgbox_Widget) {
      var tmpData = data.msgbox_Widget.map(function (item: {
        WidgetId: number;
        PublicId: string;
        Name: string;
        WelcomeText: string;
        WidgetBrand: { Name: string };
      }) {
        return {
          Id: item.WidgetId,
          PublicId: item.PublicId,
          Name: item.Name,
          WelcomeText: item.WelcomeText,
          Brand: item.WidgetBrand.Name,
        };
      });

      setTableData(tmpData);
    }
  }, [data]);

// Handle Add new widget
  const toggleAddWidgetModal = () => {
    setModal(!modal);
  };
  const handleUserClicks = () => {
    toggleAddWidgetModal();
  };

  // Handle Location Widget
  const toggleAddLocationWidget = ()=>{
    setLocationModel(!locationModel);
  };
  const handleLocationWidget=(row : any)=>{
    setId(row.Id);
    toggleAddLocationWidget();
  };

  //Handle Edit Widget
  const toggleEditWidgetModel =()=>{
    setEditWidgetModel(!editWidgetModel);
  }
  const handleEditClicked = (user: any) => {
    toggleEditWidgetModel();
    setPassedUserId(user.Id);
  };

  const refetchData = () => {
    refetch();
  };

  // Expand Row 
  const { data: location_data , refetch : location_refetch} = useQuery(GET_LOCATION, {fetchPolicy: "no-cache"});
  const expandRow = {
      renderer: (row: any) => {
      const data = location_data?.msgbox_WidgetLocations || [];
      const found = data.filter((w: any) => w.WidgetId === row.Id);

      return(
        <div>
          <ExpandRowRenderer 
            found={found}  
            row={row} 
            handleLocationWidget={handleLocationWidget}
            onRefetch={location_refetch}
        />
        </div>
      )
      },
    showExpandColumn: true,
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <h4 className="card-title">All Widgets</h4>
              </div>
            </Col>
            <Col md={6}>
              <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                <div>
                  <Link to="#" className="btn btn-light" onClick={refetchData}>
                    <i className="uil uil-refresh me-1"></i> Refresh
                  </Link>
                </div>
                <div>
                  <Link
                    to="#"
                    className="btn btn-light"
                    onClick={handleUserClicks}
                  >
                    <i className="uil uil-plus me-1"></i> Add New
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardHeader>

        <CardBody>
      
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
          // columns={columns}
          // data={productData}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="Id"
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            // responsive

                            expandRow={expandRow}
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            selectRow={selectRow}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            keyField="Id"

                          />
                           <AddexpandRowWidget
                              WidgetId ={Id}
                              locationModel={locationModel}
                              setLocationModel={setLocationModel}
                              onRefetch = {location_refetch} />    
                          
                          <AddWidget
                            modal={modal}
                            setModal={setModal}
                            onRefetch ={refetchData}
                          />
                          <EditWidget
                            editWidgetModel={editWidgetModel}
                            setEditWidgetModel={setEditWidgetModel}
                            PassedUserId={PassedUserId}
                            onRefetch ={refetchData}
                          />
                         
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Widgets;


