import client from "../apollo";
import { gql } from "@apollo/client";

export interface ICreateEmailForwarderProps {
    emailForwarderId: number;
    orgId: number;
    apiAccountId: number;
    emailAddress: string;
    matchExpression: string;
    defaultEmail: string;
}

export const CreateEmailForwarder = async (props: ICreateEmailForwarderProps) => {
    const emailfwResp = await client.mutate({
        mutation: CREATE_EMAIL_FORWARDER,
        variables: {
            organisationId: props.orgId,
            apiAccountId: props.apiAccountId,
            emailAddress:  props.emailAddress,
            isDefault: props.defaultEmail,
            regEx: props.matchExpression
        },
    });
    props.emailForwarderId =
    emailfwResp.data.insert_msgbox_EmailParserOutgoingEmail.returning[0].EmailParserOutgoingEmailId;
    return props;
};

const CREATE_EMAIL_FORWARDER = gql`
mutation MyMutation($apiAccountId: Int!, $emailAddress: String!, $isDefault: Boolean!, $regEx: String!, $organisationId: Int!) {
    insert_msgbox_EmailParserOutgoingEmail(objects: {APIAccountId: $apiAccountId, EmailAddress: $emailAddress, IsDefault: $isDefault, RegEx: $regEx, OrganisationId: $organisationId}) {
        returning {
            EmailParserOutgoingEmailId
          }
    }
  }
  `
  
  export interface ICreateEmailParserProps {
    emailParserId: number;
    orgId: number;
    apiAccountId: number;
    emailAddress: string;
    templateId: number;
    parserType: string;
    doNotForwardIfSentOK: boolean;
    doNotForwardIfDeliveredOK: boolean;
    doNotForwardIfReadOK: boolean;
    doNotForwardIfRepliedTo: boolean;
    forwardingEmail: string;
}

export const CreateEmailParser = async (props: ICreateEmailParserProps) => {
    const emailfwResp = await client.mutate({
        mutation: CREATE_EMAIL_PARSER,
        variables: {
            organisationId: props.orgId,
            apiAccountId: props.apiAccountId,
            emailAddress:  props.emailAddress,
            templateId: props.templateId,
            parserType: props.parserType,
            doNotForwardIfSentOK: props.doNotForwardIfSentOK,
            doNotForwardIfDeliveredOK: props.doNotForwardIfDeliveredOK,
            doNotForwardIfReadOK: props.doNotForwardIfReadOK,
            doNotForwardIfRepliedTo: props.doNotForwardIfRepliedTo,
            forwardingEmail: props.forwardingEmail,
        },
    });
    props.emailParserId =
    emailfwResp.data.insert_msgbox_EmailParserConfig.returning[0].EmailParserConfigId;
    return props;
};

const CREATE_EMAIL_PARSER = gql`
    mutation MyMutation($organisationId: Int!, $apiAccountId: Int!, $emailAddress: String!, $templateId: Int!, $parserType: String!, $doNotForwardIfDeliveredOK: Boolean, $doNotForwardIfReadOK: Boolean, $doNotForwardIfRepliedTo: Boolean, $doNotForwardIfSentOK: Boolean , $forwardingEmail: String) {
        insert_msgbox_EmailParserConfig(objects: {OrganisationId: $organisationId, EmailAddress: $emailAddress, TemplateId: $templateId, ParserType: $parserType, DoNotForwardIfDeliveredOK: $doNotForwardIfDeliveredOK, DoNotForwardIfReadOK: $doNotForwardIfReadOK, DoNotForwardIfRepliedTo: $doNotForwardIfRepliedTo, DoNotForwardIfSentOK: $doNotForwardIfSentOK, ForwardingEmail: $forwardingEmail, APIAccountId: $apiAccountId}) {
        returning {
            EmailParserConfigId
        }
        }
    }  `