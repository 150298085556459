import React, { Dispatch, SetStateAction, useState, useEffect, useContext} from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { ThemeContext } from "../../helpers/themeContext";
import {
  GET_ORGANISATIONS
} from "./query";
import SweetAlert from "react-bootstrap-sweetalert";
import client from "../../apollo";
import { toNumber } from "lodash";
import AddEstateAgentAutomation from "./AddEstateAgentAutomation";
import AddEstateAgentCTAAutomation from "./AddEstateAgentCTAAutomation";
interface IProps {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  refresh: any
}

const AddBotAutoamtion = (props: IProps) => {

  const toggle = () => {
    props.setModal(!props.modal);
  };

  const [organisations, setOrganisations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState<number>(-1);

  const [APIAccounts, setAPIAccounts] = useState([]);
  const [selectedAPIAccount, setSelectedAPIAccount] = useState<number>(-1);

  const [selectedAutomation, setSelectedAutoMation] = useState(0);

  const [loading, setLoading] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);
  const Theme = useContext(ThemeContext)
  const [dynamic_description, setdynamic_description] = useState("");

  const automationOptions = [
    {
      Name: "Estate Agent",
      function: AddEstateAgentAutomation
    },
    {
      Name: "Estate Agent CTA",
      function: AddEstateAgentCTAAutomation
    }
  ]

  useEffect(() => {
      loadOrgs();   
  }, []);

  useEffect(() => {
    setSelectedAPIAccount(-1)
    const fullOrg: any= organisations.filter(
      (order: any, key: number) => order["OrganisationId"] == selectedOrg
    );
    if(fullOrg.length === 0) return
    setAPIAccounts(fullOrg[0].APIAccounts)
    const apiId = fullOrg[0].APIAccounts[0]?.APIAccountId
    setSelectedAPIAccount(apiId);
  }, [selectedOrg]);

  const loadOrgs = async () => {
    const orgs = await client.query({
      query: GET_ORGANISATIONS,
    });
    const orgData = orgs?.data?.msgbox_Organisation || [];
    const orgId = orgData[0]["OrganisationId"];
    setOrganisations(orgData);
    setSelectedOrg(orgId);
    const apiId = orgData[0].APIAccounts[0]?.APIAccountId
    setSelectedAPIAccount(apiId);
  };

  const handleOrgSelect = async (target: number) => {
    setSelectedOrg(target)
  };

  const handleAPIAccountSelect = async (target: number) => {
    setSelectedAPIAccount(target)
  };

  const handleAutomationSelect = async (target: number) => {
    setSelectedAutoMation(target)
  };

  const handleSubmit = async () => {
    setLoading(true)
    const fullOrg = organisations.filter(
      (order: any, key: number) => order["OrganisationId"] == selectedOrg
    );
    const fullAPI = APIAccounts.filter(
     (order: any, key: number) => order["APIAccountId"] == selectedAPIAccount
    );
    let res = await automationOptions[selectedAutomation].function(fullOrg[0], fullAPI[0])
    if(res === true){
      props.refresh()
      setdynamic_description("Created Bot Flow");
      setsuccess_dlg(true);
      toggle();
    } else {
      setdynamic_description("Failed to create Bot Flow");
      setfailure_dlg(true);
    }
    setLoading(false)
  };

  return (
    <React.Fragment>
      {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      {failure_dlg ? (
        <SweetAlert
          error
          title={Theme.name}
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}      
      <Modal
        isOpen={props.modal}
        toggle={toggle}
        size="lg"
      >
        <ModalHeader toggle={toggle} tag="h4">
          Add New Bot Automation
        </ModalHeader>
        <ModalBody className="modelStyle">
          <AvForm
            onValidSubmit={() => {
              handleSubmit();
            }}
          >
            <Row form className="d-flex flex-row mb-3">
              <Col>

                <FormGroup className="mb-3">
                  <Label>Select an Organisation</Label>
                  <Input
                    className="avInput"
                    id="selectOrg"
                    name="selectOrg"
                    type="select"
                    value={selectedOrg}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      handleOrgSelect(toNumber(e.currentTarget.value));
                    }}
                  >
                    {Array.isArray(organisations)
                      ? organisations.map((order: any, key: number) => (
                          <option key={key} value={order["OrganisationId"]}>
                            {order["Name"]}
                          </option>
                        ))
                      : null}
                  </Input>
                  </FormGroup>

                  <FormGroup className="mb-3">
                  <Label>Select an API Account</Label>
                  <Input
                    className="avInput"
                    id="selectAPIAccount"
                    name="selectAPIAccount"
                    type="select"
                    value={selectedAPIAccount}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      handleAPIAccountSelect(toNumber(e.currentTarget.value));
                    }}
                  >
                    {Array.isArray(APIAccounts)
                      ? APIAccounts.map((order: any, key: number) => (
                          <option key={key} value={order["APIAccountId"]}>
                            {order["Name"]}
                          </option>
                        ))
                      : null}
                  </Input>
                </FormGroup>

                <FormGroup className="mb-3">
                  <Label>Select A Bot Flow</Label>
                  <Input
                    className="avInput"
                    id="selectAutomation"
                    name="selectAutomation"
                    type="select"
                    value={selectedAutomation}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      handleAutomationSelect(toNumber(e.currentTarget.value));
                    }}
                  >
                    {Array.isArray(automationOptions)
                      ? automationOptions.map((item: any, key: number) => (
                          <option key={key} value={key}>
                            {item["Name"]}
                          </option>
                        ))
                      : null}
                  </Input>
                </FormGroup>

              </Col>
            </Row>
            <Row>
              <Col>
              {!loading ? 
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={() => props.setModal(false)}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-success save-user">
                    Add
                  </button>
                </div>
                :
                <div className="text-end">
                <button
                  type="button"
                  className="btn btn-light w-sm"
                >
                  Loading
                </button>
              </div>
}
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddBotAutoamtion;
