import React, { Dispatch, SetStateAction, useState, useEffect, useContext} from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { ThemeContext } from "src/helpers/themeContext";
import {
  GET_ORGANISATIONS,
  GET_API_ACCOUNTS,
  GET_TEMPLATES,
  GET_PARSERTYPES,
} from "./query";

import client from "../../apollo";
import SweetAlert from "react-bootstrap-sweetalert";
import { toNumber } from "lodash";
import { ICreateEmailParserProps, CreateEmailParser } from "src/common/emailParserUtility";


interface IProps {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  inboxId?: number;
  orgName?: string;
  orgId?: number;
  apiAccountId?: number;
}

const AddEmailParser = (props: IProps) => {
  const handleShow = () => {
    props.setModal(false);
  };
  const toggle = () => {
    props.setModal(!props.modal);
  };
  const Theme = useContext(ThemeContext)
  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);
  const [organisations, setOrganisations] = useState([]);
  const [apiAccounts, setApiAccounts] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [parserTypes, setParserTypes] = useState([]);
  const [showApi, setShowApi] = useState<boolean>(false);
  const [showTemplate, setShowTemplate] = useState<boolean>(false);
  const [selectedOrg, setSelectedOrg] = useState<number>(-1);
  const [selectedApi, setSelectedApi] = useState<number>(-1);
  const [selectedTemplate, setSelectedTemplate] = useState<number>(-1);
  const [selectedParserType, setSelectedParserType] = useState("");
  const [selectedParserIndex, setSelectedParserIndex] = useState<number>(-1);

  useEffect(() => {
    if (organisations.length === 0) {
      loadOrgs();
    }
    loadParserTypes();
  });

  useEffect(() => {
    //console.log("apiAccouns changed");
    if (apiAccounts && apiAccounts[0]) {
      handleApiSelect(apiAccounts[0]["APIAccountId"]);
    }
  }, [apiAccounts]);

  const loadOrgs = async () => {
    const orgs = await client.query({
      query: GET_ORGANISATIONS,
    });
    const orgData = orgs?.data?.msgbox_Organisation || [];
    const orgId = orgData[0]["OrganisationId"];

    setOrganisations(orgData);
    setSelectedOrg(orgId);

    if (orgData.length > 0) {
      //      console.log("loading apis");
      loadApis(orgId);
    }
  };

  const loadParserTypes = async () => {
    const orgs = await client.query({
      query: GET_PARSERTYPES,
    });
    const parserTypes = orgs?.data?.msgbox_EmailParserType || [];

    setParserTypes(parserTypes);
  };

  const loadApis = async (orgId: number) => {
    const apis = await client.query({
      query: GET_API_ACCOUNTS,
      variables: {
        orgId: orgId,
      },
    });
    const apiData = apis?.data?.msgbox_APIAccount || [];
    setApiAccounts(apiData);

    if (apiData.length > 0) {
      // console.log("select first api account, more than 0");
      const apiId = apiData[0]["APIAccountId"];
      setSelectedApi(apiId);
    } else {
      //console.log("only 0");
    }
    setShowApi(true);
  };

  const loadTemplates = async (apiAccountId: number) => {
    console.log("loadtemplates called:", apiAccountId)
    const templates = await client.query({
      query: GET_TEMPLATES,
      variables: {
        apiAccountId: apiAccountId,
      },
    });
    const templateData = templates?.data?.msgbox_Template || [];
    setTemplates(templateData);
    if (templateData.length > 0) {
      // console.log("select first api account, more than 0");
      const templateId = templateData[0]["TemplateId"];
      setSelectedTemplate(templateId);
    } else {
      //console.log("only 0");
    }
    setShowTemplate(true);
  };

  const handleOrgSelect = async (target: number) => {
    //console.log("handleOrgSelect");
    setShowApi(false);
    setSelectedOrg(target);
    await loadApis(target);
    const selectedOrg = organisations.filter(
      (order: any, key: number) => order["OrganisationId"] == target
    );
  };

  const handleApiSelect = async (target: number) => {
    setSelectedApi(target);
    await loadTemplates(target)
    //const selectedApi = apiAccounts.filter(
    //  (order: any, key: number) => order["APIAccountId"] == target
    //);
  };

  const handleTemplateSelect = async (target: number) => {
    setSelectedTemplate(target);
  };

  const handleParserTypeSelect = async (target: any) => {
    setSelectedParserIndex(target.selectedIndex);
    setSelectedParserType(target.options[target.selectedIndex].text);
  };

  const handleEmailParserSubmit = async (values: any) => {
    console.log("Email Parser Submitted");
    console.log("values:", values)
    var emailParser: ICreateEmailParserProps | null = {
      emailParserId: 0,
      apiAccountId: selectedApi,
      orgId: selectedOrg,
      templateId: selectedTemplate,
      parserType: selectedParserType,
      forwardingEmail: values["forwardEmailAddress"],
      emailAddress: values["parserEmailAddress"],
      doNotForwardIfDeliveredOK: false,
      doNotForwardIfReadOK: false,
      doNotForwardIfRepliedTo: true,
      doNotForwardIfSentOK: false
    };
    switch (values["forwardOption"]){
      case "1": 
        emailParser.doNotForwardIfRepliedTo = false;
        break;
      case "2": 
        emailParser.doNotForwardIfRepliedTo = false;
        emailParser.doNotForwardIfSentOK = true;
        break;
      case "3": 
        emailParser.doNotForwardIfRepliedTo = false;
        emailParser.doNotForwardIfDeliveredOK = true;
        break;
      case "4": 
        emailParser.doNotForwardIfRepliedTo = false;
        emailParser.doNotForwardIfReadOK = true;
        break;        
      case "5": //this is the default so change nothing
        break;
      }
    console.log("emailParser:", emailParser)
    var newEmailParser = await CreateEmailParser(emailParser).catch(err => {
      console.log("error:",err)
      setdynamic_description("Failed to create Email Parser");
      setfailure_dlg(true);
      toggle();
      return null;
    });
    if (newEmailParser == null) {
      return false;
    }

    setdynamic_description("Email Parser created successfully");
    setsuccess_dlg(true);
    toggle();
  };

  const handleModalOpen = () => {
    if (props.orgId) {
      handleOrgSelect(props.orgId);
    }
    if (props.apiAccountId) {
      console.log("setting apiAccountId:", props.apiAccountId);
      handleApiSelect(props.apiAccountId);
    }
  };

  return (
    <React.Fragment>
      {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      {failure_dlg ? (
        <SweetAlert
          error
          title={Theme.name}
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}      
      <Modal
        isOpen={props.modal}
        toggle={toggle}
        size="lg"
        onOpened={() => handleModalOpen()}
      >
        <ModalHeader toggle={toggle} tag="h4">
          Add New Email Parser
        </ModalHeader>
        <ModalBody className="modelStyle">
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleEmailParserSubmit(values);
            }}
          >
            <Row form className="d-flex flex-row mb-3">
              <Col>
                <FormGroup className="mb-3">
                  <Label>Select an Organisation</Label>
                  <Input
                    className="avInput"
                    id="selectOrg"
                    name="selectOrg"
                    type="select"
                    value={selectedOrg}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      handleOrgSelect(toNumber(e.currentTarget.value));
                    }}
                  >
                    {Array.isArray(organisations)
                      ? organisations.map((order: any, key: number) => (
                          <option key={key} value={order["OrganisationId"]}>
                            {order["Name"]}
                          </option>
                        ))
                      : null}
                  </Input>
                </FormGroup>

                {showApi && (
                  <FormGroup className="mb-3">
                    <Label>Select an API Account</Label>
                    <Input
                      className="avInput"
                      id="selectApi"
                      name="selectApi"
                      type="select"
                      value={selectedApi}
                      onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        handleApiSelect(toNumber(e.currentTarget.value))
                      }
                    >
                      {Array.isArray(apiAccounts) && apiAccounts.length > 0 ? (
                        apiAccounts.map((order: any, key: number) => (
                          <option key={key} value={order["APIAccountId"]}>
                            {order["Name"]}
                          </option>
                        ))
                      ) : (
                        <p>No API Accounts Found</p>
                      )}
                    </Input>
                  </FormGroup>
                )}

                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="parserEmailAddress"
                    label="Incoming Email Address"
                    type="email"
                    placeholder="Incoming email address"
                    errorMessage="Invalid email address"
                    validate={{
                      required: { value: true }
                    }}
                  />
                </div>

                {showTemplate && (
                  <FormGroup className="mb-3">
                    <Label>Select Template</Label>
                    <Input
                      className="avInput"
                      id="template"
                      name="template"
                      type="select"
                      value={selectedTemplate}
                      onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        handleTemplateSelect(toNumber(e.currentTarget.value))
                      }
                    >
                      {Array.isArray(templates) && templates.length > 0 ? (
                        templates.map((order: any, key: number) => (
                          <option key={key} value={order["TemplateId"]}>
                            {order["Name"]}
                          </option>
                        ))
                      ) : (
                        <p>No Templates Found</p>
                      )}
                    </Input>
                  </FormGroup>
                )}

                {parserTypes && (
                  <FormGroup className="mb-3">
                    <Label>Select Parser Type</Label>
                    <Input
                      className="avInput"
                      id="parserType"
                      name="parserType"
                      type="select"
                      value={selectedParserIndex}
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        handleParserTypeSelect(e.currentTarget);
                      }}
                    >
                      {Array.isArray(parserTypes) && parserTypes.length > 0 ? (
                        parserTypes.map((order: any, key: number) => (
                          <option key={key} value={key}>
                            {order["Name"]}
                          </option>
                        ))
                      ) : (
                        <p>No Parser Types Found</p>
                      )}
                    </Input>
                  </FormGroup>
                )}

                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="forwardEmailAddress"
                    label="Forwarding Email Address"
                    type="email"
                    placeholder="Forwarding email address"
                    errorMessage="Invalid email address"
                    validate={{
                      required: { value: false }
                    }}
                    value={""}
                  />
                </div>

                <div className="mb-3">
                  <AvField className="avInput" type="select" id="forwardOption" defaultValue="1" name="forwardOption" label="Forwarding Option">
                    <option value="1">Always forward</option>
                    <option value="2">Dont forward if successfully sent</option>
                    <option value="3">Dont forward if delivered</option>
                    <option value="4">Dont forward if customer read</option>
                    <option value="5">Dont forward if customer replied</option>
                  </AvField>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={handleShow}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddEmailParser;
