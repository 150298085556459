import { gql } from '@apollo/client';
import { randomUUID } from 'crypto';

export const DELETE_API = gql`
  mutation delete_api ($apiId: Int!, $userId: Int, $time: timestamptz, $phoneNumber: String) {
      update_msgbox_APIAccount(
        where: {APIAccountId: {_eq: $apiId}},
        _set: {
          IsDeleted:true,
          DeletedBy: $userId,
          DeletedOn:$time,
          Key: "DELETED",
          AppName: "DELETED",
          PhoneNumber: $phoneNumber,
          PartnerId:null,
          PartnerToken:null
        }) {
        affected_rows
    }
    update_msgbox_EmailParserConfig (
        where: {APIAccountId: {_eq: $apiId}},
        _set: {
          IsDeleted:true,
          DeletedBy: $userId,
          DeletedOn:$time,
        }) {
        affected_rows
    }
  }
`
  