import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from "react";
import { ThemeContext } from "src/helpers/themeContext";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import TeamForm from "../TeamForm/TeamForm";
import SweetAlert from "react-bootstrap-sweetalert";
import { GET_TEAM } from "src/pages/Teams/teamsQuery";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import client from "src/apollo";
import {
  GET_INBOXES,
  GET_ORGS,
  GET_USERS,
  INSERT_USER_DEPARTMENTS,
  ADD_INBOXES,
} from "../AddTeam/query";
import {
  REMOVE_USER_DEPARTMENTS,
  REMOVE_DEPARTMENT_INBOX,
  UPDATE_DEPARTMENT_DETAILS,
  DELETE_DEPARTMENT,
} from "./query";

interface IProps {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  onRefetch: any;
  teamId: any;
}

const EditTeam = (props: IProps) => {
  const [teamData, setTeamData] = useState<any>();
  const [orgs, setOrgs] = useState<any>({ msgbox_Organisation: [] });
  const [inboxes, setInboxes] = useState<any>([]);
  const [selectedOrgId, setSelectedOrgId] = useState<any>(0);
  const [selectedInboxes, setSelectedInboxes] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [teamName, setTeamName] = useState("");
  const [filteredUsers, setFilteredUsers] = useState<any>([]);
  const [userSearchInput, setUserSearchInput] = useState("");
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const [existingTeam, setExistingTeam] = useState<any>({});
  const [insertUserDepartments] = useMutation(INSERT_USER_DEPARTMENTS);
  const [addInboxes] = useMutation(ADD_INBOXES);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);
  const [delete_dlg, setdelete_dlg] = useState(false);

  const Theme = useContext(ThemeContext);

  const { data, loading, error } = useQuery(GET_TEAM, {
    variables: {
      id: Number(props.teamId),
    },
    fetchPolicy: "no-cache",
  });

  const [removeUserDepartments] = useMutation(REMOVE_USER_DEPARTMENTS);
  const [removeDepartmentInbox] = useMutation(REMOVE_DEPARTMENT_INBOX);
  const [updateDepartmentDetails] = useMutation(UPDATE_DEPARTMENT_DETAILS);
  const [deleteDepartment] = useMutation(DELETE_DEPARTMENT);

  const [triggerInboxQuery, { data: InboxData }] = useLazyQuery(GET_INBOXES, {
    variables: {
      orgId: selectedOrgId,
    },
    fetchPolicy: "no-cache",
  });

  const { data: OrgData } = useQuery(GET_ORGS, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    OrgData ? setOrgs(OrgData) : setOrgs({ msgbox_Organisation: [] });
  }, [OrgData]);

  useEffect(() => {
    setSelectedInboxes([]);
    setTeamName("");
    setSelectedUser([]);
  }, [props.modal]);

  useEffect(() => {
    if (data?.msgbox_Department?.length > 0) {
      const exist: Record<string, any> = {
        DepartmentInboxes: data.msgbox_Department[0].DepartmentInboxes,
        Name: data.msgbox_Department[0].Name,
        Organisation: data.msgbox_Department[0].Organisation,
        UserDepartments: data.msgbox_Department[0].UserDepartments,
      };
      setExistingTeam(exist);

      setTeamName(data.msgbox_Department[0].Name);
      setSelectedOrgId(data.msgbox_Department[0].Organisation.OrganisationId);
      loadUsers(Number(data.msgbox_Department[0].Organisation.OrganisationId));

      data.msgbox_Department[0].DepartmentInboxes.forEach((inb: any) => {
        if (inb?.Inbox?.InboxId) {
          setSelectedInboxes((prev: any) => [
            ...prev,
            {
              value: inb.Inbox.InboxId,
              label: inb.Inbox.Name,
            },
          ]);
        }
      });
      data.msgbox_Department[0].UserDepartments.forEach((user: any) => {
        if (user?.User?.UserId) {
          setSelectedUser((prev: any) => [
            ...prev,
            {
              value: user.User.UserId,
              label: user.User.FirstName + " " + user.User.LastName,
            },
          ]);
        }
      });
    }
  }, [data, props.modal]);

  useEffect(() => {
    triggerInboxQuery().then(data => {
      let newArray = [];
      for (let index = 0; index < data.data.msgbox_Inbox.length; index++) {
        const element = data.data.msgbox_Inbox[index];
        newArray.push({
          value: element.InboxId,
          label: element.Name,
        });
      }
      setInboxes(newArray);
    });
    setDataLoaded(true);
  }, [selectedOrgId]);

  const toggle = () => {
    props.setModal(!props.modal);
  };

  const loadUsers = async (orgId: number) => {
    const users = await client.query({
      query: GET_USERS,
      variables: {
        orgId: orgId,
      },
    });
    const userData = users?.data?.msgbox_User || [];

    let options: { value: any; label: string }[] = [];
    userData.forEach((element: any) => {
      const name = element.FirstName + " " + element.LastName;
      const data = { value: element.UserId, label: name };
      options.push(data);
    });
    setUsers(options);
    setFilteredUsers(options);
  };

  const handleShow = () => {
    props.setModal(false);
  };

  const handleSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const value = event.target.value;
    setSelectedOrgId(Number(value));
    loadUsers(Number(value));
  };

  const handleTeamDelete = () => {
    deleteDepartment({
      variables: {
        departmentId: props.teamId,
        departmentName: teamName,
      },
    });

    setdelete_dlg(true);
  };
  const submit = async () => {
    try {
      //remove all the existing users
      const removeUserPromises = existingTeam.UserDepartments.map(
        (userToRemove: any) => {
          return removeUserDepartments({
            variables: {
              userId: userToRemove.User.UserId,
              departmentId: props.teamId,
            },
          });
        }
      );
      await Promise.all(removeUserPromises);
      //add in those users now selected
      for (let i = 0; i < selectedUser.length; i++) {
        await insertUserDepartments({
          variables: {
            departmentId: props.teamId,
            userId: selectedUser[i].value,
            orgId: selectedOrgId,
          },
        });
      }

      const removeInboxPromises = existingTeam.DepartmentInboxes.map(
        // remove existing inboxes
        (inboxToRemove: any) => {
          return removeDepartmentInbox({
            variables: {
              inboxId: inboxToRemove.Inbox.InboxId,
              departmentId: props.teamId,
            },
          });
        }
      );
      await Promise.all(removeInboxPromises);
      //add in those inboxes now selected
      for (let i = 0; i < selectedInboxes.length; i++) {
        await addInboxes({
          variables: {
            department: props.teamId,
            inbox: selectedInboxes[i].value,
            orgId: selectedOrgId,
          },
        });
      }
      updateDepartmentDetails({
        variables: {
          departmentId: props.teamId,
          departmentName: teamName,
        },
      });
    } catch (e) {
      console.log(e);
      setfailure_dlg(true);
    }

    setsuccess_dlg(true);
    props.setModal(false);
  };

  return (
    <React.Fragment>
      {failure_dlg ? (
        <SweetAlert
          error
          title="Error"
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      {success_dlg ? (
        <SweetAlert
          success
          hideOverlay={true}
          title={Theme.name}
          onConfirm={() => {
            props.onRefetch();
            setsuccess_dlg(false)
          }}
        >
          Team edited successfully
        </SweetAlert>
      ) : null}
      {delete_dlg ? (
        <SweetAlert
          success
          hideOverlay={true}
          title={Theme.name}
          onConfirm={() => {
            props.onRefetch();
            setdelete_dlg(false)
          }}
        >
          Team successfully deleted!
        </SweetAlert>
      ) : null}
      <Modal isOpen={props.modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle} tag="h4">
          Edit Team
        </ModalHeader>
        <ModalBody className="modelStyle">
          {dataLoaded ? (
            <TeamForm
              teamName={teamName}
              setTeamName={setTeamName}
              handleSelectChange={handleSelectChange}
              orgs={orgs}
              inboxes={inboxes}
              selectedInboxes={selectedInboxes}
              setSelectedInboxes={setSelectedInboxes}
              filteredUsers={filteredUsers}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              handleShow={handleShow}
              submit={submit}
              isEdit={true}
              existingTeam={existingTeam}
              handleTeamDelete={handleTeamDelete}
            />
          ) : (
            <></>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default EditTeam;
