import React, { Dispatch, SetStateAction, useState, useContext, useEffect } from "react";
import { Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation, useQuery } from "@apollo/client";
import SweetAlert from "react-bootstrap-sweetalert";
import { ThemeContext } from "src/helpers/themeContext";
import Select, { OnChangeValue } from "react-select";
import { ADD_USER, ADD_USER_TO_TEAM, GET_EMAILS, GET_INBOXES, GET_ORGANISATIONS, GET_ROLES, GET_TEAMS } from "./query";
import Organisations from "src/pages/Organisations/Organisations";

interface IProps {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  onRefetch: any;
}

const AddUser = (props: IProps) => {

  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);

  const [emails, setEmails] = useState<any>([])
  const [orgs, setOrgs] = useState([])
  const [org, setOrg] = useState<any>(false)

  const [roles, setRoles] = useState([])
  const [role, setRole] = useState<any>(false)

  const [inboxes, setInboxes] = useState([])
  const [inbox, setInbox] = useState<any>([])

  const [teams, setTeams] = useState([])
  const [team, setTeam] = useState([])

  const [addUser] = useMutation(ADD_USER)
  const [addUserToTeam] = useMutation(ADD_USER_TO_TEAM)
  const {
    data: orgData,
    refetch: refetchOrgData 
  } = useQuery(GET_ORGANISATIONS)

  const {
    data: emailData,
  } = useQuery(GET_EMAILS)

  const {
    data: roleData,
    refetch: refetchRoleData 
  } = useQuery(GET_ROLES)

  const {
    data: teamData,
    refetch: refetchTeamData 
  } = useQuery(GET_TEAMS, {
    variables: {
      orgId: org?.value || -1
    }
  })

  const {
    data: inboxData,
    refetch: refetchInboxData 
  } = useQuery(GET_INBOXES, {
    variables: {
      org: org?.value || -1
    }
  })

  const Theme = useContext(ThemeContext);

  const toggle = () => {
    setInbox([])
    setTeam([])
    setRole(false)
    setOrg(false)
    props.setModal(!props.modal);
  };

  useEffect(() => {
    if(!emailData) return
    let arr = emailData.msgbox_User.map((item: any) => item.EMail).filter((item: any) => item)
    setEmails(arr)
  }, [emailData])

  useEffect(() => {
    if(!roleData) return
    let arr = roleData.msgbox_Role.filter((item: any) => {
      return item.RoleId !== 2
    }).map(((item: any) => {
      return {label: item.Description, value: item.RoleId}
    }))
    setRoles(arr)
  }, [roleData])

  useEffect(() => {
    if(!orgData) return
    let arr = orgData.msgbox_Organisation.map(((item: any) => {
      return {label: item.Name, value: item.OrganisationId}
    }))
    setOrgs(arr)
  }, [orgData])

  useEffect(()=>{
    if(!teamData) return
    let arr = teamData.msgbox_Department.map((team: any)=>{
      return {label: team.Name, value: team.DepartmentId}
    })
    setTeams(arr)
  },[teamData])

  useEffect(() => {
    setInboxes([])
    setInbox([])
    refetchInboxData()
    refetchTeamData()
  }, [org])

  useEffect(() => {
    if(!inboxData) return
    let arr = inboxData.msgbox_Inbox.map(((item: any) => {
      return {label: item.Name, value: item.InboxId}
    }))
    setInboxes(arr)
  }, [inboxData])

  const handleSubmit = async (values: any) => {
    try {
      if(!org?.value || !inbox[0]?.value || !role?.value || !values.fname || !values.email || !values.lname) return

      const userId = await addUser({
        variables: {
          firstName: values.fname,
          lastName: values.lname,
          email: values.email,
          org: org.value,
          role: { data: { RoleId: role.value, OrganisationId: org.value } },
          inboxes: {
            data: inbox.map((inbox: any) => ({ InboxId: inbox.value, OrganisationId: org.value })),
          },
      }})

      team.map(async (userDeptToAdd: any)=>{
        await addUserToTeam({
          variables:{
            departmentId: userDeptToAdd.value,
            orgId: org.value,
            userId: userId.data.insert_msgbox_User.returning[0].UserId
          }
        })
      })

      setdynamic_description("User created successfully");
      setsuccess_dlg(true);
      toggle();

    } catch {
      setdynamic_description("User creation failed");
      setfailure_dlg(true);
    }
  };


  const selectStyle = {
    control: (provided: any) => ({
        ...provided,
        backgroundColor: Theme.styles.primary, // primary  
        border: `1px solid ${Theme.styles.primaryBold}`, //primary bold
        color: Theme.styles.textColour1,   //textColor1
        borderColor: Theme.styles.primary
    }), 
    singleValue: (provided: any) => ({
      ...provided,
      color: Theme.styles.textColour1,   //textColor1
     }), 
      menu: (provided: any) => ({
        ...provided,
        backgroundColor: Theme.styles.primary, // primary  
        border: `1px solid ${Theme.styles.primaryBold}`, //primary bold
        color: Theme.styles.textColour1,   //textColor1
    }), 
    menuList: (provided: any) => ({
        ...provided,
        backgroundColor: Theme.styles.primary, // primary  
        border: `1px solid ${Theme.styles.primaryBold}`, //primary bold
        color: Theme.styles.textColour1,   //textColor1
    }), 
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
        return {
          ...styles,
          backgroundColor: isFocused ? Theme.styles.secondary : null,
          color: Theme.styles.textColour1,   //textColor1
        };
      }, 
      multiValue: (styles: any) => {
        return {
          ...styles,
          backgroundColor: Theme.styles.secondary,
        };
      },
      multiValueLabel: (styles: any) => ({
        ...styles,
        color: Theme.styles.textColour1,   //textColor1
      }),
      multiValueRemove: (styles: any) => ({
        ...styles,
        color: Theme.styles.textColour1,   //textColor1
        ':hover': {
          backgroundColor: Theme.styles.primary,
        },
      }),
    }


  return (
    <React.Fragment>
      {failure_dlg ? (
        <SweetAlert
          error
          title={Theme.name}
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
            props.onRefetch();
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      <Modal isOpen={props.modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle} tag="h4">
          Add New User
        </ModalHeader>
        <ModalBody className="modelStyle">
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleSubmit(values);
            }}
          >
            <Row form className="d-flex flex-row mb-3">
              <Col>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="fname"
                    label="First Name"
                    type="text"
                    placeholder="Enter first name"
                    errorMessage="Invalid first name"
                    validate={{
                      required: { value: true },
                    }}
                    value={""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="email"
                    label="Email"
                    type="email"
                    placeholder="Enter email"
                    errorMessage="Invalid or Duplicate Email"
                    validate={{
                      email: true,
                      required: { value: true },
                      function: (value: any) => {
                        const emailInputLower = value.toLowerCase() || '';
                        return !emails.map((email: any) => email.toLowerCase()).includes(emailInputLower);
                      }        
                    }}
                    value={""}
                  />
                </div>
                <div className="mb-3">
                  <label>Select Inboxes</label>
                  <Select
                    isMulti
                    isClearable={true}
                    name="inbox"
                    placeholder="Select"
                    options={inboxes}
                    onChange={(e: any) => setInbox(e)}
                    value={inbox}
                    styles={selectStyle}
                  />
                </div>


                <div className="mb-3">
                <label>Select Role</label>

                <Select
                    isClearable={true}
                    name="role"
                    placeholder="Select"
                    options={roles}
                    onChange={(e: any) => setRole(e)}
                    styles={selectStyle}
                  />
                </div>


              </Col>
              <Col className="px-2">
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="lname"
                    label="Last Name"
                    type="text"
                    placeholder="Enter last name"
                    errorMessage="Invalid Last Name"
                    validate={{
                      required: { value: true },
                    }}
                    value={""}
                  />
                </div>
                <div className="mb-3">
                <label>Select Organisation</label>

                  <Select
                    isClearable={true}
                    name="organisation"
                    placeholder="Select"
                    options={orgs}
                    onChange={(e: any) => setOrg(e)}
                    styles={selectStyle}
                  />
                </div>



                <div className="mb-3">
                  <label>Select Teams</label>
                  <Select
                    isMulti
                    isClearable={true}
                    name="teams"
                    placeholder="Select"
                    options={teams}
                    onChange={(e: any) => setTeam(e)}
                    value={team}
                    styles={selectStyle}
                  />
                </div>

              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={toggle}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn  btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddUser;
