import React, { useContext } from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Col, Container, Row } from "reactstrap";
import Users from "./Users";
import { ThemeContext } from "src/helpers/themeContext";

const UsersPage = () => {
    const Theme= useContext(ThemeContext)
    return (
        
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Users | {Theme.name} users</title>
                </MetaTags>

                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Home" breadcrumbItem="Users" />

                    <Row>
                        <Col lg={12}>
                            <Users />
                        </Col>
                    </Row>

                    
                </Container>
            </div>
        </React.Fragment>
    );
}

export default UsersPage;