import { gql } from "@apollo/client";

export const GET_EMAILFORWARDERS = gql`
  query GetEmailForwarders {
    msgbox_EmailParserOutgoingEmail {
      Organisation {
        Name
      }
      APIAccount {
        Name
      }
      EmailAddress
      IsDefault
      RegEx
      EmailParserOutgoingEmailId
      APIAccountId
    }
  }
`;
