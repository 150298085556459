import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query MyQuery($orgId: Int) {
    msgbox_User(where: {OrganisationId: {_eq: $orgId}, IsDeleted: {_eq: false}}, order_by: {LastName: asc}) {
      UserId
      FirstName
      LastName
    }
  }`;
