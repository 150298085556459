import client from "../apollo";
import { gql } from "@apollo/client";

export interface ICreateWidgetProps {
  widgetId: Number;
  publicId: string;
  name: string;
  headerText: string;
  optionText: string;
  url: string;
  brandId: string;
  size: string;
}

export const CreateWidget = async (props: ICreateWidgetProps) => {
  const orgBotResponse = await client.mutate({
    mutation: CREATE_WIDGET_QL,
    variables: {
      publicId: props.publicId,
      name: props.name,
      headerText: props.headerText,
      optionText: props.optionText,
      url: props.url,
      widgetBrandId: props.brandId,
      size: props.size
    },
  });
  props.widgetId = orgBotResponse.data.insert_msgbox_Widget.returning[0].WidgetId;

  return props;
};

const CREATE_WIDGET_QL = gql`
  mutation MyMutation($name: String!, $publicId: String!, $headerText: String!, $optionText: String!, $url: String!, $widgetBrandId: Int!, $size: String!) {
    insert_msgbox_Widget(objects: {Name: $name, PublicId: $publicId, WelcomeText: $headerText, Size: $size, WidgetLocations: {data: {Text: $optionText, Url: $url}}, WidgetBrandId: $widgetBrandId}) {
      returning {
        WidgetId
      }
    }
  }`;

export const getWidgetBrandId = async () => {
  const widgetResponse = await client.query({
    query: GET_WIDGET_BRANDID_QL,
  });
  if (widgetResponse.data && widgetResponse.data.msgbox_WidgetBrand && widgetResponse.data.msgbox_WidgetBrand[0]) {
    return widgetResponse.data.msgbox_WidgetBrand[0].BrandId
  }
  return null;
};

const GET_WIDGET_BRANDID_QL = gql`
  query MyQuery {
    msgbox_WidgetBrand {
      BrandId
      Name
    }
  }
  `