import { gql } from "@apollo/client";

export const GET_ORGANISATIONS = gql`
  query MyQuery {
    msgbox_Organisation {
      Name
      OrganisationId
      IsEnabled
      CreatedOn
      users_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;
