import { gql } from "@apollo/client";

export const USER_DATA_COUNT = gql`
query getUserCount($start:date $end:date){
  	reporting_UsersByReseller(where: {
        Date:{_gte: $start ,_lte:$end }
      }) {
        AddedCount
    		Date
    }
}
`;  