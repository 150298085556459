import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardHeader, DropdownToggle, UncontrolledDropdown, DropdownMenu, DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { useMutation, useQuery } from "@apollo/client";
import { GET_USERS } from "./users.query";
import { ThemeContext } from "src/helpers/themeContext";
import { DELETE_USER } from "./deleteQuery";
import SweetAlert from "react-bootstrap-sweetalert";
import AddUser from "src/components/AddUser/AddUser";
import EditUser from "src/components/EditUser/EditUser";
import DeleteModal from "src/components/deleteModal/deleteModal";

const Users = () => {
  const [tableData, setTableData] = useState([]);

  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const Theme = useContext(ThemeContext);
  const [deleteUser] = useMutation(DELETE_USER);
  const [addNewModal, setAddNewModal] = useState(false)
  const [editUser, setEditUser] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const columns = [
    {
      dataField: "Id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "CreatedOn",
      text: "Created",
      sort: true,
    },
    {
      dataField: "FirstName",
      text: "First Name",
      sort: true,
    },
    {
      dataField: "LastName",
      text: "Last Name",
      sort: true,
    },
    {
      dataField: "Email",
      text: "Login",
      sort: true,
    },
    {
      dataField: "OrgName",
      text: "Org Name",
      sort: true,
    },
    {
      dataField: "IsDeleted",
      text: "IsDeleted",
      sort: true,
    },
    {
      dataField: "IsBot",
      text: "IsBot",
      sort: true,
    },
    {
      dataField: "IsAPI",
      text: "IsAPI",
      sort: true,
    },
    {
      dataField: "Auth0Id",
      text: "Auth Id",
      sort: true,
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (cellContent: any, user: any) => {
        return (
          <React.Fragment>
            <UncontrolledDropdown>
              <DropdownToggle tag="button" className="btn btn-light btn-sm">
                <i className="uil uil-ellipsis-h"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                  { !user.IsDeleted && <DropdownItem onClick={() => setDeleteModal(user)}>
                  <i className="mdi mdi-delete font-size-16 me-1"></i>
                    Delete User
                  </DropdownItem>}
              <DropdownItem onClick={() => {
                setEditUser(user)
                }}>
                <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                Edit
              </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </React.Fragment>
        );
      },
    },
  ];

  const handleDelete = async (user: any) => {  
    setDeleteModal(false) 
    await deleteUser({
      variables: {
        id: user.Id,
      },
    })
    setsuccess_dlg(true)
    setdynamic_description("Item will not be available to the user now, it will be permanently deleted in 60 days")
    refetch();
  };

  const defaultSorted: any = [
    {
      dataField: "id",
      order: "asc",
    },
  ];
  const selectRow: any = {
    mode: "checkbox",
  };
  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
  };
  const { SearchBar } = Search;

  const { data, refetch } = useQuery(GET_USERS, { fetchPolicy: "no-cache" });

  useEffect(() => {
    if (data && data.msgbox_User) {
      var tmpData = data.msgbox_User.filter((item: any) => item.userorganisation).map(function (item: {
        UserId: any;
        CreatedAt: any;
        FirstName: any;
        LastName: any;
        IsDeleted: any;
        IsBot: any;
        EMail: any;
        Auth0Id: any;
        IsAPI: any;
        userorganisation: { Name: string };
        OrganisationId: any;
        UserInboxes: any;
        UserRoles: any
      }) {
        return {
          Id: item.UserId,
          CreatedOn: new Date(item.CreatedAt).toLocaleDateString(),
          FirstName: item.FirstName,
          LastName: item.LastName,
          IsDeleted: item.IsDeleted,
          OrgName: item?.userorganisation?.Name || "",
          IsBot: item.IsBot,
          IsAPI: item.IsAPI,
          Auth0Id: item.Auth0Id,
          Email: item.EMail,
          OrganisationId: item.OrganisationId,
          Inboxes: item.UserInboxes,
          role: item.UserRoles
        };
      });
      setTableData(tmpData);
    }
  }, [data]);

  const refetchData = () => {
    refetch();
  };

  return (
    <React.Fragment>
      {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
        <DeleteModal isOpen={deleteModal !== false} cancel={()=> setDeleteModal(false)} submit={() => handleDelete(deleteModal)}/>
      <Card>
        <CardHeader>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <h4 className="card-title">All Users</h4>
              </div>
            </Col>
            <Col md={6}>
              <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                <div>
                  <Link to="#" className="btn btn-light" onClick={refetchData}>
                    <i className="uil uil-refresh me-1"></i> Refresh
                  </Link>
                </div>
                <div>
                  <Link
                    to="#"
                    className="btn btn-light"
                    onClick={() => setAddNewModal(true)}
                  >
                    <i className="uil uil-plus me-1"></i> Add New
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            // columns={columns}
            // data={productData}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="Id"
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            // responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            selectRow={selectRow}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                           <AddUser  
                            onRefetch ={refetchData} 
                            modal={addNewModal} 
                            setModal={setAddNewModal} 
                          />
                          <EditUser  
                            onRefetch ={refetchData} 
                            modal={editUser !== false} 
                            setModal={setEditUser} 
                            data={editUser}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Users;
