import logo from './images/logo.png'
import authBackground from './images/authBackground.jpg'
import icon from './images/Icon.png'
import favIcon from './images/favIcon.png'

export const msgboxx = {
    name: "msgboxx", 
    styles: {
        primary:'#03273c',
        secondary: '#043a5a',
        primaryBold: '#043a5a',
        textColour1: '#adb5db'   
     },
    images: {
        logo,
        authBackground, 
        icon,
        favIcon
    }
}
