import { gql } from "@apollo/client";

export const GET_WIDGET_DETAILS = gql`
query MyQuery ($WidgetId: Int!){
    msgbox_Widget(where: {WidgetId: {_eq: $WidgetId}}) {
      WidgetId
      Name
      WelcomeText
      Size
    }
  }
`;  