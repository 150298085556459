import React, { useContext } from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Col, Container, Row } from "reactstrap";
import Widgets from "./Widgets";
import { ThemeContext } from "src/helpers/themeContext";

const WidgetPage = () => {
  const Theme = useContext(ThemeContext)
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Widgets | {Theme.name} web widgets</title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="Widgets" />

          <Row>
            <Col lg={12}>
              <Widgets />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WidgetPage;
