import React, { useState } from 'react';
import { UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Card, CardBody, Form, Input } from "reactstrap";
import { useQuery } from "@apollo/client";
import { GET_ORGANISATIONS } from './customers.query';

const Customers = () => {
    const {
		data,
	    } = useQuery(GET_ORGANISATIONS, {
            fetchPolicy: "no-cache"
	});

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="d-flex justify-content-between">
                        <h4 className="card-title mb-4">Recent Customers</h4>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-hover table-nowrap mb-0 align-middle table-check">
                            <thead className="bg-light">
                                <tr>
                                    <th className="rounded-start" style={{ width: "15px" }}>
                                        <div className="form-check">
                                            <input className="form-check-input font-size-16" type="checkbox"
                                                value="" id="checkAll" />
                                            <label className="form-check-label" htmlFor="checkAll"> </label>
                                        </div>
                                    </th>
                                    <th>ID</th>
                                    <th>Created</th>
                                    <th>Organisation</th>
                                    <th>Users</th>
                                    <th>Enabled</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(data?.msgbox_Organisation || []).map((order: any, key: number) => (
                                    <tr key={key}>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input font-size-16" type="checkbox"
                                                    value="" id="flexCheckexampleone" />
                                                <label className="form-check-label" htmlFor="flexCheckexampleone">
                                                </label>
                                            </div>
                                        </td>
                                        <td className="fw-medium">
                                            {order['OrganisationId']}
                                        </td>
                                        <td>
                                            { new Date(order['CreatedOn']).toLocaleDateString()}
                                        </td>

                                        <td>
                                            <div className="d-flex">
                                                <div>
                                                    <p className="mb-0">{order['Name']}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {order['users_aggregate']['aggregate']['count']}    
                                        </td>
                                        <td>
                                            { order['IsEnabled'] ? 'Yes' : 'No' }
                                        </td>


                                       
                                    </tr>
                                ))}

                        </tbody>
                    </table>
                </div>
            </CardBody>
        </Card>
        </React.Fragment >
    );
};

export default Customers;