export const templateConfigs = {
  templateConfig: [
    {
      groupId: 1,
      groupName: "Common Templates",
      templates: [
        {
          templateId: 1,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "phone_follow_no_answer",
          templateText:
            "Hi {{1}}. I just tried calling you in regards to {{2}}. Can you let me know when it is convenient to speak, or alternatively, we could chat further here? Please confirm either way. Thanks, {{3}} from {{inboxName}}.",
          templateExample:
            "Hi [Paul]. I just tried calling you in regards to [the house viewing]. Can you let me know when it is convenient to speak, or alternatively, we could chat further here? Please confirm either way. Thanks, [Jane] from {{inboxName}}.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",          
        },
        {
          templateId: 2,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "phone_follow_when_answered",
          templateText:
            "Hi {{1}}. Thank you for the time on the phone just now in regards to {{2}}. If you have any further questions, then please let me know? Thanks, {{3}} from {{inboxName}}.",
          templateExample:
            "Hi [Paul]. Thank you for the time on the phone just now in regards to [the house viewing]. If you have any further questions, then please let me know? Thanks, [Jane] from {{inboxName}}.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 3,
          templateCategory: "MARKETING",
          templateType: "All",
          templateName: "previous_discussions",
          templateText:
            "Hi {{1}}. Just following up our previous discussions in regards to {{2}} Kind regards, {{3}}, {{inboxName}}",
          templateExample:
            "Hi [Bob]. Just following up our previous discussions in regards to [the rental of your property in Liverpool Street. Can you let me know when you want to discuss this further?] Kind regards, [Paul], {{inboxName}}",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",          
        },
        {
          templateId: 4,
          templateCategory: "MARKETING",
          templateType: "All",
          templateName: "can_we_chat",
          templateText:
            "Hi {{1}}. I was hoping to be able to have a quick catch up about {{2}} Can we arrange a time to discuss further, or maybe chat here? Whichever works best for you. Kind regards {{3}} from {{inboxName}}.",
          templateExample:
            "Hi [Paul]. I was hoping to be able to have a quick catch up about [the house viewing] Can we arrange a time to discuss further, or maybe chat here? Whichever works best for you. Kind regards [Jane] from {{inboxName}}.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",          
        },
        {
          templateId: 6,
          templateCategory: "MARKETING",
          templateType: "Presubscribers Only",
          templateName: "invite",
          templateText:
            "You have been invited to a WhatsApp conversation by {{1}} at {{inboxName}} in regards to {{2}}. Please respond with OK to continue the chat, or reply with STOP to unsubscribe at anytime.",
          templateExample:
            "You have been invited to a WhatsApp conversation by [Paul] at {{inboxName}} in regards to [the house viewing]. Please respond with OK to continue the chat, or reply with STOP to unsubscribe at anytime.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",          
        },
      ],
    },
    {
      groupId: 2,
      groupName: "Natural Chat Templates",
      templates: [
        {
          templateId: 52,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "natural_chat_text",
          templateText:
            "_You have been sent an update:_\n\n{{1}}\n\n_{{inboxName}}_",
          templateExample:
            "_You have been sent an update:_\n\n[You order has been shipped]\n\n_{{inboxName}}_",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",          
        },
        {
          templateId: 53,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "natural_chat_media",
          templateText:
            "_You have been sent an update:_\n\n{{1}}\n\n_To receive the attachment please respond *OK*_\n\n_{{inboxName}}_",
          templateExample:
            "_You have been sent an update:_\n\n[Please find attached the property image as request]\n\n_To receive the attachment please respond *OK*_\n\n_{{inboxName}}_",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",          
        },
        {
          templateId: 5,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "natural_chat",
          templateText:
            "There is a message waiting for you, sent by {{1}} at {{orgName}}. Please respond with OK to retrieve and view this message.",
          templateExample:
            "There is a message waiting for you, sent by [Paul] at {{orgName}}. Please respond with OK to retrieve and view this message.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
      ],
    },
    {
      groupId: 3,
      groupName: "Estate Agent Common Templates",
      templates: [
        {
          templateId: 10,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "ma_follow_up",
          templateText:
            "Hi {{1}}, Thank you for your recent enquiry regarding a valuation request for {{2}}. We would like to discuss this further and arrange a valuation for you. Can we arrange a time to discuss further or chat on here. Kind Regards {{3}}",
          templateExample:
            "Hi {{Mr Bloggs}}, Thank you for your recent enquiry regarding a valuation request for [21 Test Street]. We would like to discuss this further and arrange a valuation for you. Can we arrange a time to discuss further or chat on here. Kind Regards {{Sarah}}",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 13,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "viewing_follow_up_best_and_finals",
          templateText:
            "Hi {{1}}, we are just getting in touch about your viewing which took place on {{2}}, we are in receipt of multiple offers and are proceeding to best and final offers by {{3}}, please do let us know if you are interested and if we can do anymore for you. Many thanks, the team @{{orgName}}",
          templateExample:
            "Hi [James], we are just getting in touch about your viewing which took place on [Monday 12th October], we are in receipt of multiple offers and are proceeding to best and final offers by [1pm, Weds 14th October], please do let us know if you are interested and if we can do anymore for you. Many thanks, the team @{{orgName}}",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 14,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "val_response",
          templateText:
            "Hi {{1}}, its {{2}} here from {{orgName}}. Thank you for submitting your valuation request {{3}} for your {{4}}. I can confirm I've looked at your details submitted and I estimate your property {{5}}. If you have any other questions, please feel free to message me at anytime?",
          templateExample:
            "Hi [Paul], its [Tom] here from {{orgName}}. Thank you for submitting your valuation request [this morning] for your [2 bed house]. I can confirm I've looked at your details submitted and I estimate your property [to be worth around £750,000, however, if you have any more recent photo's to send, please can you send them to me]. If you have any other questions, please feel free to message me at anytime?",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
      ],
    },
    {
      groupId: 4,
      groupName: "Estate Agent Autoresponder Templates",
      templates: [
        {
          templateId: 20,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "lettings_responder_rightmove",
          templateText:
            "Hi {{1}}, further to your enquiry for the {{2}} on {{3}} ({{4}}), we’re sending you a one time message in WhatsApp to seek permission to chat further here about your enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 B - to book a viewing on a property you have seen listed for rent\n 👉 Q - if you want to ask a general question about a property\n 👉 L - if you want to let your property\n\n To unsubscribe reply with STOP.",
          templateExample:
            "Hi [Paul], further to your enquiry for the [2 bed house] on [liverpool street] ([https://rightmove.com]), we’re sending you a one time message in WhatsApp to seek permission to chat further here about your enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 B - to book a viewing on a property you have seen listed for rent\n 👉 Q - if you want to ask a general question about a property\n 👉 L - if you want to let your property\n\n To unsubscribe reply with STOP.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 21,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "sales_responder_rightmove",
          templateText:
            "Hi {{1}}, further to your enquiry for the {{2}} on {{3}} ({{4}}), we’re sending you a one time message in WhatsApp to seek permission to chat further here about your enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 V - to book a viewing on a property you have seen listed\n 👉 S - if you want to sell your property\n 👉 A - if you want to ask a general question about a property\n\nTo unsubscribe reply with STOP.",
          templateExample:
            "Hi [Paul], further to your enquiry for the [2 bed house] on [liverpool street] ([https://rightmove.com]), we’re sending you a one time message in WhatsApp to seek permission to chat further here about your enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 V - to book a viewing on a property you have seen listed\n 👉 S - if you want to sell your property\n 👉 A - if you want to ask a general question about a property\n\nTo unsubscribe reply with STOP.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 22,
          templateCategory: "TRANSUTILITYACTIONAL",
          templateType: "Automated Replies",
          templateName: "lettings_zoopla_responder",
          templateText:
            "Hi {{1}}, further to your enquiry for the {{2}} on {{3}}, we’re sending you a one time message in WhatsApp to seek permission to chat further about your enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 B - to book a viewing on a property you have seen listed for rent\n 👉 Q - if you want to ask a general question about a property\n 👉 L - if you want to let your property\n\n To unsubscribe reply with STOP.",
          templateExample:
            "Hi [Paul], further to your enquiry for the [2 bed house] on [liverpool street], we’re sending you a one time message in WhatsApp to seek permission to chat further about your enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 B - to book a viewing on a property you have seen listed for rent\n 👉 Q - if you want to ask a general question about a property\n 👉 L - if you want to let your property\n\n To unsubscribe reply with STOP.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 23,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "sales_zoopla_responder",
          templateText:
            "Hi {{1}}, further to your enquiry for the {{2}} on {{3}}, we’re sending you a one time message in WhatsApp to seek permission to chat further here about your property enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 V - to book a viewing on a property you have seen listed\n 👉 S - if you want to sell your property\n 👉 A - if you want to ask a general question about a property\n\nTo unsubscribe reply with STOP.",
          templateExample:
            "Hi [Paul], further to your enquiry for the [2 bed house] on [liverpool street], we’re sending you a one time message in WhatsApp to seek permission to chat further here about your property enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 V - to book a viewing on a property you have seen listed\n 👉 S - if you want to sell your property\n 👉 A - if you want to ask a general question about a property\n\nTo unsubscribe reply with STOP.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 24,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "lettings_responder_otm",
          templateText:
            "Hi {{1}}, further to your enquiry for the {{2}} on {{3}}, we’re sending you a one time message in WhatsApp to seek permission to chat further here about your enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 B - to book a viewing on a property you have seen listed for rent\n 👉 Q - if you want to ask a general question about a property\n 👉 L - if you want to let your property\n\n To unsubscribe reply with STOP.",
          templateExample:
            "Hi [Paul], further to your enquiry for the [2 bed house] on [liverpool street], we’re sending you a one time message in WhatsApp to seek permission to chat further here about your enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 B - to book a viewing on a property you have seen listed for rent\n 👉 Q - if you want to ask a general question about a property\n 👉 L - if you want to let your property\n\n To unsubscribe reply with STOP.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 25,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "sales_responder_otm",
          templateText:
            "Hi {{1}}, further to your enquiry for the {{2}} on {{3}}, we’re sending you a one time message in WhatsApp to seek permission to chat further here about your enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 V - to book a viewing on a property you have seen listed\n 👉 S - if you want to sell your property\n 👉 A - if you want to ask a general question about a property\n\nTo unsubscribe reply with STOP.",
          templateExample:
            "Hi [Paul], further to your enquiry for the [2 bed house] on [liverpool street], we’re sending you a one time message in WhatsApp to seek permission to chat further here about your enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 V - to book a viewing on a property you have seen listed\n 👉 S - if you want to sell your property\n 👉 A - if you want to ask a general question about a property\n\nTo unsubscribe reply with STOP.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 26,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "phone_auto_responder",
          templateText:
            "Sorry we missed your call.\n\nIn order to assist you with your enquiry, please reply with one of the numbers 👇\n\n 👉 1 - for sales related enquires\n 👉 2 - for a lettings related enquiry\n 👉 3 - for a book a mortgage appointment\n 👉 4 - if you rent a property from {{inboxName}} and would like to report an issue\n 👉 5 - for all other enquiries\n\nOr, if you are already dealing with a member of the team, please just continue your chat here.",
          templateExample:
            "Sorry we missed your call.\n\nIn order to assist you with your enquiry, please reply with one of the numbers 👇\n\n 👉 1 - for sales related enquires\n 👉 2 - for a lettings related enquiry\n 👉 3 - for a book a mortgage appointment\n 👉 4 - if you rent a property from {{inboxName}} and would like to report an issue\n 👉 5 - for all other enquiries\n\nOr, if you are already dealing with a member of the team, please just continue your chat here.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 12,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "portal_responder",
          templateText:
            "Hi {{1}}. Further to your enquiry for the {{2}} on {{3}}, we’re sending you a one time message in WhatsApp to seek permission to chat further here about your enquiry. To grant permission and chat with the branch, please reply with CHAT. To unsubscribe reply with STOP.",
          templateExample:
            "Hi [Paul]. Further to your enquiry for the [3 bedroom house] on [liverpool street], we’re sending you a one time message in WhatsApp to seek permission to chat further here about your enquiry. To grant permission and chat with the branch, please reply with CHAT. To unsubscribe reply with STOP.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 15,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "valpal_responder_v2",
          templateText:
            "Hi {{1}}. Thank you for submitting a valuation request for your property at {{2}}, which {{3}}. To continue to chat with a member of the team, please respond {{4}}, or should you want to unsubscribe at anytime, please respond with STOP.",
          templateExample:
            "Hi {{1}}. Thank you for submitting a valuation request for your property at [your property], which [has a valuation of £475,000]. To continue to chat with a member of the team, please respond [with S if you would like to discuss selling your property], or should you want to unsubscribe at anytime, please respond with STOP.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 16,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "leadpro_lettings_responder",
          templateText:
            "Hi {{1}}. Thank you for submitting a valuation request for your property at {{2}}, which shows an expected rental figure of upto {{3}} per month. To continue to chat with a member of the team, please respond to this message with the letter L. Should you want to unsubscribe at anytime, please respond with STOP.",
          templateExample:
            "Hi {{1}}. Thank you for submitting a valuation request for your property at [your property], which shows an expected rental figure of upto [has a valuation of £475,000] per month. To continue to chat with a member of the team, please respond to this message with the letter L. Should you want to unsubscribe at anytime, please respond with STOP.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 17,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "leadpro_sales_val_responder",
          templateText:
            "Hi {{1}}. Thank you for submitting a valuation request for your property at {{2}}, which shows an expected sales valuation of upto {{3}}. To continue to chat with a member of the team, please respond to this message with the letter S. Should you want to unsubscribe at anytime, please respond with STOP.",
          templateExample:
            "Hi {{1}}. Thank you for submitting a valuation request for your property at [your property], which shows an expected sales valuation of upto [has a valuation of £475,000]. To continue to chat with a member of the team, please respond to this message with the letter S. Should you want to unsubscribe at anytime, please respond with STOP.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 19,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "rightmove_val_responder",
          templateText:
            "Hi {{1}}. Thank you for submitting a valuation request for your property at {{2}}. To continue to chat with a member of the team, please respond with the word VAL, or should you want to unsubscribe at anytime, please respond with STOP.",
          templateExample:
            "Hi [Paul]. Thank you for submitting a valuation request for your property at [liverpool street]. To continue to chat with a member of the team, please respond with the word VAL, or should you want to unsubscribe at anytime, please respond with STOP.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
      ],
    },
  ],
};
