import { gql } from "@apollo/client";

export const GET_RECENTSUCCESSFUL_MESSAGES = gql`
  query MyQuery {
    msgbox_Message(
      limit: 50
      order_by: { MessageId: desc }
      where: { SendFailureReason: { _is_null: true } }
    ) {
      MessageId
      Organisation {
        Name
      }
      CreatedOn
      IsDeleted
      IsDelivered
      IsFailed
      IsQueued
      IsRead
      IsSent
      IsTemplate
      DeferredDelivery
      SendFailureReason
    }
  }
`;

export const GET_RECENTFAILED_MESSAGES = gql`
  query MyQuery {
    msgbox_Message(
      limit: 50
      order_by: { MessageId: desc }
      where: { SendFailureReason: { _is_null: false } }
    ) {
      MessageId
      Organisation {
        Name
      }
      CreatedOn
      SendFailureReason
    }
  }
`;
